import { put, takeEvery, call } from "redux-saga/effects";
import {
  initPermission,
  fetchPermissionRequest,
  fetchPermissionSuccess,
  fetchPermissionFailure,
} from "../actions/permissionActions";
import roleProvider from "../synapse/roleProvider";
import authProvider from "../synapse/authProvider";
import { getMetaData } from "../utils/storage";

export const FETCH_PERMISSIONS_SAGA = "FETCH_PERMISSIONS_SAGA";

export function* permissionsSaga() {
  yield takeEvery(FETCH_PERMISSIONS_SAGA, fetchPermissions);
}

function* fetchPermissions() {
  const token = localStorage.getItem("access_token");
  const requiredChangePassword = getMetaData('requiredChangePassword');
  if (token !== null && requiredChangePassword === 0) {
    yield put(fetchPermissionRequest());
    try {
      const permissions = yield call(roleProvider.getMyRole);
      yield put(fetchPermissionSuccess(permissions));
    } catch (e) {
      yield put(fetchPermissionFailure());
    }
  } else {
    yield put(initPermission());
    if (requiredChangePassword === 1 && !window.location.href.includes("login")) {
      yield call(authProvider.logout());
    }
  }
}
