import * as PERMISSION_TYPES from "../types/permissionTypes";

export const fetchPermissionRequest = () => ({
  type: PERMISSION_TYPES.FETCH_PERMISSIONS_REQUEST,
});

export const fetchPermissionSuccess = permissions => ({
  type: PERMISSION_TYPES.FETCH_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const fetchPermissionFailure = error => ({
  type: PERMISSION_TYPES.FETCH_PERMISSIONS_FAILURE,
  payload: error,
});

export const resetPermission = () => ({
  type: PERMISSION_TYPES.RESET_PERMISSIONS,
});

export const initPermission = () => ({
  type: PERMISSION_TYPES.INIT_PERMISSIONS,
});
