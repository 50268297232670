export const EErrorCode = {
  M_USER_NOT_FOUND: 'M_USER_NOT_FOUND',
  M_TWO_FACTOR_IS_INVALID: 'M_TWO_FACTOR_IS_INVALID',
  M_NOT_ENABLE_TWO_FACTOR: 'M_NOT_ENABLE_TWO_FACTOR',
  M_NOT_SETUP_YUBIKEY: 'M_NOT_SETUP_YUBIKEY',
  M_YUBIKEY_IS_INVALID: 'M_YUBIKEY_IS_INVALID',
  M_ALREADY_SETUP_YUBIKEY: 'M_ALREADY_SETUP_YUBIKEY',
  M_SESSION_NOT_EXISTS_OR_EXPIRED: 'M_SESSION_NOT_EXISTS_OR_EXPIRED',
  M_ACCESS_FORBIDDEN: 'M_ACCESS_FORBIDDEN',
}
