import {
    useTranslate,
    useNotify,
    Loading,
    Confirm,
} from "react-admin";
import React, { useEffect, useState } from "react";
import { ThemeProvider, Box, Button } from "@mui/material";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridActionsCellItem
} from '@mui/x-data-grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import muiTheme from "../../../../helper/muiTheme";
import botProvider from "../../../../synapse/botProvider";
import { Pagination } from "../../../common/Pagination";
import ResponseDetailsDialog from "./ResponseDetailsDialog";

const EditToolbar = (props) => {
    const translate = useTranslate();
    const {
        onBackToForm,
        onDeleteAll,
    } = props;

    return (
        <GridToolbarContainer
            sx={{
                justifyContent: 'space-between',
                flexFlow: 'column',
                alignItems: 'flex-start',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <div>
                    <Button
                      component="label"
                      variant="text"
                      startIcon={<ArrowBackIcon/>}
                      onClick={onBackToForm}
                    >
                        {translate("resources.bots.fields.testform.btn_back_to_form")}
                    </Button>
                </div>
                <GridToolbarQuickFilter
                    quickFilterParser={(searchInput) =>
                        searchInput.split(',').map((value) => value)
                    }
                    quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                    debounceMs={500} // time before applying the new quick filter value
                />
                <div>
                    <Button
                        component="label"
                        variant="outlined"
                        startIcon={<DeleteIcon/>}
                        color="error"
                        sx={{marginRight: '10px'}}
                        onClick={onDeleteAll}
                    >
                        {translate("resources.bots.fields.testform.btn_delete_responses")}
                    </Button>
                </div>

            </div>
        </GridToolbarContainer>
    );
}

const ListResponses = ({botId, onBackToForm, secret, botToken, ...props}) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [confirmDelete, setConfirmDelete] = React.useState({
        isOpen: false,
        title: "",
        content: "",
    });
    const [isOpenDialogDetails, setIsOpenDialogDetails] = useState(false);
    const [detailRequest, setDetailRequest] = useState({});

    useEffect(() => {
        reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isTestRecord = (record) => {
        return record.request_id.startsWith('dummy') || !record.key_query || !record.response_at;
    }

    const columns = [
        {
            field: 'room_id',
            headerName: 'Room-ID',
            width: 325,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'request_id',
            headerName: 'Request-ID',
            width: 250,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'key_query',
            headerName: 'Key query',
            width: 300,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'metadata',
            headerName: 'Meta data',
            width: 200,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 175 ,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: 'response_at',
            headerName: 'Response At',
            width: 175 ,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                let actions = [];
                const record = listData[id];
                if (!isTestRecord(record)) {
                    actions.push(
                      <GridActionsCellItem
                        icon={<OpenInNewIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={onViewClick(listData[id])}
                        color="inherit"
                      />
                    );
                }
                return actions;
            },
          },
    ];

    const reloadData = async () => {
        setLoading(true);
        botProvider.getListBotResponse(botId, 'form').then((res) => {
            setListData(res?.data);
        }).catch(e => {
            notify(e.message, { type: "error" });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onViewClick = (row) => () => {
        setIsOpenDialogDetails(true);
        setDetailRequest({
            request_id: row.request_id,
            key_query: row.key_query,
        });
    };

    const onRowsPerPageChange = (event) => {
        if (event.pageSize) {
            setPageSize(event.pageSize);
        }
    };

    const handleDeleteAll = () => {
        setLoading(true);
        botProvider.deleteListResponses(botId, 'form').then((res) => {
            reloadData();
        }).catch(e => {
            notify(e.message, { type: "error" });
        }).finally(() => {
            setLoading(false);
            handleDialogDeleteClose();
        });
    };

    const onDeleteAll = () => {
        setConfirmDelete({
            isOpen: true,
            title: "Delete all responses",
            content: "Do you want delete all responses?",
        });
    };

    const handleDialogDeleteClose = () => {
        setConfirmDelete({
            isOpen: false,
            title: "",
            content: "",
        });
    };

    const onCloseDialogDetails = () => {
        setIsOpenDialogDetails(false);
    };

    if (loading) return <Loading />;
    return (
        <ThemeProvider theme={muiTheme}>
            <Box
                sx={{
                    height: '75vh',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    marginBottom: '10px',
                }}
            >
                <DataGrid
                    rows={listData}
                    columns={columns}
                    isRowSelectable={() => false}
                    initialState={{
                        pagination: { paginationModel: { pageSize: pageSize } },
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    onPaginationModelChange={(event) => onRowsPerPageChange(event)}
                    slots={{
                        pagination: Pagination,
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            onBackToForm,
                            onDeleteAll
                        },
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
                            whiteSpace: 'unset',
                        },
                    }}
                />
            </Box>
            <Confirm
                isOpen={confirmDelete.isOpen}
                title={confirmDelete.title}
                content={confirmDelete.content}
                onConfirm={handleDeleteAll}
                onClose={handleDialogDeleteClose}
            />
            {/* Dialog Details */}
            {
                <ResponseDetailsDialog
                    secret={secret}
                    botToken={botToken}
                    isOpenDialogDetails={isOpenDialogDetails}
                    onCloseDialogDetails={onCloseDialogDetails}
                    detailRequest={detailRequest}
                />
            }
        </ThemeProvider>
    );
}

export default ListResponses;
