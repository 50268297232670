import * as React from "react";
import { AppBar, UserMenu, MenuItemLink } from "react-admin";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslate } from "ra-core";

export const MyAppBar = props => {
  const translate = useTranslate();

  return (
    <AppBar
      {...props}
      color="secondary"
      userMenu={
        <UserMenu>
          <MenuItemLink
            to="/profile"
            primaryText={translate("resources.profile.name")}
            leftIcon={<PersonIcon />}
          />
        </UserMenu>
      }
    />
  );
};
