import React, { useEffect, useState } from "react";
import { Button, useNotify, useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@mui/material/TablePagination";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import { POWER_LEVEL } from "../../enum/ERoomOwner";
import { AsyncLoader, LoadingProgress } from "../common/LoadingProgress";
import roomProvider from "../../synapse/roomProvider";
import { isBotUser } from "../../helper/users";

const ROLE_OPTIONS = [
  {
    key: "owner",
    powerLevel: POWER_LEVEL.OWNER,
  },
  {
    key: "admin",
    powerLevel: POWER_LEVEL.ADMIN,
  },
  {
    key: "member",
    powerLevel: POWER_LEVEL.MEMBER,
  },
  {
    key: "restricted",
    powerLevel: POWER_LEVEL.RESTRICTED,
  },
];

const RoleSelector = (
  {
    options,
    rowData,
    translate,
    loading,
    notify,
    onSelect,
    onOpenDialog,
    listMembers,
  }
) => {
  const [selectOptions, setSelectOptions] = useState(options ?? []);

  useEffect(() => {
    handleIgnoreOptions();
  }, []);

  const handleIgnoreOptions = () => {
    // Show full options when the user is the owner and the user is not a bot, without deactivated the user
    const isOwnerInRoom = rowData.powerLevel === POWER_LEVEL.OWNER;
    const isDeactivatedOrBotUser = rowData.deactivated || isBotUser(rowData.userId);
    if (!isOwnerInRoom && isDeactivatedOrBotUser) {
      const ignoreOptions = options.filter(option => option.key !== 'owner');
      setSelectOptions(ignoreOptions);
    }
  };
  
  const isDecreasingOwnerPermission = (value) => {
    const owners = listMembers?.filter((member) => member.powerLevel === POWER_LEVEL.OWNER);
    if (
      rowData.powerLevel === POWER_LEVEL.OWNER && // Seleted user is Owner
      value !== rowData.powerLevel && // Target role is demote power level
      owners?.length === 1 // Is last Owner
    ) {
      notify("resources.rooms.notifications.must_have_one_owner", { type: "error" });
      return true;
    }
    return false;
  };

  const isIncreasingToOwnerPermission = (value) => {
    if (value === POWER_LEVEL.OWNER && rowData.powerLevel !== POWER_LEVEL.OWNER) {
      onOpenDialog(rowData.userId, value);
      return true;
    }
    return false;
  }


  const handleChange = (event) => {
    const value = event.target.value;
    if (isDecreasingOwnerPermission(value) || isIncreasingToOwnerPermission(value)) {
      return;
    }
    onSelect(rowData.userId, event.target.value, rowData.powerLevel);
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl
        fullWidth
      >
        <Select
          variant="outlined"
          value={rowData.powerLevel}
          onChange={handleChange}
          disabled={loading}
        >
          {selectOptions.map((option) => (
            <MenuItem
              key={option.powerLevel}
              value={option.powerLevel}
            >
              {translate(`resources.rooms.permission.${option.key}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box width={"20px"} paddingX={0.5}>
        {loading && <LoadingProgress size={20} thickness={3}/>}
      </Box>
    </Box>
  );
};

export const RoomMembers = ({...props}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const PAGE_SIZE = 100;
  const roleOptions = ROLE_OPTIONS;
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemBeingUpdated, setItemBeingUpdated] = useState("");
  const [dataDialog, setDataDialog] = useState(null);
  const [paging, setPaging] = useState({from: 1, to: PAGE_SIZE});
  const [dataTable, setDataTable] = useState({
    total: 0,
    items: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);

  useEffect(() => {
    getUsers();
  }, [paging]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const {data} = await roomProvider.getListMember(props.id, paging.from, paging.to)
      setDataTable(data);
    } catch (e) {

    } finally {
      setLoading(false);
      setDataDialog(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPaging({
      from: newPage * rowsPerPage + 1,
      to: newPage * rowsPerPage + rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPaging({
      from: 1,
      to: perPage,
    })
    setPage(0);
  };

  const closeDialog = () => {
    setOpenDialogConfirm(false);
  };

  const openDialog = (userId, value) => {
    setDataDialog({userId, value});
    setOpenDialogConfirm(true);
  };

  const updateDataTable = (userId, value) => {
    setDataTable((prevData) => {
      const updatedItems = prevData.items.map((item) => {
        if (item.userId === userId) {
          return { ...item, powerLevel: value };
        }
        return item;
      });

      return { ...prevData, items: updatedItems };
    });
  };

  const onRoleSelected = async (userId, value, oldValue) => {
    closeDialog();
    updateDataTable(userId, value);
    try {
      setItemBeingUpdated(userId);
      const dto = {
        targetUserId: userId,
        targetRole: value,
      }
      await roomProvider.updateUserRoleInRoom(props.id, dto);
      notify("resources.rooms.notifications.change_permissions_success", { type: "success" });
      if (dataDialog) {
        await getUsers();
      }
    } catch (e) {
      notify(`${e?.body?.error ?? "resources.rooms.notifications.change_permissions_failed"}`, { type: "error" });
      if (oldValue) {
        updateDataTable(userId, oldValue);
      }
    } finally {
      setItemBeingUpdated("");
    }
  }

  const tableContent = (
    <>
      <TableHead>
        <TableRow>
          <TableCell>User Id</TableCell>
          <TableCell>Displayname</TableCell>
          <TableCell>Custom role</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataTable.items.map((row) => (
          <TableRow
            key={row.userId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.userId}
            </TableCell>
            <TableCell>{row.displayname}</TableCell>
            <TableCell>
              <RoleSelector
                options={roleOptions}
                rowData={row}
                onSelect={onRoleSelected}
                onOpenDialog={openDialog}
                translate={translate}
                loading={itemBeingUpdated === row.userId}
                notify={notify}
                listMembers={dataTable?.items}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );

  return (
    <Box
      paddingBottom={1}
    >
      <Paper
        sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
        <TableContainer
          sx={{height: "60vh"}}
        >
          <Table
            sx={{
              minWidth: 650,
              height: loading ? "100%" : undefined,
            }}
            aria-label="room members table"
          >
            {
              loading
              ? <AsyncLoader
                  title={translate("assigning.loading")}
                />
              : tableContent
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={dataTable.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={openDialogConfirm}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
          >
            <Box
              fullWidth
              textAlign="center"
              mt={2}
            >
              {translate("resources.rooms.members.transferring_ownership")}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            label={translate('resources.roles.actions.cancel')}
            onClick={closeDialog}
          ></Button>
          <Button
            variant="contained"
            label={translate('resources.roles.actions.confirm')}
            onClick={() => onRoleSelected(dataDialog?.userId, dataDialog?.value)}
          ></Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
};
