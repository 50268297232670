import React, {useState, useEffect, useRef} from "react";
import {
  Notification, TextInput, useNotify,
  useTranslate,
} from "react-admin";
import { Form, Field } from "react-final-form";
import {
  Button,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";
import { EErrorCode } from "../../enum/EErrorCode";

import adminProvider from "../../synapse/adminProvider";
import UserInfoLogin from "./UserInfoLogin";
import {isMobile} from "../../helper/utils";

const Yubikey = (props) => {
  const classes = useStyles({ theme: props.theme });
  const inputRefTOTP = useRef(null);

  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [totp, setTOTP] = useState('');
  const [errorFocus, setErrorFocus] = useState('');
  const [error, setError] = useState('');
  const translate = useTranslate();

  useEffect(() => {
    window.addEventListener("focus", focusInInputTOTP);
    window.addEventListener("mouseup", focusInInputTOTP);
    window.addEventListener("blur", focusOutInputTOTP);
    return () => {
      window.removeEventListener("focus", focusInInputTOTP);
      window.removeEventListener("mouseup", focusInInputTOTP);
      window.removeEventListener("blur", focusOutInputTOTP);
    };
  }, []);



  const validate = values => {
    return {};
  };

  const handleSubmit = async (values) => {
    if (!/^[a-zA-Z]+$/.test(values.totp)) {
      setTOTP('')
      inputRefTOTP.current?.focus();
      inputRefTOTP.current?.select();
      setError(translate('yubikey.turn_off_ime'));
      return false;
    }

    try {
      setLoading(true);
      const dto = {
        step: props.step,
        value: values.totp,
        sessionTemp: props.dataFlow?.sessionTemp,
      };
      const res = await adminProvider.loginStep(dto);
      props.handleSubmit(res?.json);
    } catch (err) {
      inputRefTOTP.current?.focus();
      inputRefTOTP.current?.select();
      let errorMessage;
      const errcode = err.body?.errcode || '';
      switch (errcode) {
        case EErrorCode.M_NOT_SETUP_YUBIKEY:
          errorMessage = 'yubikey.not_setup';
          break;
        case EErrorCode.M_YUBIKEY_IS_INVALID:
          errorMessage = 'yubikey.ybk_invalid';
          break;
        case EErrorCode.M_ALREADY_SETUP_YUBIKEY:
          errorMessage = 'yubikey.already_setup';
          break;
        case EErrorCode.M_SESSION_NOT_EXISTS_OR_EXPIRED:
          props.onSessionExpired();
          break;
        default:
          errorMessage = 'yubikey.cannot_setup';
          break;
      }
      notify(errorMessage, {
        type: "error",
      });
    }
    finally {
      setLoading(false);
      setTOTP('');
      setError('');
    }
  };

  const updateDataTOTP = async (e) => {
    if (e.target?.value > 44) {
      return;
    }
    setTOTP(e.target?.value || '');
  };

  const focusInInputTOTP = () => {
    setErrorFocus('')
    inputRefTOTP.current?.focus();
    inputRefTOTP.current?.select();
  };

  const focusOutInputTOTP = () => {
    setErrorFocus(translate("yubikey.focus_me"));
  };

  return (
    <Form
      initialValues={{totp: totp}}
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.form}>
                <div className={classes.iconBox}>
                  {
                    loading ? <CircularProgress size={39} thickness={2} color="gray"/> :
                      (!error && !errorFocus ?
                        <PhonelinkLockIcon style={{fontSize: "39"}}/> :
                        <WarningIcon style={{fontSize: "39", color: "red"}} />)
                  }
                </div>
                <UserInfoLogin
                  userInfo={props.userInfo}
                  onBackToLogin={props.onBackToLogin}
                />
                <div className={classes.boxBodyList}>
                  <h4>
                    {translate("yubikey.allow_using_key")}
                  </h4>
                  <span>
                     {translate("yubikey.connect_your_key")}
                  </span>
                  <div className={classes.iconConnectYubikey}>
                    <div className={
                      totp.length >= 44
                        ? classes.laptopActive : classes.laptop } >
                      <div className={
                        totp.length > 1
                            ? classes.yubikeyDeviceActive : classes.yubikeyDevice } />
                    </div>
                  </div>
                </div>
                <div className="mx_SetupYubikey__box-footer">
                  {
                    !isMobile() ?
                    <div className={classes.inputTotp}>
                      <Field
                          name="totp"
                          component="input"
                          autoFocus
                          onKeyUp={updateDataTOTP}
                          autoComplete="off"
                          autoCapitalize="off"
                          autoCorrect="off"
                          spellCheck="false"
                          value={totp}
                          onBlur={focusInInputTOTP}
                          ref={inputRefTOTP}
                      />
                    </div> :
                    <>
                      <TextInput
                          name="totp"
                          component="input"
                          autoFocus
                          onChange={updateDataTOTP}
                          value={totp}
                          onBlur={focusInInputTOTP}
                          ref={inputRefTOTP}
                          label={"otp code"}
                          resettable
                          fullWidth
                          required={true}
                      />
                      <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={totp.length < 44}
                          className={classes.button}
                          fullWidth
                      >
                        {loading && <CircularProgress size={25} thickness={2} />}
                        {translate("yubikey.verify")}
                      </Button>
                    </>
                  }
                  <div className={classes.errorText}>{ error }</div>
                  <div className={classes.errorText}>{ errorFocus }</div>
                </div>
              </div>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Yubikey.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSessionExpired: PropTypes.func,
  step: PropTypes.string,
  dataFlow: PropTypes.object,
  userInfo: PropTypes.object,
  onBackToLogin: PropTypes.func,
};

export default Yubikey;


const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: '25%',
  },
  form: {
    padding: "2em 1em 1em 1em",
  },
  boxBodyList: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    fontSize: '1rem',
  },
  iconConnectYubikey: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
  },
  yubikeyDevice: {
    width: '39px',
    minHeight: '39px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    position: 'absolute',
    bottom: '39px',
    left: '-20px',
    background: "url(./images/yubikey-disabled.svg)",
  },
  yubikeyDeviceActive: {
    width: '39px',
    minHeight: '39px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    position: 'absolute',
    bottom: '39px',
    left: '-10px',
    background: "url(./images/yubikey.svg)",
  },
  laptop: {
    width: '179px',
    minHeight: '179px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    background: "url(./images/laptop-disabled.svg)",
    position: 'relative',
  },
  laptopActive: {
    width: '179px',
    minHeight: '179px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    background: "url(./images/laptop.svg)",
    position: 'relative',
  },
  inputTotp: {
    height: '0px',
    overflow: 'hidden',
    border: 'none',
  },
  iconBox: {
    paddingTop: '16px',
    width: '100%',
    textAlign: 'center',
  },
  errorText: {
    paddingTop: '1em',
    color:'red',
  },
  otpCodeMobile: {
    width: '100%',
  }
}));
