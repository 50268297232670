import * as BOT_CONTAINERS_TYPES from "../types/botContainersTypes";

export const fetchBotContainersRequest = () => ({
  type: BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_REQUEST,
});

export const fetchBotContainersSuccess = containers => ({
  type: BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_SUCCESS,
  payload: containers,
});

export const fetchBotContainersFailure = error => ({
  type: BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_FAILURE,
  payload: error,
});

export const resetBotContainers = () => ({
  type: BOT_CONTAINERS_TYPES.RESET_BOT_CONTAINERS,
});

export const initBotContainers = () => ({
  type: BOT_CONTAINERS_TYPES.INIT_BOT_CONTAINERS,
});
