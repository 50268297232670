import React, { useState, useEffect } from "react";
import {
  Notification, useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QRCode } from "react-qrcode-logo";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import adminProvider from "../../synapse/adminProvider";
import UserInfoLogin from "./UserInfoLogin";
import profileProvider from "../../synapse/profileProvider";
import Tooltip from "@mui/material/Tooltip";

const SetupTwoFactor = (props) => {
  const reg = /^\d+$/;
  const classes = useStyles({ theme: props.theme });
  const isLoginFlow = props.isLoginFlow;
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [dataEnable2FA, setDataEnable2FA] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copied');
  const translate = useTranslate();

  useEffect(async () => {
    try {
      setLoading(true);
      if (isLoginFlow) {
        const dto = {
          step: props.step,
          sessionTemp: props.dataFlow?.sessionTemp,
        }
        const res = await adminProvider.loginStep(dto);
        setDataEnable2FA(res?.json);
      } else {
        const res = await  profileProvider.twoFactorEnable();
        setDataEnable2FA(res?.json);
      }

    } catch (err) {
      setError(translate('two_factor.cannot_get_qr'));
    }
    finally {
      setLoading(false);
      setOTP('');
    }
  }, []);


  const validate = values => {
    return {};
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (isLoginFlow) {
        const dto = {
          step: dataEnable2FA?.nextStep,
          value: otp,
          sessionTemp: dataEnable2FA?.sessionTemp,
        };
        const res = await adminProvider.loginStep(dto);
        props.handleSubmit(res?.json);
      } else {
        const res = await profileProvider.verifyTwoFactor(otp);
        props.handleSubmit(res?.json);
      }
    } catch (err) {
      let errorMessage;
      const errcode = err.body?.errcode || '';
      switch (errcode) {
        case 'M_TWO_FACTOR_IS_INVALID':
          errorMessage = 'two_factor.code_invalid';
          break;
        case 'M_SESSION_NOT_EXISTS_OR_EXPIRED':
          props.onSessionExpired();
          break;
        default:
          errorMessage = 'two_factor.cannot_setup';
          break;
      }
      notify(errorMessage, {
        type: "error",
      });
    }
    finally {
      setLoading(false);
      setOTP('');
      setError('')
    }
  };

  const updateDataOTP = async (e) => {
    const value = e.target.value;
    if (value.length > 6 || (!reg.test(value) && value.length !== 0)) {
      return;
    }
    setOTP(value);
  };

  const onCopy = (value) => {
    setCopyText(value ? translate("resources.users.action.copied") : translate("resources.users.action.empty"));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={isLoginFlow ? classes.main : ''}>
            <Card className={isLoginFlow ? classes.card: ''}>
              <div className={classes.form}>
                <h2>
                  {translate("two_factor.title")}
                </h2>
                {
                  props?.userInfo && <UserInfoLogin
                    userInfo={props.userInfo}
                    onBackToLogin={props.onBackToLogin}
                  />
                }
                <h4>{translate("two_factor.scan_qr")}</h4>
                <div className={classes.loading}>
                  {loading && <CircularProgress size={50} thickness={2} />}
                </div>
                <div className={!dataEnable2FA?.qrCodeStr ? classes.hidden : ''}>
                  <QRCode
                    value={dataEnable2FA?.qrCodeStr}
                    logoImage=""
                    removeQrCodeBehindLogo={true}
                    size={200}
                  />
                </div>
                <div className={!dataEnable2FA?.secret ? classes.hidden : ''}>
                  <div className={classes.copyClipboardBox}>
                    <span className={classes.copyClipboardText}>
                      {dataEnable2FA?.secret}
                    </span>
                    <CopyToClipboard text={dataEnable2FA?.secret} onCopy={onCopy}>
                      <span className={classes.copyClipboard}>
                        <Tooltip title={copyText} open={isCopied} placement="top">
                          <FileCopyIcon style={{fontSize: "23"}}/>
                        </Tooltip>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
                <div>
                  <OutlinedInput
                    className={classes.inputOtp}
                    id="component-outlined"
                    value={otp}
                    onChange={updateDataOTP}
                    placeholder={translate("two_factor.enter_code")}
                  />
                </div>
                <div className={classes.errorText}>{ error }</div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading || otp.length < 6}
                  className={classes.button}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Verify
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

SetupTwoFactor.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSessionExpired: PropTypes.func,
  step: PropTypes.string,
  dataFlow: PropTypes.object,
  userInfo: PropTypes.object,
  onBackToLogin: PropTypes.func,
  isLoginFlow: PropTypes.bool,
};

export default SetupTwoFactor;

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: '25%',
  },
  form: {
    padding: "2em 1em 1em 1em",
    textAlign: 'center'
  },
  inputOtp: {
    width: '100%',
    minHeight: '2em',
    marginTop: '2em'
  },
  copyClipboardBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  copyClipboard: {
    cursor: 'pointer',
    paddingLeft: '0.5em',
    paddingTop: '1em'
  },
  copyClipboardText: {
    paddingLeft: '1em',
    paddingTop: '1em'
  },
  errorText: {
    paddingTop: '1em',
    color:'red',
  },
  hidden: {
    display: 'none',
  },
  loading: {
    width: "100%",
    textAlign: "center",
  }
}));
