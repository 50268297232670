export function safeParse(input) {
  try {
    return JSON.parse(input);
  } catch (e) {
    return input;
  }
}

const UrlRegexp = /http(s*):\/\/.*/i;
export const isUrl = (value) => !value || UrlRegexp.test(value);
