import { Confirm, Loading, useNotify, useTranslate } from "react-admin";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, ThemeProvider } from "@mui/material";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import muiTheme from "../../../../helper/muiTheme";
import botProvider from "../../../../synapse/botProvider";
import { Pagination } from "../../../common/Pagination";
import ProxyDetailsDialog from "./ProxyDetailsDialog";
import RefreshInterval from "./RefreshInterval";

const EditToolbar = (props) => {
  const translate = useTranslate();
  const { onDeleteAll } = props;

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: 'space-between',
        flexFlow: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) => value)
          }
          quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
          debounceMs={500} // time before applying the new quick filter value
         variant='outlined'
        />
        <div>
          <Button
            component="label"
            variant="outlined"
            startIcon={<ClearAllIcon />}
            color="error"
            sx={{marginRight: '10px'}}
            onClick={onDeleteAll}
            disabled={props.rows}
          >
            {translate("resources.bots.actions.clear_all")}
          </Button>
        </div>
      </div>
    </GridToolbarContainer>
  );
}

const ListProxy = ({ botId, botToken }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [confirmDelete, setConfirmDelete] = React.useState({
    isOpen: false,
    title: "",
    content: "",
  });
  const [eventDetail, setEventDetail] = useState(null);
  const autoUpdating = useRef(null);

  const reloadData = useCallback((showLoading = false) => {
    if (showLoading) {
      setLoading(true);
    }
    botProvider.getListBotResponse(botId, 'proxy').then((res) => {
      setListData(res?.data);
    }).catch(e => {
      notify(e.message, { type: "error" });
    }).finally(() => {
      setLoading(false);
    });
  }, [botId, setLoading, notify, setListData]);

  const resumeFetchingData = useCallback((showLoading) => {
    reloadData(showLoading);
    autoUpdating.current = setInterval(() => {
      reloadData();
    }, 5000);
  }, [reloadData]);

  useEffect( () => {
    reloadData(true);
    return () => {
      clearInterval(autoUpdating.current);
    }
  }, [reloadData]);

  const isTestRecord = (record) => {
    return record.event_id?.startsWith('$dummy');
  }

  const columns = [
    {
      field: 'room_id',
      headerName: 'Room-ID',
      width: 325,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'event_id',
      headerName: 'Event-ID',
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'text',
      headerName: 'Text',
      width: 400,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 175 ,
      sortable: true,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return params.value?.toLocaleString?.();
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        let actions = [];
        const record = listData[id];
        if (!isTestRecord(record)) {
          actions.push(
            <GridActionsCellItem
              icon={<OpenInNewIcon />}
              label="View"
              className="textPrimary"
              onClick={() => onViewClick(listData[id])}
              color="inherit"
            />
          );
        }
        return actions;
      },
    },
  ];

  const onCloseDialog = () => {
    setEventDetail(null);
  }

  const onViewClick = (row) => {
    setEventDetail({
      room_id: row.room_id,
      event_id: row.event_id,
      user: row.user,
      text: row.text,
      created_at: row.created_at,
      headers: row.headers,
    });
  };

  const onRowsPerPageChange = (event) => {
    if (event.pageSize) {
      setPageSize(event.pageSize);
    }
  };

  const handleDeleteAll = async () => {
    setLoading(true);
    botProvider.deleteListResponses(botId, 'proxy').then(async () => {
      return reloadData();
    }).catch(e => {
      notify(e.message, { type: "error" });
    }).finally(() => {
      setLoading(false);
      handleDialogDeleteClose();
    });
  };

  const onDeleteAll = () => {
    setConfirmDelete({
      isOpen: true,
      title: "Clear all responses",
      content: "Do you want clear all responses?",
    });
  };

  const handleDialogDeleteClose = () => {
    setConfirmDelete({
      isOpen: false,
      title: "",
      content: "",
    });
  };

  if (loading) return <Loading />;
  return (
    <ThemeProvider theme={muiTheme}>
      <Box
        sx={{
          height: '75vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          marginBottom: '10px',
        }}
      >
        <DataGrid
          rows={listData}
          columns={columns}
          isRowSelectable={() => false}
          onRowClick={(params) => {
            if (isTestRecord(params.row)) return;
            onViewClick(params.row);
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: pageSize } },
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          onPaginationModelChange={(event) => onRowsPerPageChange(event)}
          slots={{
            pagination: Pagination,
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              onDeleteAll
            },
          }}
          sx={{
            border: 'none',
            '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
              whiteSpace: 'unset',
            },
          }}
        />
      </Box>
      <RefreshInterval
        onStop={() => {
          clearInterval(autoUpdating.current);
        }}
        onResume={() => {
          resumeFetchingData(false);
        }}
        onForce={() => {
          reloadData(true);
        }}
      />
      <Confirm
        isOpen={confirmDelete.isOpen}
        title={confirmDelete.title}
        content={confirmDelete.content}
        onConfirm={handleDeleteAll}
        onClose={handleDialogDeleteClose}
      />
      {/* Dialog Details */}
      {
        eventDetail && <ProxyDetailsDialog
          botToken={botToken}
          onCloseDialogDetails={onCloseDialog}
          event={eventDetail}
        />
      }
    </ThemeProvider>
  );
}

export default ListProxy;
