import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FormTab, Loading,
  useTranslate,
} from "react-admin";
import PropTypes from "prop-types";
import DvrIcon from "@material-ui/icons/Dvr";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@mui/material";
import botProvider from "../../../synapse/botProvider";
import RefreshInterval from "./BotTestProxy/RefreshInterval";

const useStyles = makeStyles({
  label: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  labelInline: {
    marginLeft: '10px',
    fontSize: '1rem',
    color: 'initial',
  },
  valueField: {
    padding: '8px 0 4px',
    marginLeft: '15px',
  },
  messageErr: {
    padding: '20px',
    color: 'red',
  },
  queueBox: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    marginBottom: '10px',
  }
});

const LabelField = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        marginTop: '0px',
      }}
    >
      <label>{ children }</label>
    </div>
  );
};
LabelField.prototype = {
  children: PropTypes.object,
}
function getStatusValue(status, options) {
    if (status !== 'unknown') {
      return status ? options.truthy : options.falsy;
    }
    return status;
}

const StatusField = (props) => {
  const color = props.success !== 'unknown' ?
    (props.success ? '#2cbf52' : 'rgb(244, 67, 54)')
    : '#ccc';
  return (
    <span
      className={props.className}
      style={{ color: color }}
    >
      { props.children }
    </span>
  );
}

StatusField.prototype = {
  success: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object,
}

const NumberField = (props) => {
  function formatNumber(value) {
    return new Intl.NumberFormat().format(value);
  }

  return (
    <span className={props.className}>{ formatNumber(props.value) }</span>
  )
}

NumberField.prototype = {
  className: PropTypes.string,
  value: PropTypes.number,
}

const BotQueueInfo = ({ queueInfo }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <div className={classes.queueBox}>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_name")}:
        <span className={classes.labelInline}>{queueInfo?.queueName}</span>
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_is_ready")}:
        <StatusField success={queueInfo?.isReady} className={classes.labelInline}>
          {
            translate(`resources.bots.queue.status.${
              getStatusValue(queueInfo?.isReady, { truthy: "ready", falsy: "not_ready" })
            }`)
          }
        </StatusField>
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_count")}:
        <NumberField className={classes.labelInline} value={queueInfo?.messageCount ?? 0} />
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_consumer")}:
        <NumberField className={classes.labelInline} value={queueInfo?.consumerCount ?? 0} />
      </LabelField>
    </div>
    {
      queueInfo.error && <div className={classes.label}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {queueInfo.error}
        </Alert>
      </div>
    }
  </div>
}

BotQueueInfo.prototype = {
  queueInfo: PropTypes.object,
}

const BotQueue = ({ botId, ...props }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();
  const [queueSummary, setQueueSummary] = useState({})
  const autoUpdating = useRef(null);
  const fetchQueueInfo = useCallback((id, showLoading = false) => {
    if (!id) return;
    if (showLoading) {
      setLoading(true);
    }
    botProvider.getQueueInfo(id).then((res) => {
      setQueueSummary({
        ...res.json,
      });
    }).catch((e) => {
      setQueueSummary({
        messageErr: "resources.bots.fields.queue_fetching_fail"
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [botId, setLoading, setQueueSummary]);

  const autoFetchingData = useCallback((id, showLoading) => {
    fetchQueueInfo(id, showLoading);
    autoUpdating.current = setInterval(() => {
      fetchQueueInfo(id);
    }, 5000);
  }, [fetchQueueInfo]);

  useEffect(() => {
    fetchQueueInfo(botId);
    return () => {
      clearInterval(autoUpdating.current);
    }
  }, [botId, fetchQueueInfo]);

  if (loading) return <Loading />;
  return (
    <FormTab {...props} label="Queue" icon={<DvrIcon />}>
      {
        queueSummary?.messageErr ?
          <div className={classes.messageErr}>
            {translate(queueSummary?.messageErr)}
          </div> :
          <div>
            {queueSummary.queues?.map((queueInfo) => {
              return <BotQueueInfo key={queueInfo.queueName} queueInfo={queueInfo} />
            })}
            <RefreshInterval
              onStop={() => {
                clearInterval(autoUpdating.current);
              }}
              onResume={() => {
                autoFetchingData(botId, false);
              }}
              onForce={() => {
                fetchQueueInfo(botId, true);
              }} />
          </div>
      }
    </FormTab>
  );
};

export default BotQueue;
