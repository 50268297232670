import { Button, CreateButton, sanitizeListRestProps, TopToolbar } from "react-admin";
import React, { cloneElement } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import PermissionWrapper from "../common/PermissionWrapper";
import { EPermission } from "../../enum/EPermission";
import ExportButton from "../common/ExportButton";

const redirect = () => {
    return {
        pathname: "/import_users",
    };
};

export const UserListActions = (
  {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  },
) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
      <PermissionWrapper names={[ EPermission.USERS_CREATE ]}>
        <CreateButton basePath={basePath} />
      </PermissionWrapper>
      <PermissionWrapper names={[ EPermission.USERS_EXPORT ]}>
        <ExportButton
          // disabled={total === 0}
          // resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          // exporter={exporter}
          // maxResults={maxResults}
        />
      </PermissionWrapper>
      {/* Add your custom actions */}
      <PermissionWrapper names={[ EPermission.USERS_IMPORT ]}>
        <Button component={Link} to={redirect} label="CSV Import">
          <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: "20" }} />
        </Button>
      </PermissionWrapper>
    </TopToolbar>
  );
};

// noinspection JSUnusedGlobalSymbols
UserListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};
