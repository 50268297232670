export const EPermission = {
  SUPER_ADMIN: "*",
  USERS_SEARCH_ALL: "users_search_all",
  USERS_EXPORT: "users_export",
  USERS_IMPORT: "users_import",
  USERS_DETAILS: "users_details",
  USERS_ERASE: "users_erase",
  USERS_RESET_TWO_FACTOR: "users_reset_two_factor",
  USERS_RESET_YUBIKEY: "users_reset_yubikey",
  USERS_CONNECTION: "users_connection",
  USERS_MEDIA: "users_media",
  USERS_ROOMS: "users_rooms",
  USERS_PUSHERS: "users_pushers",
  USERS_CREATE: "users_create",
  USERS_UPDATE: "users_update",
  USERS_ACTIVE: "users_active",
  USERS_DEVICES: "users_devices",
  ROOM_SEARCH: "rooms_search",
  ROOM_EXPORT: "rooms_export",
  ROOM_DETAILS: "rooms_details",
  ROOM_UPDATE: "rooms_update",
  ROOM_RE_ASSIGN_OWNER: "rooms_re_assign_owner",
  ROOM_CHANGE_PERMISSIONS: "rooms_change_permissions",
  ROOM_CHANGE_USER_ROLE: "rooms_change_user_role",
  ROOM_CONFIG_WHITELISTED_IPS: "rooms_config_whitelisted_ips",
  ROOM_CONFIG_RETENTION: "rooms_config_retention",
};
