import React,
{
  useEffect,
  useState,
} from "react";
import {
  useTranslate,
} from 'react-admin';
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from "@material-ui/core/Button";

import { ProgressModal } from "../common/ProgressModal";
import { ComboBox } from "../common/ComboBox";
import roomProvider from "../../synapse/roomProvider";
import Box from "@mui/material/Box";
import { LoadingProgress } from "../common/LoadingProgress";
import {
  RoomOwnerModalActionTemplate,
  RoomOwnerModalTitleTemplate,
  TEMPLATE_ACTION_NAME,
  TEMPLATE_TITLE_NAME,
} from "./RoomOwnerModalTemplate";
import { logger } from "../../utils/logger";
import { RoomOwnerGoBack } from "./RoomOwnerGoBack";
import {
  ROOM_OWNER_TYPE,
  PROGRESS_STATUS, POWER_LEVEL,
} from "../../enum/ERoomOwner";

const TITLE_REASSIGN_MEMBER =
{
  type: ROOM_OWNER_TYPE.USER,
  roles: [
    {
      key: 'owner',
      powerLevel: 100,
    },
    {
      key: 'admin',
      powerLevel: 75,
    },
  ]
};

const STEPS = {
  RETRIEVING: 'RETRIEVING',
  ASSIGNING: 'ASSIGNING',
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "roomId",
    numeric: false,
    disablePadding: true,
    width: 300,
    labelKey: "room_id",
    // css for cell
    sx: {},
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    width: 300,
    labelKey: "name",
    // css for cell
    sx: {
      marginLeft: "10px",
    },
  },
  {
    id: "owner",
    numeric: false,
    disablePadding: true,
    width: 300,
    labelKey: "owner",
    // css for cell
    sx: {
      marginLeft: "10px",
    },
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    translate,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.width}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              ...headCell.sx
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {translate("assigning." + headCell.labelKey)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const RoomOwner = (
  {
    title,
    type,
    onGoBackClick,
    onDeleteAccountClick, // ROOM_OWNER_TYPE.USER
    roomOwnerData, // ROOM_OWNER_TYPE.USER
    ownerId, // ROOM_OWNER_TYPE.USER
    deleting,
    ...props
  }
) => {
  const PAGE_SIZE = 100;

  const classes = useStyles();
  const translate = useTranslate();
  const [loading, setLoading] = useState(true);

  // Handle modal
  const [modalOpen, setModalOpen] = useState(false);
  const [titleModal, setTitleModal] = useState(<></>);
  const [actionModal, setActionModal] = useState(<></>);
  const [progress, setProgress] = useState(0);

  // Handle table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [paging, setPaging] = useState({
    from: 1,
    to: PAGE_SIZE,
  });
  const [roomData, setRoomData] = useState({
    total: 0,
    items: [],
  });
  // Handle assigning
  const [errorAssign, setErrorAssign] = useState([]);
  const [needToGoBack, setNeedToGoBack] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    resetModal();
  };

  const onDoneAssigning = () => {
    const remainingData = roomData.items.filter(item => item.status !== PROGRESS_STATUS.DONE);
    // If cancel => change status to NONE
    if (isCanceling) {
      remainingData.map(item => item.status = PROGRESS_STATUS.NONE);
      setIsCanceling(false);
    }
    setRoomData({
      total: remainingData.length,
      items: remainingData
    });
    setSelected([]);
    handleCloseModal();
  }

  const onCancel = () => {
    setIsCanceling(true);
    // Set disable button cancel
    handleActionModal(TEMPLATE_ACTION_NAME.ASSIGNING_CANCEL, {loading: true});
  }

  const onClose = () => {
    handleCloseModal();
  }

  const onRetry = () => {
    getRoomData();
    handleTitleModal(TEMPLATE_TITLE_NAME.RETRIEVING);
    // No template name -> no action
    handleActionModal();
  }

  const resetModal = () => {
    setTitleModal(<></>);
    setActionModal(<></>);
    setProgress(0);
  }

  const handleProgress = (total, progress, step, options) => {
    const ratio = (progress / total) * 100;
    const roundedRatio = Math.ceil(ratio);
    setProgress(roundedRatio);
    if (total === progress) {
      switch (step) {
        case STEPS.RETRIEVING:
          handleTitleModal(TEMPLATE_TITLE_NAME.RETRIEVED);
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
          break;
        case STEPS.ASSIGNING:
          handleTitleModal(TEMPLATE_TITLE_NAME.ASSIGNING_DONE);
          handleActionModal(
            TEMPLATE_ACTION_NAME.ASSIGNING,
            {
              success: selected.length - options.errorAssign.length,
              error: options.errorAssign,
              total: selected.length,
              onDoneAssigning,
            },
          );
          break;
        default:
          // Do nothing
          break;
      }
    }
  }

  const handleTitleModal = (templateName, ...options) => {
    setTitleModal(
      <RoomOwnerModalTitleTemplate
        templateName={templateName}
        translate={translate}
        options={options}
      />
    );
  };

  const handleActionModal = (templateName, options) => {
    setActionModal(
      <RoomOwnerModalActionTemplate
        templateName={templateName}
        translate={translate}
        options={options}
      />
    );
  };

  const handleCancel = () => {
    const { items } = roomData;
    const itemDone = items.filter(item => item.status === PROGRESS_STATUS.DONE);
    handleTitleModal(TEMPLATE_TITLE_NAME.ASSIGNING_CANCELED);
    handleActionModal(
      TEMPLATE_ACTION_NAME.ASSIGNING,
      {
        success: itemDone.length,
        total: selected.length,
        onDoneAssigning: onDoneAssigning
      },
    );
    // Set progress 100%
    handleProgress(1, 1);
  };

  useEffect(() => {
    switch (type) {
      case ROOM_OWNER_TYPE.ROOM:
        handleOpenModal();
        getRoomData();
        handleTitleModal(TEMPLATE_TITLE_NAME.RETRIEVING);
        break;
      case ROOM_OWNER_TYPE.USER:
        setRoomData(roomOwnerData ?? []);
        break;
      default:
        setRoomData({
          total: 0,
          items: [],
        });
        break;
    }
  }, []);

  useEffect(() => {
    const { items, total } = roomData;
    const itemProcessing = items.filter(item => item.status === PROGRESS_STATUS.PROCESSING)
    if (items.length < total) {
      const from = items.length + 1;
      const to = items.length + PAGE_SIZE;
      setPaging({
        from: from,
        to: to,
      });
    } else if (itemProcessing.length) {
      if (isCanceling) {
        handleCancel();
        return;
      }
      handleAssignRole(itemProcessing);
    } else
      if (!items.length) {
      setNeedToGoBack(true);
    }
  }, [roomData]);

  useEffect(() => {
    const { items, total } = roomData;
    if (loading) {
      setNeedToGoBack(false);
      setLoading(false);
    }
    if (items.length < total) {
      getRoomData();
    }
  }, [paging]);

  const getRoomData = async () => {
    try {
      const { data } = await roomProvider.getRoomInvalidOwner(paging.from, paging.to);
      let total = roomData.total;
      if (total === 0) {
        total = data.total;
      }
      data.items.map(item => {
        item.status = PROGRESS_STATUS.NONE;
        return item;
      });
      const items = [...roomData.items, ...data.items];
      setRoomData({
        total: total,
        items: items,
      });
      handleProgress(total, items.length, STEPS.RETRIEVING);
    } catch (e) {
      logger.error(e);
      // Can be handle error for resume get data
      handleTitleModal(TEMPLATE_TITLE_NAME.RETRIEVE_ERROR);
      handleActionModal(
        TEMPLATE_ACTION_NAME.RETRIEVE_ERROR,
        {
          onClose,
          onRetry
        },
      );
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelects = roomData.items.map((n) => n.roomId);
      setSelected(newSelects);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, roomId) => {
    const selectedIndex = selected.indexOf(roomId);

    // Set selected for item and checkbox
    if ((
      event.target?.nodeName === 'LI' ||
      event.target?.nodeName === 'BUTTON' ||
      event.target?.autocomplete
    ) && selectedIndex !== -1 ) {
      return;
    }

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, roomId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onAssignRole = async () => {
    handleTitleModal(TEMPLATE_TITLE_NAME.ASSIGNING);
    handleActionModal(
      TEMPLATE_ACTION_NAME.ASSIGNING_CANCEL,
      {onCancel},
    );
    handleOpenModal();
    setErrorAssign([]);
    roomData.items.map(item => {
      if (selected.includes(item.roomId)) {
        item.status = PROGRESS_STATUS.PROCESSING;
      }
      return item;
    });
    setRoomData({...roomData});
  }

  const handleAssignRole = async  (itemProcessing) => {
    const item = itemProcessing[0];
    let error = errorAssign;
    const options = {};
    try {
      if (type === ROOM_OWNER_TYPE.USER) {
        await roomProvider.transferOwner(
          {
            roomId: item.roomId,
            ownerId: ownerId, // ownerId only appears in props sent by user.js.
            targetUserId: item.userId ?? null, // assignOwnerRoom use targetUserId
          }
        );
      } else if (type === ROOM_OWNER_TYPE.ROOM) {
        await roomProvider.assignOwnerRoom(
          {
            roomId: item.roomId,
            userId: item.userId ?? null,
          }
        );
      } else {
        // Show error icon when wrong type
        setStatusRoomDataById(item.roomId, PROGRESS_STATUS.ERROR);
        return;
      }
      setStatusRoomDataById(item.roomId, PROGRESS_STATUS.DONE);
    } catch (e) {
      const errorData = {
        requestPayload: item,
        errorResponse: e,
      }
      error = [...errorAssign, errorData];
      setErrorAssign(error);
      // Handle show error icon when assign role error
      setStatusRoomDataById(item.roomId, PROGRESS_STATUS.ERROR);
      logger.error("handleAssignRole error", e);
    } finally {
      options.errorAssign = error;
      setRoomData({...roomData});
      const remainingItems = itemProcessing.slice(1);
      handleProgress(selected.length, selected.length - remainingItems.length, STEPS.ASSIGNING, options);
    }
  }

  const setStatusRoomDataById = (roomId, status) => {
    roomData.items.map(x => {
      if (x.roomId === roomId) {
        x.status = status;
      }
      return x;
    });
  };

  const assignUserIdToRoom = (selected) => {
    setRoomData(prevRoomData => {
      const { roomId, userId } = selected;
      const matchedRoom = prevRoomData.items.find(room => room.roomId === roomId);

      if (matchedRoom) {
        matchedRoom.userId = userId;
      }

      return { ...prevRoomData, items: [...prevRoomData.items] };
    });
  }

  const CommonLayout = ({title, children}) => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            title={title}
          />
          <Box
            sx={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {children}
          </Box>
        </Paper>
      </div>
    );
  }

  if (loading) {
    return (
      <CommonLayout
        title={title}
      >
        <LoadingProgress size={50} thickness={3}/>
        <p>
          {translate("assigning.loading")}
        </p>
      </CommonLayout>
    );
  } else if (!loading && needToGoBack) {
    return (
      <CommonLayout
        title={title}
      >
        <RoomOwnerGoBack
          type={type}
          onGoBackClick={onGoBackClick}
          onDeleteAccountClick={onDeleteAccountClick}
          translate={translate}
          deleting={deleting}
          {...props}
        />
      </CommonLayout>
    );
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, roomData.items.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <ProgressModal
        title={titleModal}
        open={modalOpen}
        handleClose={handleCloseModal}
        progress={progress}
        action={actionModal}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
        />
        <TableContainer
          className={classes.tableContainer}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={roomData.items.length}
              translate={translate}
            />
            <TableBody>
              {stableSort(roomData.items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.roomId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.roomId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.roomId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="checkbox"
                      >
                        {row.roomId}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                      >
                        <SelectOwner
                          status={row.status}
                          roomId={row.roomId}
                          onSelected={assignUserIdToRoom}
                          translate={translate}
                          type={type}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 300, 500, 1000]}
          component="div"
          count={roomData.items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Button
        variant="outlined"
        size="small"
        className={classes.buttonAssign}
        onClick={onAssignRole}
        startIcon={<AutorenewIcon />}
        disabled={!selected.length}
      >
        {translate("assigning.room_title")} ({selected.length})
      </Button>
    </div>
  );
}

const SelectOwner = (props) => {
  const PAGE_SIZE = 100;

  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [memberData, setMemberData] = useState({
    total: 0,
    items: []
  });
  const [paging, setPaging] = useState({
    from: 1,
    to: PAGE_SIZE,
  });

  const getMoreData = () => {
    fetchData();
  }

  const onSelected = (value) => {
    props.onSelected({
      roomId: props.roomId,
      userId: value?.userId ?? null,
    });
  }

  const onOpen = async () => {
    fetchData();
  }

  const fetchData = async () => {
    if (
      dataFetched
    ) {
      return;
    }

    setLoading(true);
    try {
      // ignoreDeactiveAndBot => set true
      const { data } = await roomProvider.getListMember(props.roomId, paging.from, paging.to, true);
      let total = memberData.total;
      if (memberData.total === 0) {
        total = data.total;
      }
      const enhancedItems = enhanceItemNames(props.type, data.items, props.translate);
      const items = [...memberData.items, ...enhancedItems];
      setMemberData({
        total: total,
        items: items,
      });

      if (items.length < total) {
        setPaging({
          from: items.length + 1,
          to: items.length + PAGE_SIZE,
        });
      }
      setDataFetched(true);
      setLoading(false);
    } catch (e) {
      logger.error(e);
    }

  }

  let status;
  switch (props.status) {
    case PROGRESS_STATUS.PROCESSING:
      status = <LoadingProgress size={20} thickness={3}/>
      break;
    case PROGRESS_STATUS.DONE:
      status = <CheckCircleIcon
        color="secondary"
      />;
      break;
    case PROGRESS_STATUS.ERROR:
      status = <Box color="red">
        <CancelIcon />
      </Box>
      break;
    default:
      status = <></>;
      break;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <ComboBox
        options={memberData.items}
        getMoreData={getMoreData}
        onOpen={onOpen}
        onSelected={onSelected}
        optionProperty="enhancedNames"
        idProperty="userId"
        placeholder={props.translate("assigning.auto")}
        loading={loading}
      />
      <Box sx={{
        width: 20,
        paddingLeft: 1,
      }}>
        {status}
      </Box>
    </Box>
  );
}

function enhanceItemNames(type, items, translate) {
  if (!items.length) {
    return items;
  }

  let filteredItems = items;

  if (type === ROOM_OWNER_TYPE.USER) {
    // If type is ROOM_OWNER_TYPE.USER, then filter out items with powerLevel = POWER_LEVEL.OWNER
    // powerLevel = POWER_LEVEL.OWNER in type ASSIGNING_TYPE.USER is owner
    filteredItems = items.filter(item => item.powerLevel !== POWER_LEVEL.OWNER);
  }

  return filteredItems.sort((a, b) => {
    return b.powerLevel - a.powerLevel;
  }).map(item => {
    const foundKey = findKeyByTypeAndPowerLevel(type, item.powerLevel);
    const roleText = foundKey ? `(${translate("assigning." + foundKey)})` : '';
    item.enhancedNames = `${item.displayname} ${roleText}`;
    return item;
  });
}

function findKeyByTypeAndPowerLevel(type, powerLevel) {
  return TITLE_REASSIGN_MEMBER.roles.find(role => role.powerLevel === powerLevel)?.key ?? "";
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: "65vh",
  },
  tableCell: {
    // Overwrite padding table cell
    padding: "10px !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonAssign: {
    color: "red",
    textTransform: "none",
  },
}));
