import { isProduction } from '../helper/utils';

export const logger = {
    log: (...args) => {
        if (isProduction()) {
            return;
        }
        console.log(args);
    },
    info: (...args) => {
        if (isProduction()) {
            return;
        }
        console.info(args);
    },
    warn: (...args) => {
        if (isProduction()) {
            return;
        }
        console.warn(args);
    },
    error: (...args) => {
        if (isProduction()) {
            return;
        }
        console.error(args);
    }
}
