import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { env } from "../../../env";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNotify, useTranslate } from "react-admin";
import { EBotHelp } from "../../../enum/EBotHelp";
import botProvider from "../../../synapse/botProvider";
import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const host = env.REACT_APP_PUBLIC_API_SERVER;
const brand = env.REACT_APP_BRAND;
const ENCRYPTION_VALUES = ['Default', 'False', 'Try', 'Force'];
const postMethod = "POST";
const getMethod = "GET";
const getEncryptionParams = (encryption) => ((encryption && encryption !== ENCRYPTION_VALUES[0]) ? `?encryption=${encryption.toLowerCase()}` : '');
const sendMessageEndpoint = (botToken, encryption) => (`/v1/bot/${botToken}/sendMessage${getEncryptionParams(encryption)}`);
const directEndpoint = (botToken, encryption) => `/v1/bot/${botToken}/directMessage${getEncryptionParams(encryption)}`;
const requestStatusEndpoint = (botToken, requestId) => `/v1/bot/${botToken}/request/${requestId}/status`;

const defaultEncryption = ENCRYPTION_VALUES[0];
const defaultMessage = `This message was sent from ${brand} Bot`;
const defaultRoomId = `!room_id:${env.REACT_APP_SERVER_NAME}`;
const defaultDirectUsers = ['user1@example.com'];
const defaultRequestId = 'ea8cc271-071a-4fae-bc19-1d161ab47c9b';

const getRoomBodyStr = (roomIds) => (`'roomIds=${roomIds.join(',')}'`);

const broadcastContentAPI = (message) => (`Host URL: ${host}
Method: ${postMethod}
Endpoint: ${sendMessageEndpoint("<bot_token>", "[encryption]")}
Content-type: application/x-www-form-urlencoded | application/json
Body:
  text="${message}"
`);

const sendContentAPI = (message, roomIds) => (`Host URL: ${host}
Method: ${postMethod}
Endpoint: ${sendMessageEndpoint("<bot_token>", "[encryption]")}
Content-type: application/x-www-form-urlencoded | application/json
Body:
  text="${message}"
  ${getRoomBodyStr(roomIds)}
`);


const directContentAPI = (message, users) => (`Host URL: ${host}
Method: ${postMethod}
Endpoint: ${directEndpoint("<bot_token>", "[encryption]")}
Content-type: application/x-www-form-urlencoded | application/json
Body:
  text="${message}"
  users="${users}"
`);

const requestStatusAPI = (requestId) => (`Host URL: ${host}
Method: ${getMethod}
Endpoint: ${requestStatusEndpoint("<bot_token>", requestId)}
`);

const contentAPI = (type, message, target) => {
  switch(type) {
    case EBotHelp.M_BROADCAST_MESSAGE:
      return broadcastContentAPI(message)
    case EBotHelp.M_SEND_MESSAGE:
      return sendContentAPI(message, target)
    case EBotHelp.M_DIRECT_MESSAGE:
      return directContentAPI(message, target)
    case EBotHelp.M_REQUEST_STATUS:
        return requestStatusAPI(target)
    default:
      return ""
  }
}

const contentCurl = (botToken, type, message, target, encryption) => {
  switch(type) {
    case EBotHelp.M_BROADCAST_MESSAGE:
      return `curl -X ${postMethod} ${host}${sendMessageEndpoint(
        botToken, encryption
      )} -d text="${message}"`; // Remove white space
    case EBotHelp.M_SEND_MESSAGE:
      return `curl -X ${postMethod} ${host}${sendMessageEndpoint(
        botToken, encryption
      )} -d text="${message}" -d ${getRoomBodyStr(target)}`; // Remove white space
    case EBotHelp.M_DIRECT_MESSAGE:
      return `curl -X ${postMethod} ${host}${directEndpoint(
        botToken, encryption
      )} -d text="${message}" -d users="${target}"`; // Remove white space
    case EBotHelp.M_REQUEST_STATUS:
      return `curl -X ${getMethod} ${host}${requestStatusEndpoint(
        botToken,
        target
      )}`; // Remove white space
    default:
      return ""
  }

} ;

const useStyles = makeStyles({
  container: {
    margin: 0,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    padding: "8px",
  },
  titleBlock: {
    margin: "0 0 20px 0"
  },
  shortControl: {
    minWidth: "200px",
    width: "300px",
  },
  mediumControl: {
    minWidth: "300px",
    width: "450px",
  },
  codeBlock: {
    background: "#ececec",
    marginBottom: "10px",
  },
  codeBlockCurl: {
    paddingRight: "10px",
    marginBottom: "0px",
  },
  blockSeparate: {
    marginBottom: '20px',
  },
  responseTitle: {
    margin: '10px 0',
  }
});

const HowToUseBotToken = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState("Copied");
  const [encryption, setEncryption] = useState(defaultEncryption);
  const [text, setText] = useState(defaultMessage);
  const [roomIds, setRoomIds] = useState([defaultRoomId]);
  const [directUsers, setDirectUsers] = useState(defaultDirectUsers);
  const [requestId, setRequestId] = useState(props.requestId || defaultRequestId);
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);

  const onCopy = (event) => {
    event.preventDefault();
    setCopyText(
      props.botToken
        ? translate("resources.users.action.copied")
        : translate("resources.users.action.empty")
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const onChangeEncryption = (e) => {
    setEncryption(e.target.value);
  }

  const onAddUser = () => {
    const newDirectUsers = [...directUsers];
    newDirectUsers.push(defaultDirectUsers[0]);
    setDirectUsers(newDirectUsers);
  }

  const onChangeDirectUser = (data, index) => {
    const newDirectUsers = [...directUsers];
    newDirectUsers[index] = data;
    setDirectUsers(newDirectUsers);
  }

  const onRemoveUser = (index) => {
    const newDirectUsers = directUsers.filter((_, idx) => index !== idx);
    if (!newDirectUsers.length) {
      setDirectUsers(['']);
      return
    }
    setDirectUsers(newDirectUsers);
  }

  const onAddRoomId = () => {
    const newRoomIds = [...roomIds];
    newRoomIds.push(defaultRoomId);
    setRoomIds(newRoomIds);
  }

  const onChangeRoomId = (data, index) => {
    const newRoomIds = [...roomIds];
    newRoomIds[index] = data;
    setRoomIds(newRoomIds);
  }

  const onRemoveRoomId = (index) => {
    const newRoomIds = roomIds.filter((_, idx) => index !== idx);
    if (!newRoomIds.length) {
      setRoomIds(['']);
      return
    }
    setRoomIds(newRoomIds);
  }

  const onSend = () => {
    let url = host;
    let method = postMethod;
    let data = {};
    let invalidForm = false;
    switch (props.type) {
      case EBotHelp.M_BROADCAST_MESSAGE:
        if (!text.trim()) {
          invalidForm = true;
          break;
        }
        url += sendMessageEndpoint(props.botToken);
        data = { text: text };
        break;
      case EBotHelp.M_SEND_MESSAGE:
        const validRooms = roomIds.map((r) => r?.trim()).filter((r) => r !== '');
        if (!text.trim() || !validRooms.length) {
          invalidForm = true;
          break;
        }
        url += sendMessageEndpoint(props.botToken);
        data = {
          text: text,
          roomIds: validRooms,
        };
        setRoomIds(validRooms);
        break;
      case EBotHelp.M_DIRECT_MESSAGE:
        const users = directUsers
          .map((u) => (u?.trim()))
          .filter((u) => (u !== ''));
        if (!text.trim() || !users.length) {
          invalidForm = true;
          break;
        }
        url += directEndpoint(props.botToken);
        data = {
          text: text,
          users: users.join(','),
        };
        setDirectUsers(users);
        break;
      case EBotHelp.M_REQUEST_STATUS:
        if (!requestId.trim()) {
          invalidForm = true;
          break;
        }
        url += requestStatusEndpoint(props.botToken, requestId);
        method = getMethod
        break;
      default:
        throw new Error('Invalid type');
    }

    if (invalidForm) return;

    if (encryption !== ENCRYPTION_VALUES[0]) {
      const params = new URLSearchParams();
      params.append('encryption', encryption.toLowerCase())
      url += `?${params.toString()}`;
    }

    setLoading(true);
    botProvider.send({
      url: url,
      method: method,
      data: data,
    }).then((res) => {
      setOutput(JSON.stringify(res.json, null, 4));
      notify('Send successfully', { type: 'success' });
    }).catch((e) => {
      if (e.body) {
        setOutput(JSON.stringify(e.body, null, 4));
      } else {
        setOutput(e.message);
      }
      notify(e.body?.error || e.message, { type: "error" });
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    })
  }

  const getTarget = (type) => {
    switch (type) {
      case EBotHelp.M_SEND_MESSAGE:
        return roomIds;
      case EBotHelp.M_DIRECT_MESSAGE:
        return directUsers.filter((i) => i !== '').join(',');
      case EBotHelp.M_REQUEST_STATUS:
        return requestId;
      default:
        return undefined;
    }
  }

  return (
    <>
      <Box className={classes.codeBlock}>
        <pre className={classes.container}>
          <code>{contentAPI(props.type, text, getTarget(props.type))}</code>
        </pre>
      </Box>
      <Box className={[classes.codeBlock, classes.codeBlockCurl, classes.blockSeparate]}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <pre className={classes.container}>
            <code>{contentCurl(props.botToken, props.type, text, getTarget(props.type), encryption)}</code>
          </pre>
          <CopyToClipboard text={contentCurl(props.botToken, props.type, text, getTarget(props.type), encryption)}>
            <span
              className={classes.copyClipboard}
              onClick={event => onCopy(event)}
              onKeyDown={() => {}}
              role='button'
            >
              <Tooltip title={copyText} open={isCopied} placement="top">
                <FileCopyIcon style={{ fontSize: "20", cursor: "pointer" }} />
              </Tooltip>
            </span>
          </CopyToClipboard>
        </Box>
      </Box>
      <h5 className={classes.titleBlock}>{translate("resources.bots.fields.test_api")}</h5>
      <Box>
        {
          props.type !== EBotHelp.M_REQUEST_STATUS &&
          <h6 className={classes.titleBlock}>{translate("resources.bots.fields.params")}</h6>
        }
        <Box className={classes.blockSeparate}>
          {
            props.type !== EBotHelp.M_REQUEST_STATUS &&
            <FormControl
              className={classes.shortControl}
            >
              <InputLabel id={`${props.type}_select_encryption`}>
                {translate("resources.bots.fields.encryption")}
              </InputLabel>
              <Select
                labelId={`${props.type}_select_encryption`}
                value={encryption}
                label={translate("resources.bots.fields.encryption")}
                onChange={onChangeEncryption}
              >
                {
                  ENCRYPTION_VALUES.map((enc, index) =>
                    <MenuItem
                      key={`encryption_value_${index}`}
                      value={enc}
                    >{enc}</MenuItem>)
                }
              </Select>
            </FormControl>

          }
        </Box>
        <h6 className={classes.titleBlock}>{translate("resources.bots.fields.body")}</h6>
        <Box className={classes.blockSeparate}>
          {
            props.type !== EBotHelp.M_REQUEST_STATUS &&
            <TextField
              fullWidth
              required
              size="small"
              label={translate("resources.bots.fields.message")}
              value={text}
              variant="outlined"
              multiline
              onChange={(e) => setText(e.target.value)}
              disabled={loading}
              error={text.trim() === ""}
            />
          }
        </Box>
        {
          props.type === EBotHelp.M_SEND_MESSAGE &&
          <Box className={classes.blockSeparate}>
            {
              roomIds.map((roomId, index) => (
                <div className={classes.blockSeparate} key={`direct_user_${index}`}>
                  <TextField
                    className={classes.mediumControl}
                    required
                    size="small"
                    label={translate("resources.bots.fields.room_id")}
                    value={roomId}
                    variant="outlined"
                    onChange={(e) => onChangeRoomId(e.target.value?.trim(), index)}
                    disabled={loading}
                    error={roomId.trim() === ""}
                  />
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => onRemoveRoomId(index)}
                  >
                    {translate("resources.bots.actions.remove")}
                  </Button>
                </div>
              ))
            }
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={onAddRoomId}
            >
              {translate("resources.bots.actions.add")}
            </Button>
          </Box>
        }
        {
          props.type === EBotHelp.M_DIRECT_MESSAGE &&
          <Box className={classes.blockSeparate}>
            {
              directUsers.map((directUser, index) => (
                <div className={classes.blockSeparate} key={`direct_user_${index}`}>
                  <TextField
                    className={classes.mediumControl}
                    required
                    size="small"
                    label={translate("resources.bots.fields.user")}
                    value={directUser}
                    variant="outlined"
                    onChange={(e) => onChangeDirectUser(e.target.value?.trim(), index)}
                    disabled={loading}
                    error={directUser.trim() === ""}
                  />
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => onRemoveUser(index)}
                  >
                    {translate("resources.bots.actions.remove")}
                  </Button>
                </div>
              ))
            }
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={onAddUser}
            >
              {translate("resources.bots.actions.add")}
            </Button>
          </Box>
        }
        {
          props.type === EBotHelp.M_REQUEST_STATUS &&
          <Box className={classes.blockSeparate} s>
            <TextField
              fullWidth
              required
              size="small"
              label={translate("resources.bots.fields.request_id")}
              value={requestId}
              variant="outlined"
              onChange={(e) => setRequestId(e.target.value?.trim())}
              disabled={loading}
              error={requestId.trim() === ""}
            />
          </Box>
        }
      </Box>
      <Box className={classes.blockSeparate}>
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          disabled={loading}
          onClick={onSend}
        >{translate('resources.bots.actions.send')}</Button>
      </Box>
      {
        output && <Box>
          <strong className={classes.responseTitle}>Response</strong>
          <Box className={classes.codeBlock}>
            <pre className={classes.container}>
              <code>{output}</code>
            </pre>
          </Box>
        </Box>
      }
    </>
  );
};

export default HowToUseBotToken;
