const OPEN_SYNTAX = 0x7b; /* { */
const CLOSE_SYNTAX = 0x7d; /* } */
const OPEN_CONTENT = 0x28; /* ( */
const CLOSE_CONTENT = 0x29; /* ) */

const COLOR_MAPPING = {
  primary: '#0dbd8b',
  red: 'rgb(235, 78, 59)',
  yellow: 'rgb(247, 206, 70)',
  blue: 'rgb(35, 140, 245)',
  green: 'rgb(100, 197, 100)',
  magenta: 'rgb(255, 0, 255)',
  cyan: 'rgb(121, 199,246)',
};

/**
 * If successful, returns end pos.
 * Else, returns -1
 */
const parseColorName = (
    state,
  start,
  disableNested,
  ) => {
  let level = 1;
  let found = false;
  let prevPos;
  let labelEnd = -1;
  const max = state.posMax;
  const oldPos = state.pos;

  state.pos = start + 1;

  while (state.pos < max) {
    const marker = state.src.charCodeAt(state.pos);
    if (marker === CLOSE_SYNTAX) {
      level--;
      if (level === 0) {
        found = true;
        break;
      }
    }

    prevPos = state.pos;
    state.md.inline.skipToken(state);
    if (marker === OPEN_SYNTAX) {
      if (prevPos === state.pos - 1) {
        // increase level if we find open bracket, which is not a part of any token
        level++;
      } else if (disableNested) {
        state.pos = oldPos;
        return -1;
      }
    }
  }

  if (found) {
    labelEnd = state.pos;
  }

  // restore old state
  state.pos = oldPos;

  return labelEnd;
};

/**
 * Parse color value for 'inline' options
 * @param colorName string
 * @return string
 */
const parseColorValue = (colorName) => {
  const colorValue = COLOR_MAPPING[colorName];
  if (!colorValue) {
    return colorName;
  }
  return colorValue;
};

/**
 * If successful, returns end pos.
 * Else, returns -1
 */
const parseContent = (state, start) => {
  let pos = start;
  const max = state.posMax;

  if (pos < max && state.src.charCodeAt(pos) === OPEN_CONTENT) {
    pos++;

    let level = 1;
    while (pos < max) {
      const char = state.src.charCodeAt(pos);
      if (char === CLOSE_CONTENT) {
        level--;

        if (level === 0) {
          return pos;
        }
      } else if (char === OPEN_CONTENT) {
        level++;
      }
      pos++;
    }

    // if we failed to find ")"
    return -1;
  } else {
    // if we failed to find "("
    return -1;
  }
};

export const colorPlugin = (
  md,
  {
    attributeName = 'class',
    defaultClassName = 'md-color', //
    inline = false,
  } = {},
) => {
  const tokenize = (state, silent) => {
    const max = state.posMax;

    if (state.src.charCodeAt(state.pos) !== 0x7b /* { */) {
      return false;
    }

    const labelStart = state.pos + 1;
    const labelEnd = parseColorName(state, state.pos, true);
    if (labelEnd < 0) {
      return false;
    }
    const colorName = state.src.substring(labelStart, labelEnd);

    const contentStart = labelEnd + 2;
    const contentEnd = parseContent(state, labelEnd + 1);
    if (contentEnd < 0) {
      return false;
    }

    if (!silent) {
      state.pos = contentStart;
      state.posMax = contentEnd;

      const openToken = state.push('color_open', 'span', 1);
      const attrName = attributeName ? attributeName : 'class';
      const attrValue = attributeName
        ? colorName
        : [
          `${defaultClassName}`,
          `${defaultClassName}--${colorName}`,
        ].join(' ');
      openToken.attrs = [[attrName, attrValue]];
      if (inline) {
        openToken.attrs.push([
          'style',
          `color: ${parseColorValue(colorName)};`,
        ]);
      }
      openToken.info = colorName;

      state.md.inline.tokenize(state);

      const closeToken = state.push('color_close', 'span', -1);
      closeToken.info = colorName;
    }

    state.pos = contentEnd + 1;
    state.posMax = max;
    return true;
  };

  md.inline.ruler.before('emphasis', 'color', tokenize);
};

export default colorPlugin;
