import React, {useState} from "react";
import { Autocomplete, TextField } from "@mui/material";

export const ComboBox = (
  {
    options,
    onOpen,
    onSelected,
    getMoreData,
    optionProperty,
    placeholder,
    idProperty,
    loading,
  }
) => {

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={options}
      onOpen={onOpen}
      loading={loading}
      ListboxProps={{
        onScroll: (event) => {
          const listBoxNode = event.currentTarget;
          if (
            listBoxNode.scrollTop + listBoxNode.clientHeight >=
            listBoxNode.scrollHeight - 3
          ) {
            getMoreData();
          }
        }
      }}
      renderInput={
        (props) => (
          <TextField
            size="small"
            label={placeholder}
            {...props}
          />
        )
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[idProperty] ?? option.id}>
            {option[optionProperty]}
          </li>
        );
      }}
      getOptionLabel={(option) => option[optionProperty]}
      onChange={(event, newValue) => {
        onSelected(newValue);
      }}
    />
  );
}
