import * as BOT_CONTAINERS_TYPES from "../types/botContainersTypes";

const initialState = {
  data: [],
  loading: true,
  error: null,
};

const botContainersReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOT_CONTAINERS_TYPES.INIT_BOT_CONTAINERS:
      return {
        ...state,
        loading: false,
        error: null,
        data: [],
      };
    case BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      };
    case BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data:
            action.payload && action.payload?.json
            ? action.payload.json
            : [],
      };
    case BOT_CONTAINERS_TYPES.FETCH_BOT_CONTAINERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      };
    case BOT_CONTAINERS_TYPES.RESET_BOT_CONTAINERS:
      return initialState;
    default:
      return state;
  }
};

export default botContainersReducer;
