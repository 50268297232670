import ViewListIcon from "@material-ui/icons/ViewList";
import React, { useState } from "react";
import { FormDataConsumer, FormTab, useTranslate, Button } from "react-admin";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm, Field } from "react-final-form";
import { CallDirectOptions } from '../../enum/ESetting';
import { FieldLabel } from "./SettingsStyle";

export const GeneralSettings = props => {
  const translate = useTranslate();
  const form = useForm();
  const [openDialogCallOptions, setOpenDialogCallOptions] = useState(false);

  const dialogActionCallOptions = () => {
    setOpenDialogCallOptions(false);
  };

  const changeCallOptions = (event) => {
    const selectedValue = event.target.checked;
    let optionChange;
    if (selectedValue) {
      optionChange = [...form.getState().values.CALL_DIRECT_VIA, event.target.name];
    } else {
      optionChange = form.getState().values.CALL_DIRECT_VIA?.filter(e => e !== event.target.name);
    }
    if (optionChange?.length === 0) {
      setOpenDialogCallOptions(true);
    } else {
      form.change("CALL_DIRECT_VIA", optionChange);
    }
  };

  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.general"
      icon={<ViewListIcon />}
    >
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <div>
              <div>
                <Field name="CALL_DIRECT_VIA">{()=>null}</Field>
                <FieldLabel>
                  {translate("resources.settings.fields.call_direct_via")}
                </FieldLabel>
              </div>
              <StyledFormGroup>
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.matrix_turn_server)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.matrix_turn_server}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.matrix_turn_server")}
                />
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.jitsi_meet)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.jitsi_meet}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.jitsi_meet")}
                />
              </StyledFormGroup>
              <Dialog 
                onClose={dialogActionCallOptions}
                aria-labelledby="simple-dialog-title"
                open={openDialogCallOptions}
              >
                  <DialogTitle id="alert-dialog-title">{translate('resources.settings.validations.required_call_via_title')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {translate('resources.settings.validations.required_call_via_content')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={dialogActionCallOptions} label="Ok"></Button>
                  </DialogActions>
              </Dialog>
            </div>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
};

const StyledFormGroup = withStyles({
  root: {
    marginLeft: '20px',
  }
})(FormGroup);

const StyledFormControlLabel = withStyles({
  root: {
    marginBottom: '20px',
  },
})(FormControlLabel);
