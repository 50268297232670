import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  ROOM_OWNER_TYPE,
} from "../../enum/ERoomOwner";
import CircularProgress from "@mui/material/CircularProgress";

export const RoomOwnerGoBack = (
  {
    type,
    onGoBackClick,
    onDeleteAccountClick, // ROOM_OWNER_TYPE.USER
    displayname, // ROOM_OWNER_TYPE.USER
    deleting,
    translate,
    ...props
  }
) => {
  switch (type) {
    case ROOM_OWNER_TYPE.ROOM:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>{translate("assigning.room_all_valid")}</h4>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={onGoBackClick}
          >
            {translate("assigning.back_to_list_room")}
          </Button>
        </Box>
      );
    case ROOM_OWNER_TYPE.USER:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>{translate("assigning.assign_role_completed")}</h4>
          <Box>
            {translate("assigning.continue_delete")}&nbsp;{displayname}
          </Box>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Button
              style={{
                color: deleting ? "" : "#f44336",
                borderColor: deleting ? "" : "#f44336",
              }}
              variant="outlined"
              disabled={deleting}
              startIcon={
                deleting ?
                <CircularProgress
                  size={16}
                  style={{color: "rgba(0, 0, 0, 0.12)"}}
                /> :
                <CancelIcon />}
              onClick={onDeleteAccountClick}
            >
              {translate("assigning.delete_account")}
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <Button
              color="primary"
              variant="text"
              disabled={deleting}
              startIcon={<ArrowBackIcon />}
              onClick={onGoBackClick}
            >
              {translate("assigning.back_to_list_user")}
            </Button>
          </Box>
        </Box>
      );
    default:
      return <></>;
  }
}
