import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { StyledTextField } from './FormController';
import { checkValidation } from "./FormController";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    sourceContainer: {
        display: 'flex',
        padding: '10px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
    },
});

const initValidations = {
    limit_messageErr: '',
}

const TextareaFormController = ({component, index, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [id, setId] = useState('');
    const [validations, setValidations] = useState(initValidations);

    useEffect(() => {
        setId(component.id);
        if (!id) {
            setValidations({
                ...validations,
                limit_messageErr: handleMessageErr(component.limit, 'limit'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && id !== component.id) {
            setId(component.id);
            setValidations({
                ...validations,
                limit_messageErr: handleMessageErr(component.limit, 'limit'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component, id]);

    const onFileTextChange = (e) => {
        const { name, value } = e.target;

        const inputValidation = {
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        };
        const isInValid = checkValidation(inputValidation);
        setValidations(inputValidation);
        props.onChildFormChange({
            ...component,
            [name]: value,
        }, isInValid);
    }

    const handleMessageErr = (value, field) => {
        if (!value) {
            return `resources.bots.validations.required`;
        }
        if (!(/^(?:[1-9]\d{0,2}|1000)$/.test(value))) {
            return `resources.bots.validations.invalid_1000`;
        }
        return '';
    }

    return (
        <div className={classes.container}>
            <StyledTextField
                id="limit"
                label={translate(`resources.bots.fields.testform.textarea_limit`)}
                name="limit"
                value={component.limit}
                onChange={onFileTextChange}
                variant="outlined"
                size="small"
                type="number"
                required
                error={validations.limit_messageErr}
                helperText={validations?.limit_messageErr ? translate(validations?.limit_messageErr) : ""}
            />
        </div>
    );
}

export default TextareaFormController;