import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RadioFormController from './RadioFormController';
import FileFormController from './FileFormController';
import TextareaFormController from './TextareaFormController';
import {
    MAX_LENGTH_64_REGEX,
    MAX_LENGTH_255_REGEX,
    NAME_REGEX,
    SPACES_REGEX,
} from "../BotTestForm";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    customCard: {
        position: 'relative',
        padding: '10px',
        backgroundColor: '#f7f7f7',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
        // overflow: 'hidden',
        minWidth: '0%',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    componentLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    direction: {
        position: 'absolute',
        left: '-50px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
});

const initValidations = {
    label_messageErr: 'resources.bots.validations.required',
    name_messageErr: 'resources.bots.validations.required',
    child_messageErr: '',
}

export const checkValidation = (inputValidation) => {
    return Object.keys(inputValidation)?.some((e) => inputValidation[e] !== '');
};

const FormController = ({component, index, total, onMoveUp, onMoveDown, onComponentChange, onRemoveComponent}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [id, setId] = useState('');
    const [validations, setValidations] = useState({
        ...initValidations,
        child_messageErr: component.type !== 'radio' ? '' : 'inValid'
    });

    useEffect(() => {
        setId(component.id);
        if (!id) {
            const validationsUpdated = {
                ...validations,
                label_messageErr: handleMessageErr(component.label, 'label'),
                name_messageErr: handleMessageErr(component.name, 'name'),
            };
            if (component.type === 'radio') {
                const childComponentInvalid = component?.values?.some(
                    e => !!handleMessageErr(e.label, 'label') || !!handleMessageErr(e.value, 'value')
                );
                validationsUpdated.child_messageErr =
                    childComponentInvalid ?
                    'inValid' : '';
            }
            const isInValid = checkValidation(validationsUpdated);
            onComponentChange({
                ...component,
            }, index, isInValid);
            setValidations(validationsUpdated);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && id !== component.id) {
            setId(component.id);
            setValidations({
                ...validations,
                label_messageErr: handleMessageErr(component.label, 'label'),
                name_messageErr: handleMessageErr(component.name, 'name'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component, id]);

    const onRemove = () => {
        onRemoveComponent(index);
    };

    const handleTextFieldChange = (e) => {
        const { name, value } = e.target;

        const inputValidation = {
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        };
        const isInValid = checkValidation(inputValidation);
        onComponentChange({
            ...component,
            [name]: value,
        }, index, isInValid);
        setValidations({
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        });
    };

    const onChildFormChange = (component, isInValidInput) => {
        const inputValidation = {
            ...validations,
            child_messageErr: isInValidInput ? 'isInValid' : '',
        };
        const isInValid = checkValidation(inputValidation);
        setValidations(inputValidation);
        onComponentChange({
            ...component,
        }, index, isInValid);
    };

    const onChangeRequired = (e) => {
        const selectedValue = e.target.checked;

        const isInValid = checkValidation(validations);
        onComponentChange({
            ...component,
            'required': selectedValue,
        }, index, isInValid);
    };

    const handleMessageErr = (value, field) => {
        if (!value.length) {
            return `resources.bots.validations.required`;
        }
        if ((SPACES_REGEX.test(value))) {
            return `resources.bots.validations.invalid_value`;
        }
        if (!(MAX_LENGTH_255_REGEX.test(value)) && field === 'label') {
            return `resources.bots.validations.invalid_length_description`;
        }
        if (!(MAX_LENGTH_64_REGEX.test(value)) && field === 'name') {
            return `resources.bots.validations.invalid_length_name`;
        }
        if (!(NAME_REGEX.test(value)) && field === 'name') {
            return `resources.bots.validations.invalid_value`;
        }
        return '';
    }

    return (
        <div className={classes.customCard}>
            <div className={classes.direction} >
                <div>
                    <IconButton
                      onClick={() => onMoveUp(component, index, total)}
                      disabled={index === 0}>
                        <KeyboardArrowUpIcon />
                    </IconButton>
                </div>
                <div>
                    <IconButton
                      onClick={() => onMoveDown(component, index, total)}
                      disabled={index === total - 1}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </div>
            </div>
            <div className={classes.componentLabel}>
                <div style={{ fontWeight: 'bold' }}>
                    {translate(`resources.bots.fields.testform.${component.type}`)}
                </div>
                <IconButton onClick={onRemove}>
                    <DeleteIcon />
                </IconButton>
            </div>
            <FormControlLabel
                control={
                    <Switch
                        checked={component.required || false}
                        onChange={onChangeRequired}
                        name='required'
                        color="primary"
                    />
                }
                label='Required'
            />
            <StyledTextField
                id="label"
                label={translate(`resources.bots.fields.testform.label`)}
                name="label"
                value={component.label}
                onChange={handleTextFieldChange}
                onClick={handleTextFieldChange}
                variant="outlined"
                size="small"
                required
                error={validations.label_messageErr}
                helperText={validations?.label_messageErr ? translate(validations?.label_messageErr) : ""}
            />
            <StyledTextField
                id="name"
                label={translate(`resources.bots.fields.testform.name`)}
                name="name"
                value={component.name}
                onChange={handleTextFieldChange}
                onClick={handleTextFieldChange}
                variant="outlined"
                size="small"
                required
                error={validations.name_messageErr}
                helperText={validations?.name_messageErr ? translate(validations?.name_messageErr) : ""}
            />
            { component.type === 'radio' && <RadioFormController
                    component={component}
                    onChildFormChange={onChildFormChange}
                    index={index}
                />
            }
            { component.type === 'file' && <FileFormController
                    component={component}
                    index={index}
                    onChildFormChange={onChildFormChange}
                />
            }
            { component.type === 'textarea' && <TextareaFormController
                    component={component}
                    index={index}
                    onChildFormChange={onChildFormChange}
                />
            }
        </div>
    );
}

export const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root' : {
            backgroundColor: '#ffffff',
        }
    },
})(TextField);

FormController.prototype = {
    component: PropTypes.object,
    //index, total, onMoveUp, onMoveDown, onComponentChange, onRemoveComponent
    index: PropTypes.number,
    total: PropTypes.number,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onComponentChange: PropTypes.func,
    onRemoveComponent: PropTypes.func,
}

export default FormController;
