import {
  AND_PERMISSION,
  OR_PERMISSION,
} from "../components/common/PermissionWrapper";

/**
 * Check has permission by names of permission.
 * @param {Array.<string>} names of permission
 * @param {Array.<string>} permissions current list permission that the user already have
 * @param {string} [condition=AND_PERMISSION] condition compare for each permission
 *
 * @returns {boolean}
 *
 * @example
 * hasPermission([EPermission.USERS_EXPORT], [], AND_PERMISSION)
 */
export function hasPermission(names, permissions, condition = AND_PERMISSION) {
  if (permissions.includes("*")) {
    return true;
  } else if (permissions.length === 0) {
    return false;
  } else {
    if (condition === AND_PERMISSION) {
      return names.every(name => permissions.includes(name));
    } else if (condition === OR_PERMISSION) {
      return names.some(name => permissions.includes(name));
    } else {
      return false;
    }
  }
}
