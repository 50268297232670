import { fetchUtils, HttpError } from "react-admin";

export const fetchFile = (url, options = {}, fileName) => {
    const requestHeaders = fetchUtils.createHeadersFromOptions(options);
    return fetch(url, { ...options, headers: requestHeaders })
        .then(response => response.blob()
            .then(blob => {
                if (response?.status !== 200) {
                    return {
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                    }
                }
                // Create a URL for the Blob object
                const urlRef = URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const link = document.createElement('a');
                link.href = urlRef;
                link.download = getFileName(fileName, blob.type) || 'file.zip'; // Specify the filename
                // Append the <a> element to the document body
                document.body.appendChild(link);
                // Trigger the download
                link.click();
                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(urlRef);
                return {
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                }
            })
        ).then(({ status, statusText, headers }) => {
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        statusText,
                        status,
                    )
                );
            }
            return Promise.resolve({ status, headers });
        });
};

const getFileName = (name, type) => {
    const fileType = type.substring(type.indexOf('/') + 1);
    return `${name}.${fileType}`;
};
