import React, { Fragment } from "react";
import { ServerNoticeBulkButton } from "../ServerNotices";
// import { BulkDeleteButton } from "react-admin";
import PermissionWrapper from "../common/PermissionWrapper";
import { EPermission } from "../../enum/EPermission";

export const UserBulkActionButtons = props => (
  <Fragment>
    <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
      <ServerNoticeBulkButton {...props} />
    </PermissionWrapper>
    { /* <BulkDeleteButton
      {...props}
      label="resources.users.action.delete_account"
      confirmTitle="resources.users.helper.erase"
      mutationMode="pessimistic"
    /> */ }
  </Fragment>
);
