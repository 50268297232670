import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hasPermission } from "../../helper/permission";

export const AND_PERMISSION = "AND_PERMISSION";
export const OR_PERMISSION = "OR_PERMISSION";

/**
 * Check permission for show/hide element.
 *
 * @example if you want to check permission by default with AND conditional
 *
 *<PermissionWrapper names={[ EPermission.USERS_EXPORT ]}>
 *   <CustomButton />
 *</PermissionWrapper>
 *
 * @example if you want to check permission with OR conditional
 *
 *<PermissionWrapper names={[ EPermission.USERS_EXPORT ]} condition={ OR_PERMISSION }>
 *   <CustomButton />
 *</PermissionWrapper>
 */
const PermissionWrapper = ({ permissions, children, condition, names }) => {
  return hasPermission(names, permissions, condition) ? children : null;
};

PermissionWrapper.propTypes = {
  permissions: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  condition: PropTypes.oneOf([AND_PERMISSION, OR_PERMISSION]),
};

const mapStateToProps = function (state) {
  return {
    permissions: state.permissions.permissions,
  };
};

export default connect(mapStateToProps)(PermissionWrapper);
