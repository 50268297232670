import { env } from "../env";
import { getCurrentUserId } from '../utils/storage';
import { jsonClient } from "./dataProvider"

const userProvider = {
    homeUrl: env.REACT_APP_SERVER,
    authUrl: env.REACT_APP_AUTH_SERVER,
    async remove2FA(userId) {
        const url = this.authUrl + `/user/two-factor/${userId}`;
        const options = {
            method: 'Delete',
        }
        return await jsonClient(url, options);
    },
    async removeYBK(userId) {
        const url = this.authUrl + `/security/yubikey/${userId}`;
        const options = {
            method: 'Delete',
        }
        return await jsonClient(url, options);
    },
    async checkEmailExistToUpdate(dto) {
        const url = this.authUrl + '/user/email/exist';
        const options = {
            method: 'Post',
            body: JSON.stringify(dto)
        }
        return await jsonClient(url, options);
    },
    async checkPhoneExist(dto) {
        const url = this.authUrl + '/user/phone/exist';
        const options = {
          method: 'Post',
          body: JSON.stringify(dto)
        }
        return await jsonClient(url, options);
    },
    async checkAddressExist(dto) {
        const url = this.authUrl + '/user/address/exist';
        const options = {
            method: 'Post',
            body: JSON.stringify(dto)
        }
        return await jsonClient(url, options);
    },
    async deactivatedUser(userId, dto) {
        const url = this.authUrl + `/user/deactivate/${userId}`;
        const options = {
            method: 'Put',
            body: JSON.stringify(dto),
        }
        return await jsonClient(url, options);
    },
    async getSecurityStatus(userId) {
        const url = this.authUrl + `/user/security/status/${userId}`;
        const options = {
            method: 'Get',
        }
        return await jsonClient(url, options);
    },
    async getSelfProfile() {
        const userId = getCurrentUserId();
        const url = this.homeUrl + `/_matrix/client/v3/profile/${userId}`;
        const options = {
            method: 'Get',
        };
        const res = await jsonClient(url, options);
        res.json.userId = userId;
        return res.json;
    },
    async getSelfUsername() {
        const url = this.authUrl + `/user/username`;
        const options = {
            method: 'Get',
        };
        const res = await jsonClient(url, options);
        return res.json;
    },
    async getSelfThreePId() {
        const url = this.homeUrl + `/_matrix/client/v3/account/3pid`;
        const options = {
            method: 'Get',
        };
        const res = await jsonClient(url, options)
        return res.json;
    },
    async getSelfTwoFactorStatus() {
        const url = this.authUrl + `/user/two-factor`;
        const options = {
            method: 'Get',
        };
        const res = await jsonClient(url, options)
        return res.json;
    },
    async checkUsernameExist(dto) {
        const url = this.authUrl + `/user/username/existed`;
        const options = {
            method: 'Post',
            body: JSON.stringify(dto),
        }
        return await jsonClient(url, options);
    },
    /* Room migration */
    async getOwnerRoomsByUser(userId) {
        const url = this.authUrl + `/user/${userId}/list-owner-rooms`;
        const options = {
            method: 'Get',
        };
        const res = await jsonClient(url, options)
        return res.json;
    },
    async deleteMultiDevices(userId, dto) {
        const url = this.homeUrl + `/_synapse/admin/v2/users/${userId}/delete_devices`;
        const options = {
            method: 'Post',
            body: JSON.stringify(dto),
        }
        const res = await jsonClient(url, options)
        return res.json;
    },
};

export default userProvider;
