import React from 'react';
import {
    useTranslate,
    useAuthenticated
} from "react-admin";
import Button from '@material-ui/core/Button';
import HotTubIcon from "@material-ui/icons/HotTub";
import HistoryIcon from '@material-ui/icons/History';

const styles = {
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    icon: {
        width: '9em',
        height: '9em',
    },
    message: {
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
        opacity: 0.5,
        margin: '0 1em',
    },
    toolbar: {
        textAlign: 'center',
        marginTop: '1em',
    },
    button: {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#e0e0e0',
        padding: '6px 16px',
    },
};

function goBack() {
    window.location.reload();
    window.location = "/";
}

export const NotFound = ({ ...props }) => {
    const translate = useTranslate();
    useAuthenticated(); // redirects to login if not authenticated
    return (
        <div style={styles.container}>
            <div style={styles.message}>
                <HotTubIcon style={styles.icon} />
                <h1>
                  Not Found
                </h1>
                <div>
                    {translate('ra.message.not_found')}
                </div>
            </div>
            <div style={styles.toolbar}>
                <Button 
                    
                    size="medium" 
                    onClick={goBack}
                    style={styles.button}
                >
                    <HistoryIcon />
                    GO BACK
                </Button>
            </div>
        </div>
    );
}
