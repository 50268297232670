import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Radio, RadioGroup, TextField } from "@mui/material";
import { getTimeByDays, getDaysByTime } from "../../../src/helper/utils";

const MAX_VALUE = 30;
const MIN_VALUE = 1;
const DISABLE_VALUE = -1;
const CustomLabel = (
  {
    translate,
    selectedValue,
    customValue,
    onCustomDaysChange,
  }
) => {
  const [value, setValue] = useState(MIN_VALUE);

  useEffect(() => {
    setValue(customValue);
  }, []);

  const handleCustomDays = (event) => {
    onCustomDaysChange(event.target.value);
    setValue(event.target.value);
  }

  const onBlur = () => {
    let positiveValue = parseInt(customValue);
    if (customValue > MAX_VALUE) {
      positiveValue = MAX_VALUE;
    } else if (customValue < MIN_VALUE) {
      positiveValue = MIN_VALUE;
    }
    setValue(positiveValue);
    onCustomDaysChange(positiveValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        id="custom-days"
        variant="outlined"
        disabled={Number(selectedValue) > 0}
        type="number"
        value={value}
        InputProps={{
          inputProps: {
            max: MAX_VALUE,
            min: MIN_VALUE,
          },
          sx: {
            width: "100px"
          }
        }}
        onChange={handleCustomDays}
        onBlur={onBlur}
      />
      <Box
        sx={{
          paddingLeft: 1
        }}
      >
        {translate('resources.settings.fields.days')}
      </Box>
    </Box>
  );
};

// const getTimeByDays = (days) => {
//   return days * 24 * 60 * 60 * 1000;
// }

// const getDaysByTime = (time) => {
//   return time / 24 / 60 / 60 / 1000;
// }

export const AutoDeleteMessages = (
  {
    translate,
    maxLifetime,
    switchLabel,
    onMaxLifetimeChange,
    firstSelected, // When enable selected first value
  }
) => {
  const [autoChecked, setAutoChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(DISABLE_VALUE);
  const [customValue, setCustomValue] = useState(MIN_VALUE);

  useEffect(() => {
    // Check and set value for switch and radio
    if (maxLifetime > 0) {
      setAutoChecked(true);
      const days = getDaysByTime(maxLifetime);
      if (options.find(x => x.value === days)) {
        setSelectedValue(days);
      } else {
        // Custom checked
        setSelectedValue(0);
        setCustomValue(days);
      }
    }
  }, []);

  useEffect(() => {
    // Send to props max lifetime value
    const value = Number(selectedValue)
    if (value > 0) {
      onMaxLifetimeChange(getTimeByDays(selectedValue));
    } else if (value === 0) {
      onMaxLifetimeChange(getTimeByDays(customValue));
    } else {
      onMaxLifetimeChange(DISABLE_VALUE);
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    // Change radio value
    setSelectedValue(event.target.value);
  };

  const onHandleChangeAutoDeleteConfig = () => {
    setAutoChecked((prev) => {
      if (!prev) {
        // If checked -> set default 30 days
        let selectedDay;
        if (firstSelected) {
          selectedDay = options[0].value;
        } else {
          selectedDay = MAX_VALUE;
        }
        setSelectedValue(selectedDay);
      } else {
        setSelectedValue(DISABLE_VALUE);
      }
      return !prev;
    });
  }

  const onCustomDaysChange = (days) => {
    setCustomValue(days);
    onMaxLifetimeChange(getTimeByDays(days));
  }

  const options = useMemo(
    () => [
    {
      value: 1,
      label: translate('resources.settings.fields.one_day'),
    },
    {
      value: 3,
      label: translate('resources.settings.fields.days_plural', {
        days: 3
      }),
    },
    {
      value: 7,
      label: translate('resources.settings.fields.one_week'),
    },
    {
      value: 14,
      label: translate('resources.settings.fields.weeks_plural', {
        weeks: 2
      }),
    },
    {
      value: 30,
      label: translate('resources.settings.fields.one_month'),
    },
    {
      value: 180,
      label: translate('resources.settings.fields.months_plural', {
        months: 6
      }),
    },
    {
      value: 365,
      label: translate('resources.settings.fields.one_year', {
        year: 1
      }),
    },
    {
      value: 0,
      label:
        <CustomLabel
          selectedValue={selectedValue}
          translate={translate}
          customValue={customValue}
          onCustomDaysChange={onCustomDaysChange}
        />,
    },
  ], [autoChecked, customValue, onCustomDaysChange, translate]);

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={autoChecked}
            onChange={onHandleChangeAutoDeleteConfig}
            name="config_auto_delete_value"
            color="primary"
          />
        }
        label={switchLabel}
      />
      <Box
        sx={{
          whiteSpace: "nowrap",
          color: "gray",
          fontSize: "0.8em"
        }}
      >
        {translate("resources.settings.fields.auto_delete_title")}
      </Box>
      {
        autoChecked &&
        <Box
          sx={{
            paddingLeft: 2,
          }}
        >
          <RadioGroup
            aria-label="auto-delete"
            name="auto-delete"
            value={selectedValue}
            onChange={handleChange}
          >
            {
              options.map(option => (
                <FormControlLabel
                  key={option.value}
                  control={<Radio />}
                  value={option.value}
                  label={option.label}
                />
              ))
            }
          </RadioGroup>
        </Box>
      }
    </Box>
  );
};

