export const FieldLabel = ({ children }) => {
    return (
        <div
            style={{
            color: 'rgba(0, 0, 0, 0.54)',
            padding: '10px 0px 20px',
            fontSize: '18px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            lineHeight: '1',
            letterSpacing: '0.00938em',
            marginTop: '0px',
            }}
        >
            { children }
        </div>
    );
}
  
export const GroupContent = ({ children }) => {
    return (
        <div
            style={{
                marginLeft: '20px',
            }}
        >
            { children }
        </div>
    );
}
