import { safeParse } from "./common";
const META_DATA_KEY = 'meta_data';

export function getCurrentUserId() {
  return localStorage.getItem("user_id");
}

/**
 * Get meta data, if not provide key, return all as object
 * @param key (optional) Key of meta data
 * @returns {any}
 */
export function getMetaData(key) {
  const raw = localStorage.getItem(META_DATA_KEY);
  const metaData = safeParse(raw);
  if (key !== undefined && metaData != null && typeof metaData === 'object') {
    return metaData[key];
  }
  return metaData ? metaData : {};
}

/**
 * Set meta data by key
 * @param key (required)
 * @param value (required)
 */
export function setMetaData(key, value) {
  const currentData = getMetaData();
  currentData[key] = value;
  localStorage.setItem(META_DATA_KEY, JSON.stringify(currentData))
}

/**
 * Delete meta data by key
 * @param key
 */
export function delMetaData(key) {
  const currentData = getMetaData();
  if (currentData[key]) {
    delete currentData[key];
    localStorage.setItem(META_DATA_KEY, JSON.stringify(currentData))
  }
}
