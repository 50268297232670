import React, { useState } from "react";
import { useTranslate, useNotify, Link } from "react-admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconCode from "@material-ui/icons/Code";
import IconCancel from "@material-ui/icons/Cancel";
import IconReply from "@material-ui/icons/Reply";
import SendIcon from "@material-ui/icons/Send";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Button, TextField, Avatar, ThemeProvider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { deepOrange } from "@mui/material/colors";

import botProvider from "../../../../synapse/botProvider";
import muiTheme from "../../../../helper/muiTheme";
import { CopyToClipboard } from "react-copy-to-clipboard";

const getChar = (user) => {
  return user?.substring(0, 1)?.toUpperCase();
};

const getTime = (date) => {
  return date.getHours() + ":" + date.getMinutes();
};

const useMessageStyles = makeStyles((theme) =>
  ({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageOther: {
      position: "relative",
      marginLeft: "10px",
      marginBottom: "15px",
      padding: "10px 10px 30px",
      backgroundColor: "#e8edf4",
      minWidth: "150px",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      // border: "1px solid #97C6E3",
      borderRadius: "10px",
      borderTopLeftRadius: "0",
    },
    messageSelf: {
      position: "relative",
      marginRight: "10px",
      marginBottom: "15px",
      padding: "10px 10px 30px",
      backgroundColor: "#e7f8f3",
      minWidth: "150px",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      // border: "1px solid #dfd087",
      borderRadius: "10px",
      borderTopRightRadius: "0",
    },
    messageContent: {
      maxWidth: '100%',
      wordWrap: "break-word",
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "3px",
      right: "5px",
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "10px",
      marginBottom: "10px",
    },
    actionsBtn: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "-35px",
      color: theme.palette.action.primary,
    },
  }),
);

const MessageBubble = ({ event, onReply, onSource }) => {
  const classes = useMessageStyles();

  if (!event) return <></>;

  return <div className={classes.messageRow}>
    <Avatar
      sx={{ bgcolor: deepOrange[500] }}
      className={classes.orange}
    >{getChar(event.user)}</Avatar>
    <div>
      <div className={classes.displayName}>{event.user}</div>
      <div className={classes.messageOther}>
        <div>
          <p className={classes.messageContent}>{event.text}</p>
        </div>
        <div className={classes.messageTimeStampRight}>
          <Tooltip title={event.created_at?.toLocaleString?.()}>
            <span>{getTime(new Date(event.created_at))}</span>
          </Tooltip>
        </div>
        <div className={classes.actionsBtn}>
          <div>
            <Tooltip title="Reply to this event">
              <IconReply onClick={() => {
                onReply();
              }} />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Toggle show code">
              <IconCode onClick={() => {
                onSource();
              }} />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

const codeBlockStyles = makeStyles((theme) =>
  ({
    codeBlock: {
      maxWidth: "100%",
      overflowX: "scroll",
      backgroundColor: theme.palette.action.selected,
      padding: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    copyClipboard: {
      position: "absolute",
      right: "0",
      top: "10px",
      cursor: "pointer",
      height: "30px",
      margin: "0 10px",
    },
    copyIcon: {
      fontSize: "23"
    }
  })
);
const BlockCode = ({ content }) => {
  const translate = useTranslate();
  const [toolTip, setToolTip] = useState({ header: '', body: '' });
  const classes = codeBlockStyles();
  const source = {...content};
  delete source.created_at;
  delete source.response_at;
  delete source.headers;
  return <div>
    <div>
      Headers:
      <div className={classes.codeBlock}>
        <pre>{JSON.stringify(content.headers, null, 4)}</pre>
        <CopyToClipboard text={JSON.stringify(content.headers)}>
          <span
            className={classes.copyClipboard}
            onClick={(event) => {
              event.preventDefault();
              setToolTip({ header: translate("resources.users.action.copied"), body: ''});
              setTimeout(() => {
                setToolTip({ header: '', body: '' });
              }, 1500);
            }}
          >
            <Tooltip title={toolTip.header} open={!!toolTip.header} placement="top">
              <FileCopyIcon className={classes.copyIcon} />
            </Tooltip>
          </span>
        </CopyToClipboard>
      </div>
    </div>
    <div>
      Body:
      <div className={classes.codeBlock}>
        <pre>{JSON.stringify(source, null, 4)}</pre>
        <CopyToClipboard text={JSON.stringify(source)}>
          <span
            className={classes.copyClipboard}
            onClick={(event) => {
              event.preventDefault();
              setToolTip({ header: '', body: translate("resources.users.action.copied")});
              setTimeout(() => {
                setToolTip({ header: '', body: '' });
              }, 1500);
            }}
          >
            <Tooltip title={toolTip.body} open={!!toolTip.body} placement="top">
              <FileCopyIcon className={classes.copyIcon} />
            </Tooltip>
          </span>
        </CopyToClipboard>
      </div>
    </div>
  </div>;
};

const replyingStyles = makeStyles((theme) => ({
  replyingContainer: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    padding: "5px",
  },
  replying: {
    fontSize: ".85em",
    color: theme.palette.text.disabled,
    margin: "5px",
  },
  replyUser: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
    color: theme.palette.text.secondary,
  },
  replyMessage: {
    margin: "5px 5px 10px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  replyCancelBtn: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: theme.palette.text.primary,
  },
}));

const ReplyingPreview = ({ event, onCancel }) => {
  const classes = replyingStyles();

  return <div className={classes.replyingContainer}>
    <div className={classes.replying}>Replying</div>
    <div className={classes.replyUser}>
      <Avatar
        sx={{ bgcolor: deepOrange[500] }}
        style={{
          width: "20px",
          height: "20px",
          fontSize: "0.65em",
          margin: "0 5px 0",
        }}
        className={classes.replyUserAvatar}
      >{getChar(event.user)}</Avatar>
      <div>{event.user}</div>
    </div>
    <div className={classes.replyMessage}>{event.text}</div>
    <div className={classes.replyCancelBtn}>
      <IconCancel onClick={() => onCancel()} />
    </div>
  </div>;
}

const ProxyDetailsDialog = ({ botToken, onCloseDialogDetails, event }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [showSource, setShowSource] = useState(false);
  const [replyToEvent, setReplyToEvent] = useState(false);
  const [content, setContent] = useState("");

  const onClose = () => {
    onCloseDialogDetails();
  };

  const onChangeContent = (event) => {
    setContent(event.target.value);
  };

  const onSend = () => {
    const requestBody = {
      room_id: event.room_id,
      text: content,
      event_id: replyToEvent ? event.event_id : undefined,
    };
    setLoading(true);
    botProvider.proxyResponse(botToken, requestBody).then(() => {
      setContent("");
      setReplyToEvent(false);
      notify("Send response successfully", { type: "success" });
      onClose();
    }).catch((e) => {
      notify(e.message, { type: "error" });
    }).finally(() => {
      setLoading(false);
    });
  };


  return <ThemeProvider theme={muiTheme}>
    <StyledDialog onClose={onClose} open={!!event}>
      <DialogTitle>Proxy details</DialogTitle>
      <DialogContent>
        <h3 style={{ margin: '0 0 25px', fontSize: '1.15em', fontWeight: 'normal' }}>
          Room:
          <Link to={`/rooms/${encodeURIComponent(event.room_id)}/show`} target="_blank" rel="noopener noreferrer">
            {event.room_id}
          </Link>
        </h3>
        <MessageBubble
          event={event}
          onReply={() => {
            setReplyToEvent(true);
          }}
          onSource={() => {
            setShowSource(!showSource);
          }}
        />
        { showSource && <BlockCode content={event} /> }
        { replyToEvent && <ReplyingPreview
          event={event}
          onCancel={() => {
            setReplyToEvent(false)
          }}
        /> }
        <TextField
          fullWidth
          required
          label={translate("resources.bots.fields.message")}
          value={content}
          variant="outlined"
          minRows={1}
          maxRows={15}
          multiline
          onChange={onChangeContent}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<IconCancel />}
          onClick={onClose}
          color="inherit"
          variant="contained"
          disabled={loading}
        >Cancel</Button>
        <Button
          endIcon={<SendIcon />}
          onClick={onSend}
          color="primary"
          variant="contained"
          disabled={loading || !content.length}
        >Send</Button>
      </DialogActions>
    </StyledDialog>
  </ThemeProvider>;
};

const StyledDialog = withStyles({
  paperWidthSm: {
    width: "100%",
    maxWidth: "50vw",
  },
})(Dialog);

export default ProxyDetailsDialog;
