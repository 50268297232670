import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import PlayIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  autoUpdate: {
    margin: "10px 0",
    width: "500px",
  },
  refreshBtn: {

    marginLeft: "10px",
  },
  icon: {
    fontSize: "1.15em",
    marginRight: "5px",
  }
}));


const RefreshInterval = ({ intervalTime, onStop, onResume, onForce, refreshLabel }) => {
  const [refreshing, setRefreshing] = useState(false);
  const classes = useStyles();


  useEffect(() => {
    return () => {
      console.log(`On component unmount RefreshInterval`);
      onStop();
    };
  }, []);

  return <div className={classes.autoUpdate}>
    {refreshing ? `Auto updating... (every ${intervalTime ?? 5} seconds) ` : "Auto update has stopped! "}
    <Button
      variant="contained"
      onClick={() => {
        setRefreshing(!refreshing);
        if (refreshing) {
          onStop?.();
          return;
        }
        onResume?.();
      }}
      className={classes.refreshBtn}
    >
      {
        refreshing ?
          <StopIcon className={classes.icon} /> :
          <PlayIcon className={classes.icon} />}
      {refreshing ? "Stop" : "Resume"}
    </Button>
    {
      !refreshing && <Button
        sx={{ marginLeft: "20px" }}
        variant="outlined"
        onClick={() => {
          onForce?.();
        }}
      >
        <CachedIcon className={classes.icon} />
        {refreshLabel ?? "Refresh"}</Button>
    }
  </div>;
};

export default RefreshInterval;

