import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FETCH_START,
  FETCH_END,
  Loading,
  TabbedForm,
  useNotify,
  Title,
  useTranslate,
  Button,
  Toolbar,
  SaveButton,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { logger } from '../utils/logger';
import { ErrorComponent } from "./Error";
import { useDispatch } from "react-redux";
import settingProvider from "../synapse/settingProvider";
import { ThirdPartyIdSettings } from "./settings/ThirdPartyIdSettings";
import { OTPSettings } from "./settings/OTPSettings";
import { LoginSettings } from "./settings/LoginSettings";
import { SecuritySettings } from "./settings/SecuritySettings";
import { ThemeProvider } from "@mui/material";
import Card from '@material-ui/core/Card';
import { BotSettings } from "./settings/BotSettings";
import { GeneralSettings } from "./settings/GeneralSettings";
import { CronSettings } from "./settings/CronSettings";
import muiTheme from "../helper/muiTheme";

const ToolbarSaveButton = ({ invalidForm, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.invalid || invalidForm}
      />
    </Toolbar>
  );
};

ToolbarSaveButton.prototype = {
  invalidForm: PropTypes.bool.isRequired,
}

export const Settings = props => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();
  const [openDialogError, setOpenDialogError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [invalidForm, setInvalidForm] = useState(false);

  useEffect(() => {
    dispatch({ type: FETCH_START });
    settingProvider.getSettings()
      .then((settings) => {
        setSettings(settings);
      }).catch(error => {
      notify(error.message, { type: "error" });
      setError(error);
    }).finally(() => {
      setLoading(false);
      // eslint-disable-next-line
      dispatch({ type: FETCH_END });
    });
    // eslint-disable-next-line
  }, []); // * note that [] is just run once

  const onSave = (formData) => {
    if (JSON.stringify(settings) === JSON.stringify(formData)) {
      return;
    }
    const data = {};
    Object.keys((formData)).forEach((key) => {
      if (JSON.stringify(formData[key]) === JSON.stringify(settings[key])) return;
      data[key] = formData[key];
    });
    settingProvider.saveSettings({ data })
      .then(() => {
        const newSettings = {...settings};
        Object.keys(data).forEach((k) => {
          newSettings[k] = data[k];
        });
        setSettings(newSettings);
        notify("resources.settings.notifications.save_success");
      })
      .catch(error => {
        logger.error(error.message);
        if (error && error.body && error.body.errcode === 'M_CANNOT_CHANGE_CONFIG_WHITELISTED_IP') {
          setMessageError(error.body.error);
          setOpenDialogError(true);
          return;
        }
        notify(
          "resources.settings.notifications.save_failed",
          { type: "error" },
        );
      });
  };

  const dialogActionError = () => {
    setOpenDialogError(false);
  };

  const onBotSettingsChange = (value) => {
    value ? setInvalidForm(false) : setInvalidForm(true);
  };

  const onCronSettingsChange = (value) => {
    value ? setInvalidForm(true) : setInvalidForm(false);
  };

  const onSecuritySettingsChange = (valid) => {
    valid ? setInvalidForm(true) : setInvalidForm(false);
  }

  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <Card>
      <ThemeProvider theme={muiTheme}>
        <TabbedForm {...props}
          initialValues={settings}
          save={onSave}
          toolbar={
            <ToolbarSaveButton
              invalidForm={invalidForm}
              {...props}
            />
          }
        >
          <GeneralSettings value={settings} />
          <OTPSettings value={settings} />
          <LoginSettings value={settings} path="login" />
          <ThirdPartyIdSettings value={settings} path="3pid" />
          <SecuritySettings
              value={settings}
              path="two-factor"
              onChange={onSecuritySettingsChange}
          />
          <BotSettings value={settings} path="bot" props={props} onChange={onBotSettingsChange}/>
          <CronSettings value={settings} path="cron" props={props} onChange={onCronSettingsChange}/>
          <Title title={'resources.settings.name'} />
        </TabbedForm>
        <Dialog
            onClose={dialogActionError}
            aria-labelledby="simple-dialog-title"
            open={openDialogError}
          >
              <DialogTitle id="alert-dialog-title">{translate('resources.settings.validations.cannot_change_config_wl')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {translate(messageError)}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogActionError} label="Ok"></Button>
              </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Card>
  )
};
