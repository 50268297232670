import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    content: {
        marginBottom: '20px'
    },
    row: {
        display: 'flex'
    },
    key: {
        textTransform: 'capitalize',
        marginRight: '5px'
    },
});

const IpAddressDetailsDialog = ({ data, onClose }) => {
    const classes = useStyles();
    const [details, setDetails] = useState({});

    useEffect(() => {
        if (data?.metadata) {
            setDetails(JSON.parse(data?.metadata));
        }
    }, [data]);

    const onCloseDiaglog = () => {
        setDetails('');
        onClose();
    };

    return <StyledDialog onClose={onCloseDiaglog} aria-labelledby="reset-dialog-title" open={data.isOpen}>
        <DialogTitle id="reset-dialog-title">{data.ipAddress}</DialogTitle>
        <DialogContent>
            <div className={classes.content}>
                {
                    Object.entries(details)?.map(([key, value]) => (
                        <div className={classes.row}>
                            <div className={classes.key}>{key}:</div>
                            <div>{value}</div>
                        </div>
                    ))
                }
            </div>
        </DialogContent>
    </StyledDialog>
}

const StyledDialog = withStyles({
    paperWidthSm: {
        width: '100%',
        maxWidth: '40vw'
    },
})(Dialog);

export default IpAddressDetailsDialog;
