import { env } from "../env";
import { jsonClient } from "./dataProvider"
import { stringify } from "query-string";
import { fetchFile } from "../utils/fetch";

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

const botProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  api_public_url: env.REACT_APP_PUBLIC_API_SERVER,
  async getList(res, params) {
    const { search, enable, isPublic, container } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      size: perPage,
      page: page,
      search: search?.trim(),
      enable: enable,
      public: isPublic,
      containerId: container && container === 'All' ? undefined : container,
      order_by: field,
      dir: getSearchOrder(order),
    };
    const url = this.base_url + `/bots?${stringify(query)}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json['items'].map((item, index) => res.map(item, index, from)),
      total: res.total(json, from, perPage),
    }));
  },

  async getBot(botId) {
    const url = this.base_url + `/bots/${botId}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json,
    }));
  },

  async createBot(data) {
    const url = this.base_url + `/bots`;
    let formData = new FormData();

    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('avatar', data.avatar);
    formData.append('containerId', data.containerId);
    formData.append('isPublic', data.isPublic ?? false);

    const options = {
      method: 'POST',
      header: {
        "Content-Type": "multipart/form-data;"
      },
      body: formData
    }
    return await jsonClient(url, options);
  },

  async updateBot(data) {
    const url = this.base_url + `/bots/${data.id}`;
    let formData = new FormData();

    for (var key in data) {
      if (data.hasOwnProperty(key) && key !== 'id') {
        formData.append(key, data[key]);
      }
    }

    const options = {
      method: 'PUT',
      header: {
        "Content-Type": "multipart/form-data;"
      },
      body: formData
    }
    return await jsonClient(url, options);
  },

  async deleteBot(botId) {
    const url = this.base_url + `/bots/${botId}`;
    const options = {
      method: 'DELETE',
    }
    return await jsonClient(url, options);
  },

  async generateShareKey(botId) {
    const url = this.base_url + `/share/bot/presigned-key/${botId}`;
    const options = {
      method: 'POST',
      body: JSON.stringify({})
    }
    return await jsonClient(url, options);
  },

  async generateBotToken(botId) {
    const url = this.base_url + `/bots/${botId}/gen-bot-token`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({})
    }
    return await jsonClient(url, options);
  },

  async getListRoom(botId) {
    const url = this.base_url + `/bots/${botId}/rooms`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options);
  },

  async getQueueInfo(botId) {
    const url = this.base_url + `/bots/queue/${botId}/info`;
    const options = {
      method: 'GET',
    };
    return await jsonClient(url, options);
  },

  async leaveRooms(botId, roomIds) {
    let ids = roomIds;
    if (typeof ids === 'string') {
      ids = [roomIds];
    }
    const url = this.base_url + `/bots/remove-from-rooms/${botId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        roomIds: ids,
      })
    }
    return await jsonClient(url, options);
  },

  async getContainers() {
    const url = env.REACT_APP_AUTH_SERVER + '/bots/containers';
    const options = {
      method: 'GET',
    };
    return await jsonClient(url, options);
  },

  async sendForm(botToken, signature, data) {
    const url = env.REACT_APP_PUBLIC_API_SERVER + `/v1/bot/${botToken}/form`;
    let formData = new FormData();

    formData.append('from', data.from);
    formData.append('to', data.to);
    formData.append('description', data.description);
    if (data.metadata) {
      formData.append('metadata', data.metadata);
    }
    if (data.file?.length) {
      for (const f of data.file) {
        formData.append('file', f);
      }
    }
    formData.append('form', JSON.stringify(data.form));
    const options = {
      method: 'POST',
      headers: new Headers({
        "x-signature": `${signature}`,
      }),
      body: formData
    }
    return await jsonClient(url, options);
  },

  async send(config) {
    let body;
    if (config.data && ['POST', 'PUT', 'PATCH'].includes(config.method)) {
      body = JSON.stringify(config.data);
    }
    return jsonClient(config.url, {
      method: config.method,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body,
    })
  },

  async getListBotResponse(botId, type) {
    const url = env.REACT_APP_AUTH_SERVER + `/bots/${botId}/callback/${type}`;
    const options = {
      method: 'GET',
    };
    return await jsonClient(url, options).then(({ json }) => ({
      data: json?.map((item, index) => { return {
          ...item,
          id: index,
          metadata: JSON.stringify(item.metadata),
          headers: item.headers,
          created_at: (item.created_at && item.created_at instanceof Date) ?
              item.created_at : new Date(item.created_at),
          response_at: (item.response_at && item.response_at instanceof Date) ?
              item.response_at : new Date(item.response_at),
      }}),
    }));
  },

  /**
   * Delete list response for form and proxy
   *
   * @param botId string
   * @param type form|proxy
   */
  async deleteListResponses(botId, type) {
    const url = env.REACT_APP_AUTH_SERVER + `/bots/${botId}/callback/${type}`;
    const options = {
      method: 'DELETE',
    };
    return await jsonClient(url, options);
  },

  async getBotFormResponseDetails(botToken, signature, data) {
    const url = env.REACT_APP_PUBLIC_API_SERVER + `/v1/bot/${botToken}/form/response`;
    const options = {
      method: 'POST',
      headers: new Headers({
        "x-signature": `${signature}`,
        "content-type": "application/json; charset=utf-8"
      }),
      body: JSON.stringify(data)
    };

    return await jsonClient(url, options);
  },

  async downloadFileFromResponse(botToken, signature, requestBody, fileName) {
    const url = env.REACT_APP_PUBLIC_API_SERVER + `/v1/bot/${botToken}/form/file`;
    const options = {
      method: 'POST',
      headers: new Headers({
        "x-signature": `${signature}`,
      }),
      body: JSON.stringify(requestBody)
    };
    return await fetchFile(url, options, fileName);
  },

  async testCallback(url, data, testOpt) {
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      skipLogout: true,
    }
    options.headers = new Headers();
    if (testOpt.signature) {
      options.headers.append('x-signature', testOpt.signature);
    }
    if (testOpt.token) {
      const tokenParts = testOpt.token.split(' ');
      const token = tokenParts.length > 1 ? tokenParts : `Bearer ${tokenParts[0]}`;
      options.headers.append('Authorization', token);
    }
    return await jsonClient(url, options);
  },

  async proxyResponse(botToken, requestBody) {
    const url = env.REACT_APP_PUBLIC_API_SERVER + `/v1/bot/${botToken}/proxy/response`;
    const options = {
      method: 'POST',
      body: JSON.stringify(requestBody)
    };
    return await jsonClient(url, options);
  }
};

export default botProvider;
