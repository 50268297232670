import React from "react";
import {
  FormTab,
  PasswordInput,
  minLength,
  required
} from "react-admin";
import LockIcon from "@material-ui/icons/Lock";

const confirmPasswordValidation = (value, form) => {
  if (value !== form.new_password) {
    return "resources.profile.fields.does_not_match_new_password";
  }
  return undefined;
};

const passwordValidation = (value, _) =>  {
  if ((/^\s*$/.test(value?.trim())) || (/\s/.test(value))) {
      return 'resources.users.invalid_password';
  }
  return undefined;
}

export const ProfileChangePassword = props => {
  return (
    <FormTab
      {...props}
      label="resources.profile.tabs.change_password"
      icon={<LockIcon />}
    >
      <PasswordInput
        label={"resources.profile.fields.old_password"}
        source="old_password"
        variant="outlined"
        autoComplete="off"
        validate={[
          required("resources.profile.fields.old_password_required"),
          minLength(6, "resources.profile.fields.minlength_password")
        ]}
      />
      <PasswordInput
        label={"resources.profile.fields.new_password"}
        source="new_password"
        variant="outlined"
        autoComplete="off"
        validate={[
          required("resources.profile.fields.new_password_required"),
          minLength(6, "resources.profile.fields.minlength_password"),
          passwordValidation
        ]}
      />
      <PasswordInput
        label={"resources.profile.fields.re_enter_new_password"}
        source="re_enter_new_password"
        variant="outlined"
        autoComplete="off"
        validate={[
          required("resources.profile.fields.re_enter_new_required"),
          confirmPasswordValidation,
          passwordValidation
        ]}
      />
    </FormTab>
  );
};
