import UpdateIcon from '@material-ui/icons/Update';
import React, { useEffect, useState } from "react";
import {
    FormDataConsumer,
    FormTab,
    required,
    TextInput,
    useNotify,
    useTranslate,
} from "react-admin";
import { FieldLabel } from './SettingsStyle';
import { CronExpression } from '../../enum/ECronExpression';
import { CronForm } from './CronSettings/CronForm';
import settingProvider from "../../synapse/settingProvider";

export const CronSettings = ({...props}) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [cronOptions, setCronOptions] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        const cronOptions = Object.keys(CronExpression).map(
            (key) => ({ key, value: CronExpression[key]})
        );
        setCronOptions(cronOptions);
    }, []);

    const onExecute = (key) => {
        setIsBusy(true);
        settingProvider.executeCron(key)
            .then(({ json }) => {
                notify(json.message, { type: 'success' });
            }).catch((e) => {
                notify(e.message, { type: 'error' });
            }).finally(() => {
                setTimeout(() => {
                    setIsBusy(false);
                }, 5000);
            });
    }

    return (
        <FormTab
          {...props}
          label="resources.settings.tabs.cron"
          icon={<UpdateIcon />}
        >
            <FormDataConsumer>
                {({ formData, ..._ }) => {
                    return <div>
                        <FieldLabel>{translate("resources.settings.fields.cron_retention_tile")}:</FieldLabel>
                        <CronForm
                            keySetting="CRON_ROOM_RETENTION"
                            cronOptions={cronOptions}
                            fieldTile="resources.settings.fields.cron_room_retention"
                            onValidateCronForm={props.onChange}
                            isBusy={isBusy}
                            onExecute={onExecute}
                        />
                        <CronForm
                          keySetting="PURGE_ROOM_AT"
                          cronOptions={cronOptions}
                          fieldTile="resources.settings.fields.cron_purge_room"
                          onValidateCronForm={props.onChange}
                          isBusy={isBusy}
                          onExecute={onExecute}
                        />
                        <TextInput
                          style={{
                              margin: '30px 20px',
                              width: '70%',
                          }}
                          source="PURGE_ROOM_TASK_PREV_TS"
                          label="resources.settings.fields.purge_room_task_prev_ts"
                          variant="outlined"
                          fullWidth
                          defaultValue={24}
                          disabled={isBusy}
                          validate={required()}
                          helperText={translate("resources.settings.fields.purge_room_task_prev_ts_desc")}
                        />
                        <FieldLabel>{translate("resources.settings.fields.cron_bot_tile")}:</FieldLabel>
                        <CronForm
                            keySetting="CRON_BOT_FORM_RETENTION"
                            cronOptions={cronOptions}
                            fieldTile="resources.settings.fields.cron_bot_form_retention"
                            onValidateCronForm={props.onChange}
                            isBusy={isBusy}
                            onExecute={onExecute}
                        />
                    </div>
                }}
            </FormDataConsumer>
        </FormTab>
    );
};
