import { env } from "../env";
import { jsonClient } from "./dataProvider";
// import { stringify } from "query-string";

const roomProvider = {
    base_url: env.REACT_APP_AUTH_SERVER,
        async updateRoomPermissions(roomId, dto) {
            const url = this.base_url + `/rooms/${roomId}/permission`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(dto)
            }
            return await jsonClient(url, options);
        },
        async getWhiteListIpFromRoom(roomId) {
            const url = this.base_url + `/admin/rooms/${roomId}/ip`;
            const options = {
                method: 'GET',
            }
            return await jsonClient(url, options).then(({ json }) => ({
                data: json['items'].map((item, index) => { return {
                    ...item,
                    id: index,
                    createdAt: item.createdAt instanceof Date ?
                        item.createdAt : new Date(item.createdAt).toLocaleString(),
                }}),
            }));
        },
        async createIpInWhiteList(data, roomId) {
            const url = this.base_url + `/admin/rooms/${roomId}/ip`;
            const options = {
              method: 'POST',
              body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        async updateIpInWhiteList(data, roomId, ip) {
            const url = this.base_url + `/admin/rooms/${roomId}/ip/${ip}`;
            const options = {
              method: 'PUT',
              body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        async deleteIpInWhiteList(roomId, ip) {
            const url = this.base_url + `/admin/rooms/${roomId}/ip/${ip}`;
            const options = {
              method: 'DELETE',
            }
            return await jsonClient(url, options);
        },

        /* Room settings */
        async getRoomSetting(roomId, key) {
            const url = this.base_url + `/admin/rooms/${roomId}/settings/${key}`;
            const options = {
                method: 'GET',
            }
            return await jsonClient(url, options).then(({ json }) => ({
                data: json,
            }));
        },
        async changeRoomSetting(roomId, key, data) {
            const url = this.base_url + `/admin/rooms/${roomId}/settings/${key}`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        /* Room reassign owner */
        async getRoomInvalidOwner(from, to) {
            const url = this.base_url + `/admin/rooms/list-invalid-owner?from=${from}&to=${to}`;
            const options = {
                method: 'GET',
            }
            return await jsonClient(url, options).then(({ json }) => ({
                data: json,
            }));
        },
        async getListMember(roomId, from, to, ignoreDeactiveAndBot = false) {
            const url = this.base_url +
              `/admin/rooms/${roomId}/list-members?from=${from}&to=${to}
                &ignoreDeactiveAndBot=${ignoreDeactiveAndBot}`;
            const options = {
                method: 'GET',
            }
            return await jsonClient(url, options).then(({ json }) => ({
                data: json,
            }));
        },
        // data: {roomId: string, userId: string | null}
        async assignOwnerRoom(data) {
            const url = this.base_url + `/admin/rooms/re-assign-owner`;
            const options = {
                method: 'POST',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        // data: {roomId: string, ownerId: string, targetUserId: string | null}
        async transferOwner(data) {
            const url = this.base_url + `/admin/rooms/transfer-owner`;
            const options = {
                method: 'POST',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        /* Room permission */
        async updatePermissions(roomId, data) {
            const url = this.base_url + `/admin/rooms/${roomId}/permissions`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        async updateUserRoleInRoom(roomId, data) {
            const url = this.base_url + `/admin/rooms/${roomId}/member-role`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        /* Room auto delete messages */
        async setAutoDeleteMessages(roomId, data) {
            const url = this.base_url + `/admin/rooms/${roomId}/retention`;
            const options = {
              method: 'PUT',
              body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        },
        async clearRoomStateName(roomId) {
            const url = this.base_url + `/admin/rooms/${roomId}/state/m.room.name/`;
            const options = {
                method: 'PUT',
                body: JSON.stringify({
                    name: '',
                }),
            };
            return jsonClient(url, options);
        },
        async setRoomEncrytion(roomId, data) {
            const url = this.base_url + `/admin/rooms/${roomId}/state/m.room.encryption/`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(data)
            }
            return await jsonClient(url, options);
        }
};

export default roomProvider;
