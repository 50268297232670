import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { useForm } from "react-final-form";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import { CronTime } from 'cron';

const useStyles = makeStyles({
    form: {
        marginLeft: '20px',
        width: '70%',
        display: 'flex'
    },
    nextTimes: {
        color: 'gray',
        fontSize: '0.8rem',
        fontStyle: 'italic',
        marginTop: '10px',
        marginBottom: '10px',
        fontFamily: 'monospace',
        marginLeft: '20px',
        lineHeight: '1rem',
    },
});

export const CronForm = ({keySetting, cronOptions, fieldTile, onValidateCronForm, isBusy, onExecute }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const form = useForm();
    const [cronSelect, setCronSelect] = useState("custom");
    const [cronValue, setCronValue] = useState("");
    const [nextTimes, setNextTimes] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);
    const [expand, setExpand] = useState(false);
    const [timeZone, setTimeZone] = useState("");

    useEffect(() => {
        const keyValue = form.getState().values[keySetting];
        if (cronOptions?.some((e) => e.value === keyValue)) {
            setCronSelect(keyValue);
        }
        setCronValue(keyValue);
        validateCronTime(keyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cronOptions]);

    useEffect(() => {
        onValidateCronForm(isInvalid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvalid]);

    const handleChangeOption = (event) => {
        let { name, value } = event.target;
        setCronSelect(value);
        if (value === 'custom') {
            setCronValue("");
            return;
        }
        setCronValue(value);
        setIsInvalid(false);
        validateCronTime(value);
        form.change(name, value);
    };

    const handleChangeInput = (event) => {
        let { name, value } = event.target;
        setCronValue(value);
        if (cronOptions?.some((e) => e.value === value)) {
            setCronSelect(value);
        } else {
            setCronSelect('custom');
        }
        validateCronTime(value);
        form.change(name, value);
    };

    const getNextTimes = (cronTime, times) => {
        let nextTime;
        let listTimes = [];
        for (let i = 1; i < times; i++) {
            if (i === 1) {
                nextTime = cronTime.getNextDateFrom(new Date());
                listTimes.push(nextTime);
            }
            nextTime = cronTime.getNextDateFrom(new Date(nextTime));
            listTimes.push(nextTime);
        }
        setNextTimes(listTimes);
    };

    const validateCronTime = (cronExpression) => {
        try {
            const cronTime = new CronTime(cronExpression);
            getNextTimes(cronTime, 5);
            // Validate second
            if (
                cronExpression &&
                !cronOptions?.some((e) => e.value === cronExpression) &&
                cronExpression.split(' ').length > 5
            ) {
                setIsInvalid(true);
                setNextTimes([]);
                return;
            }
            setIsInvalid(false);
            const timeZoneOffset = new Date().getTimezoneOffset() / -60;
            const sign = timeZoneOffset > 0 ? '+' : '-';
            setTimeZone(`GMT ${sign}${timeZoneOffset}`);
        } catch(e) {
            console.log(e);
            setIsInvalid(true);
            setNextTimes([]);
            setTimeZone('');
        }
    };

    const onExpand = () => {
        setExpand(!expand);
    };

    const getTimeFormat = (time) => {
        return new Date(time).toLocaleString('af');
    };

    return (<div>
        <div className={classes.form}>
            <TextField
                label={translate(fieldTile)}
                name={keySetting}
                value={cronValue}
                onChange={handleChangeInput}
                onClick={handleChangeInput}
                variant="outlined"
                size="small"
                sx={{minWidth: '60%'}}
                required
                error={isInvalid}
                helperText={isInvalid || !cronValue ? translate("resources.bots.validations.invalid_value") : ""}
            />
            <FormControl sx={{minWidth: '40%', marginLeft: '10px'}}>
                <Select
                    labelId="demo-simple-select-helper-label"
                    name={keySetting}
                    value={cronSelect}
                    size="small"
                    onChange={handleChangeOption}
                >
                    <MenuItem key="custom" value="custom">
                        Custom
                    </MenuItem>
                    {cronOptions?.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.value}
                        >
                            {option.key}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
        <Grid display="flex" direction="row" alignItems="flex-start" justifyContent="flex-start">
            <div className={classes.nextTimes}>
                {
                    !!nextTimes?.length && <div>
                        Next at {getTimeFormat(nextTimes[0])} {timeZone}
                    </div>
                }
                {
                    expand && nextTimes?.map((e, index) => {
                        return (
                            index !== 0 &&
                            <div>
                                Then at {getTimeFormat(e)} {timeZone}
                            </div>
                        )
                    })
                }
            </div>
            {
                !!nextTimes?.length && <Button sx={{ margin: '2px 0px 0px 10px' }} size="small" onClick={onExpand}> {expand ? "Collapse" : "Expand" }</Button>
            }
        </Grid>
        {
          expand && <div style={{ marginLeft: "20px"}}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={isBusy}
                onClick={() => onExecute(keySetting) }
              >{ translate("resources.settings.actions.execute") }</Button>
          </div>
        }
    </div>);
}
