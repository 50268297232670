import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider } from '@mui/material';
import muiTheme from "../../helper/muiTheme";

export const RolePermissions = ({permissions, role, ...props}) => {
    const translate = useTranslate();
    const [userPermissions, setUserPermissions] = useState([]);
    const [roomPermissions, setRoomPermissions] = useState([]);
    const [seletedUserPermissions, setSeletedUserPermissions] = useState([]);
    const [seletedRoomPermissions, setSeletedRoomPermissions] = useState([]);

    useEffect(() => {
        if (permissions.length > 0) {
            const users = permissions.map((item) => {
                if (item?.code.startsWith("users_")) {
                    return item;
                }
                return null;
            }).filter((item) => item !== null);
            setUserPermissions(users);

            const rooms = permissions.map((item) => {
                if (item?.code.startsWith("rooms_")) {
                    return item;
                }
                return null;
            }).filter((item) => item !== null);
            setRoomPermissions(rooms);
        }
        if (role.roleId && role?.permissions.length > 0) { // UPDATE
            const users = role.permissions.map((item) => {
                if (item.startsWith("users_")) {
                    return item;
                }
                return null;
            }).filter((item) => item !== null);
            setSeletedUserPermissions(users);

            const room = role.permissions.map((item) => {
                if (item?.startsWith("rooms_")) {
                    return item;
                }
                return null;
            }).filter((item) => item !== null);
            setSeletedRoomPermissions(room);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]);

    useEffect(() => {
        props.onPermissionsChange(
            [
                ...seletedUserPermissions, 
                ...seletedRoomPermissions
            ]
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seletedUserPermissions, seletedRoomPermissions]);

    const handleChangeGroup = (isChecked, isUser) => {
        if (isUser) {
            if (isChecked) {
                return setSeletedUserPermissions(
                    userPermissions.map((item) => item.code)
                );
            } else {
                setSeletedUserPermissions([]);
            };
        } else {
            if (isChecked) {
                return setSeletedRoomPermissions(
                    roomPermissions.map((item) => item.code)
                );
            } else {
                setSeletedRoomPermissions([]);
            };
        }
    };
    
    const handleChangePermissions = (isChecked, code, isUser) => {
        if (isUser) {
            const index = seletedUserPermissions.indexOf(code);
    
            if (isChecked) return setSeletedUserPermissions((state) => [...state, code]);
        
            if (!isChecked && index > -1)
              return setSeletedUserPermissions((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
        } else {
            const index = seletedRoomPermissions.indexOf(code);
    
            if (isChecked) return setSeletedRoomPermissions((state) => [...state, code]);
        
            if (!isChecked && index > -1)
              return setSeletedRoomPermissions((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
        }
    };
  
    const userpermissions = (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {
            seletedUserPermissions && userPermissions.map((userPermission) => (
                <FormControlLabel
                    label={userPermission.name}
                    control={
                        <Checkbox 
                            key={userPermission.code}
                            checked={seletedUserPermissions.includes(userPermission.code)}
                            onChange={(event) =>
                                handleChangePermissions(event.target.checked, userPermission.code, true)
                            } 
                        />
                    }
                />
            ))
        }
      </Box>
    );

    const roompermissions = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {
              seletedRoomPermissions && roomPermissions.map((roomPermission) => (
                  <FormControlLabel
                      label={roomPermission.name}
                      control={
                          <Checkbox 
                              key={roomPermission.code}
                              checked={seletedRoomPermissions.includes(roomPermission.code)}
                              onChange={(event) =>
                                  handleChangePermissions(event.target.checked, roomPermission.code, false)
                              } 
                          />
                      }
                  />
              ))
          }
        </Box>
    );
    
    return (
        permissions.length > 0 && 
        <ThemeProvider theme={muiTheme}>
            <Box display='flex' flexDirection='row' sx={{ marginLeft: "30px" }} >
                <Box sx={{ paddingRight: "70px" }}>
                    <FormControlLabel
                    label={translate("resources.roles.fields.userPermissions")}
                    control={
                        <Checkbox
                            checked={seletedUserPermissions.length === userPermissions.length}
                            indeterminate={
                                seletedUserPermissions.length !== userPermissions.length &&
                                seletedUserPermissions.length > 0
                            }
                            onChange={(event) => handleChangeGroup(event.target.checked, true)}
                        />
                    }
                    />
                    {userpermissions}
                </Box>
                <Box>
                    <FormControlLabel
                    label={translate("resources.roles.fields.roomPermissions")}
                    control={
                        <Checkbox
                            checked={seletedRoomPermissions.length === roomPermissions.length}
                            indeterminate={
                                seletedRoomPermissions.length !== roomPermissions.length &&
                                seletedRoomPermissions.length > 0
                            }
                            onChange={(event) => handleChangeGroup(event.target.checked, false)}
                        />
                    }
                    />
                    {roompermissions}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
