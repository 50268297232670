import React, { useEffect, useState } from "react";
import { FormTab, useNotify, useTranslate } from "react-admin";
import SecurityIcon from "@material-ui/icons/Security";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import SettingsIcon from "@material-ui/icons/Settings";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button as MUIButton, ThemeProvider } from '@mui/material';
import Typography from '@mui/material/Typography';

import SetupTwoFactor from "../login-flow/SetupTwoFactor";
import userProvider from "../../synapse/userProvider";
import muiTheme from "../../helper/muiTheme";

export const ProfileSecurity = (props) => {
  const [loading, setLoading] = useState(false);
  const [isOpenSetup2Fa, setOpenSetup2FA] = useState(false);
  const [enabledTwoFactor, setEnabledTwoFactor] = useState(null);
  const translate = useTranslate();
  const notify = useNotify();

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      loadData();
    }
  }, []);

  function loadData() {
    setLoading(true);
    userProvider
        .getSelfTwoFactorStatus()
        .then((data) => {
          setEnabledTwoFactor(data.enabledTwoFactor);
        })
        .catch(() => {
          notify("resources.profile.security.cannot_get_status");
        })
        .finally(() => {
          setLoading(false);
        });
  }
  const onOpenDialogEnable2FA = () => {
    setOpenSetup2FA(true);
    setLoading(true);
  }

  const onCloseSetup = () => {
    setOpenSetup2FA(false);
    setLoading(false);
  }

  const handleSubmit = (res) => {
    setLoading(false);
    setOpenSetup2FA(false);
    if (res.result) {
      setEnabledTwoFactor({
        twoFactor: true
      });
    }
  }

  return (
    <FormTab
      {...props}
      label="resources.profile.tabs.security"
      icon={<SecurityIcon/>}
    >
      {
        loading &&
        <div style={{ margin: '75px auto 100px', display: 'flex', justifyContent: 'center' }} >
          <CircularProgress size={50} thickness={2} />
        </div>
      }
      {
        !loading &&
        <Box sx={{
          padding: "1em 0 1em 0",
          width: '100%'
        }}>
          <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={8} md={7} lg={4}>
              <Typography variant="h6" component="div">
                {translate("resources.profile.security.two_factor_title")}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" >
                {translate("resources.profile.security.two_factor_title_description")}
              </Typography>
            </Grid>
            <Grid item xs={4} md={5} lg={8} style={{ textAlign: 'left' }}>
              {
                enabledTwoFactor ?
                  <MUIButton color="success">
                    {translate("two_factor.enabled")}
                  </MUIButton>:
                  <ThemeProvider theme={muiTheme}>
                    <MUIButton
                      variant="contained"
                      startIcon={<SettingsIcon/>}
                      onClick={onOpenDialogEnable2FA}
                    >
                      {translate("resources.profile.security.setup_two_factor")}
                    </MUIButton>
                  </ThemeProvider>
                }
            </Grid>
          </Grid>
        </Box>
      }
      <div>
        <Dialog onClose={onCloseSetup} aria-labelledby="setup-dialog-title" open={isOpenSetup2Fa}>
          <DialogContent>
            <DialogContentText id="setup-dialog">
              <SetupTwoFactor
                isLoginFlow={false}
                handleSubmit={handleSubmit}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </FormTab>
  );
};

ProfileSecurity.propTypes = {};


