import { env } from "../env";
import { jsonClient } from "./dataProvider"

const isArrayInputDomains = (key) => (
  ['ALLOW_CLOUDKIT_DOMAINS'].includes(key)
);

const settingProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async getSettings() {
    const url = this.base_url + '/settings/system';
    const options = {
      method: 'GET',
    }
    const { json: { data } } = await jsonClient(url, options);
    const settings = {};
    data.forEach(({ key, value }) => {
      settings[key] = value;
      if (key.startsWith('LOGIN_MANUAL')) {
        settings['_loginManual'] = !!value.length;
      } else if (isArrayInputDomains(key)) {
        settings[key] = value.map(d => ({ domain: d }));
      }
    });
    return settings;
  },
  saveSettings({ data }) {
    const url = this.base_url + '/settings/system';
    const configs = [];
    Object.keys(data).forEach(key => {
      if (key.startsWith('_')) {
        if (key === '_loginManual' && !data[key]) {
          data['LOGIN_MANUAL'] = [];
          configs.push({
            key: 'LOGIN_MANUAL',
            value: [],
          })
        }
        return;
      }
      if (isArrayInputDomains(key)) {
        configs.push({
          key: key,
          value: data[key].map(input => input.domain),
        });
        return;
      }
      configs.push({
        key: key,
        value: data[key],
      })
    })
    const options = {
      method: 'PUT',
      body: JSON.stringify(configs)
    }
    return jsonClient(url, options);
  },
  executeCron(key) {
    const url = this.base_url + '/settings/system/cron/execute';
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        key: key,
      }),
    }
    return jsonClient(url, options);
  },
  getIPList() {
    const url = this.base_url + `/settings/system/ip-list`;
    const options = {
      method: 'GET',
    };
    return jsonClient(url, options).then(({ json }) => ({
      data: json?.map((item, index) => { return {
          ...item,
          id: index,
          createdAt: item.createdAt instanceof Date ?
              item.createdAt : new Date(item.createdAt).toLocaleString(),
      }}),
    }));
  },
  addIPAddress(data) {
    const url = this.base_url + `/settings/system/ip-list`;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        ip: data.ip,
        type: data.type,
        feature: data.feature,
        note: data.note,
      })
    }
    return jsonClient(url, options);
  },
  updateIPAddress(data) {
    const url = this.base_url + `/settings/system/ip-list`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ip: data.ip,
        type: data.type,
        feature: data.feature,
        note: data.note,
      })
    }
    return jsonClient(url, options);
  },
  deleteIPFromList(ip, feature) {
    const url = this.base_url + `/settings/system/ip-list/remove`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ip,
        feature: feature
      })
    }
    return jsonClient(url, options);
  }
};

export default settingProvider;
