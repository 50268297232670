import React, { useState } from "react";
import {
  Notification, useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import adminProvider from "../../synapse/adminProvider";
import UserInfoLogin from "./UserInfoLogin";

const VerifyTwoFactor = (props) => {
  const reg = /^\d+$/;
  const classes = useStyles({ theme: props.theme });

  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState('');
  const translate = useTranslate();

  const validate = () => {
    return {};
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const dto = {
        step: props.step,
        value: otp,
        sessionTemp: props.dataFlow?.sessionTemp,
      };
      const res = await adminProvider.loginStep(dto);
      props.handleSubmit(res?.json);
    } catch (err) {
      let errorMessage;
      const errcode = err.body?.errcode || '';
      switch (errcode) {
        case 'M_TWO_FACTOR_IS_INVALID':
          errorMessage = 'two_factor.code_invalid_verify';
          break;
        case 'M_NOT_ENABLE_TWO_FACTOR':
          errorMessage = 'two_factor.enable_two_factor';
          break;
        case 'M_SESSION_NOT_EXISTS_OR_EXPIRED':
          props.onSessionExpired();
          break;
        default:
          errorMessage = 'two_factor.cannot_verify';
          break;
      }
      notify(errorMessage, {
        type: "error",
      });
      setOTP('');
    }
    finally {
      setLoading(false);
    }
  };

  const updateDataOTP = async (e) => {
    const value = e.target.value;
    if (value.length > 6 || (!reg.test(value) && value.length !== 0)) {
      return;
    }
    setOTP(value);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.form}>
                <h2>{translate("two_factor.title_verify")}</h2>
                <UserInfoLogin
                  userInfo={props.userInfo}
                  onBackToLogin={props.onBackToLogin}
                />
                <div>
                  <OutlinedInput
                    className={classes.inputOtp}
                    id="component-outlined"
                    autoFocus
                    value={otp}
                    onChange={updateDataOTP}
                    placeholder={translate("two_factor.enter_code")}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Verify
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

VerifyTwoFactor.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSessionExpired: PropTypes.func,
  step: PropTypes.string,
  dataFlow: PropTypes.object,
  userInfo: PropTypes.object,
  onBackToLogin: PropTypes.func,
};

export default VerifyTwoFactor;

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: '25%',
  },
  form: {
    padding: "2em 1em 1em 1em",
    textAlign: 'center'
  },
  inputOtp: {
    width: '100%',
    minHeight: '2em',
    marginTop: '2em'
  },
  copyClipboardBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  copyClipboard: {
    cursor: 'context-menu',
    paddingLeft: '1em',
    paddingTop: '1em'
  },
  errorText: {
    paddingTop: '1em',
    color:'red',
  }
}));
