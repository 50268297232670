import { fetchUtils } from "react-admin";
import { EErrorCode } from '../enum/EErrorCode';
import { HttpStatus } from '../enum/HttpStatus';
import { env } from "../env";
import { getMetaData, delMetaData } from "../utils/storage";
import { jsonClient } from "./dataProvider";

const onCreateScalarToken = (token, error) => {
  // if (token) {
  //   window.location.reload();
  //   return;
  // }
}
const authProvider = {
  // called when the user attempts to log in
  login: ({ base_url, loginToken, type, createScalaToken }) => {
    // force homeserver for protection in case the form is manipulated
    base_url = env.REACT_APP_SERVER || base_url;

    const options = {
      method: "POST",
      body: JSON.stringify(
        Object.assign(
          {
            device_id: localStorage.getItem("device_id"),
            initial_device_display_name: "Synapse Admin",
          },{
              type: type || "m.login.token",
              token: loginToken,
            }
        )
      ),
    };

    // use the base_url from login instead of the well_known entry from the
    // server, since the admin might want to access the admin API via some
    // private address
    base_url = base_url.replace(/\/+$/g, "");
    localStorage.setItem("base_url", base_url);

    const decoded_base_url = window.decodeURIComponent(base_url);
    const login_api_url = decoded_base_url + "/_matrix/client/r0/login";

    return fetchUtils.fetchJson(login_api_url, options).then(({ json }) => {
      localStorage.setItem("home_server", json.home_server);
      localStorage.setItem("user_id", json.user_id);
      localStorage.setItem("access_token", json.access_token);
      localStorage.setItem("device_id", json.device_id);
      if (createScalaToken) {
        authProvider.createScalarToken(onCreateScalarToken);
      }
      if (!getMetaData('requiredChangePassword')) {
        window.location = "/";
      }
    });
  },
  createScalarToken: (cal) => {
    const dimensionUrl = env.REACT_APP_MATRIX_DIMENSION_SERVER;

    const getMatrixOpenIdData = (cal) => {
      const userId = localStorage.getItem("user_id");
      const homeServerBaseUrl = localStorage.getItem("base_url");
      jsonClient(`${homeServerBaseUrl}/_matrix/client/r0/user/${userId}/openid/request_token`, {
        method: "POST",
        body: JSON.stringify({}),
      }).then(({ json }) => {
        cal && cal(json);
      }).catch(e => {
        cal && cal(null, e);
      });
    };

    const getScalarToken = (cal) => {
      getMatrixOpenIdData((openIdData) => {
        fetchUtils.fetchJson(`${dimensionUrl}/api/v1/scalar/register?v=1.1`, {
          method: "POST",
          body: JSON.stringify({
            "state": "allowed",
            "token_type": "Bearer",
            ...openIdData
          }),
        }).then(({ json }) => {
          cal(json.scalar_token);
        }).catch(e => {
          cal(null, e)
        });
      });
    };

    getScalarToken((scalarToken, error) => {
      localStorage.setItem("scalar_token", scalarToken);
      cal(scalarToken, error);
      setTimeout(() => {
        // 30 minutes refresh token
        authProvider.createScalarToken(onCreateScalarToken);
      }, 30 * 60 * 1000);
    });
  },
  connectDimensionServer: (scalarToken, cal) => {
    const dimensionUrl = env.REACT_APP_MATRIX_DIMENSION_SERVER;
    fetchUtils.fetchJson(`${dimensionUrl}/api/v1/dimension/admin/stickers/packs?scalar_token=${scalarToken}`, {
      method: "GET",
    }).then(({ json }) => {
      cal(true);
    }).catch((e) => {
      //retry to connect with new scalar token
      cal(null, e);
    });
  },
  // called when the user clicks on the logout button
  logout: () => {
    const logout_api_url =
      localStorage.getItem("base_url") + "/_matrix/client/r0/logout";
    const access_token = localStorage.getItem("access_token");

    const options = {
      method: "POST",
      user: {
        authenticated: true,
        token: `Bearer ${access_token}`,
      },
    };

    if (typeof access_token === "string") {
      fetchUtils.fetchJson(logout_api_url, options).then();
      localStorage.removeItem("access_token");
      delMetaData("requiredChangePassword");
    }
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === HttpStatus.FORBIDDEN) {
      return Promise.reject({ redirectTo: '/not_found', logoutUser: false, message: false });
    }
    if (status === HttpStatus.UNAUTHORIZED) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const access_token = localStorage.getItem("access_token");
    const requiredChangePassword = getMetaData('requiredChangePassword');
    return typeof access_token === "string" && !requiredChangePassword
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login', message: false });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
