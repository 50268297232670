import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AdminUI, Resource, Loading } from "react-admin";
import { useDispatch, connect } from "react-redux";
import { MatrixDimensionIframe } from "./components/MatrixStickers";
import { UserCreate, UserEdit } from "./components/users";
import { RoomList, RoomShow } from "./components/rooms";
import { ReportList, ReportShow } from "./components/EventReports";
import UserIcon from "@material-ui/icons/Group";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import DevicesIcon from "@material-ui/icons/Devices";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { UserMediaStatsList } from "./components/statistics";
import { UserDeviceStatsList } from "./components/UserDevice";
import { ImportFeature } from "./components/ImportFeature";
import { Settings } from "./components/Settings";
import { MyLayout } from "./components/Layout";
import { MyProfile } from "./components/MyProfile";
import { Route } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { RoleList } from "./components/role-management/RoleList";
import { BotList } from "./components/bot-management/BotList";
import { BotCreate } from "./components/bot-management/BotCreate";
import { BotEdit } from "./components/bot-management/BotEdit";
import { RoleDetails } from "./components/role-management/RoleDetails";
import PersonIcon from "@material-ui/icons/Person";
import * as permissionsSaga from "./sagas/permissionsSaga";
import LoginFlow from "./components/LoginFlow";
import { EPermission } from "./enum/EPermission";
import { hasPermission } from "./helper/permission";
import UserList from "./components/users/user-list";
import { NotFound } from "./layout/NotFound";
import BotIcon from "./customIcons/BotIcon"
import { BotQueueInfo } from "./components/bot-management/queue/BotQueueInfo";

const customRouters = (permissions) => {
  const routers = [
    <Route
      key="profile"
      path="/profile"
      component={MyProfile}
    />,
    <Route
      key="notFound"
      path="/not_found"
      component={NotFound}
    />
  ];

  if (hasPermission([EPermission.USERS_IMPORT], permissions)) {
    routers.push(
      <Route
        key="userImport"
        path="/import_users"
        component={ImportFeature}
      />
    );
  }
  if (hasPermission([EPermission.SUPER_ADMIN], permissions)) {
    routers.push(
      <Route
        key="botQueue"
        path="/bot-queue"
        component={BotQueueInfo}
      />,
    )
  }
  return routers;
}

const customResources = (permissions) => {
  const resources = [];

  if (hasPermission([EPermission.USERS_SEARCH_ALL], permissions)) {
    resources.push(
      <Resource
        key="users"
        name="users"
        list={UserList}
        create={
          hasPermission(
            [
              EPermission.USERS_CREATE,
            ],
            permissions,
          ) && UserCreate
        }
        edit={
          hasPermission(
            [
              EPermission.USERS_DETAILS
            ],
            permissions
          ) && UserEdit
        }
        icon={UserIcon}
      />
    );
  }

  if (hasPermission([EPermission.ROOM_SEARCH], permissions)) {
    resources.push(
      <Resource
        key="rooms"
        name="rooms"
        list={RoomList}
        show={
          hasPermission(
            [
              EPermission.ROOM_DETAILS
            ],
            permissions
          ) && RoomShow
        }
        icon={MeetingRoomIcon}
      />
    );
  }

  if (hasPermission([EPermission.SUPER_ADMIN], permissions)) {
    resources.push(
      [
        <Resource
          key="user_device_statistics"
          name="user_device_statistics"
          list={UserDeviceStatsList}
          icon={DevicesIcon}
        />,
        <Resource
          key="user_media_statistics"
          name="user_media_statistics"
          list={UserMediaStatsList}
          icon={PermMediaIcon}
        />,
        <Resource
          key="reports"
          name="reports"
          list={ReportList}
          show={ReportShow}
          icon={AssessmentIcon}
        />,
        <Resource
          key="matrix_stickers"
          name="stickers"
          list={MatrixDimensionIframe}
          icon={EmojiEmotionsIcon}
          options={{ label: "resources.matrix_stickers.name" }}
        />,
        <Resource
          key="matrix_bot"
          name="bots"
          list={BotList}
          create={BotCreate}
          edit={BotEdit}
          icon={BotIcon}
          options={{ label: "resources.bots.name" }}
        />,
        <Resource
          key="roles"
          name="roles"
          list={RoleList}
          create={RoleDetails}
          edit={RoleDetails}
          icon={AssignmentIndIcon}
          options={{ label: "resources.roles.name" }}
        />,
        <Resource
          key="settings"
          name="settings"
          list={Settings}
          icon={SettingsIcon}
        />
      ]
    )
  }

  resources.push(
    [
      <Resource name="connections" />,
      <Resource name="devices" />,
      <Resource name="room_members" />,
      <Resource name="users_media" />,
      <Resource name="joined_rooms" />,
      <Resource name="pushers" />,
      <Resource name="servernotices" />,
      <Resource name="forward_extremities" />,
      <Resource name="room_state" />,
    ]
  );

  return resources;
}

function AsyncResources(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: permissionsSaga.FETCH_PERMISSIONS_SAGA });
  }, [dispatch]);

  return !props.permissions.loading ? (
    props.permissions.permissions.length === 0 ? (
      <AdminUI
        disableTelemetry
        loginPage={LoginFlow}
        layout={MyLayout}
        ready={Loading}
        catchAll={NotFound}
        customRoutes={[
          <Route
            key="notFound"
            path="/not_found"
            component={NotFound}
          />
        ]}
      >
        <Resource name="profile" list={MyProfile} icon={PersonIcon} />
      </AdminUI>
    ) : (
      <AdminUI
        disableTelemetry
        loginPage={LoginFlow}
        layout={MyLayout}
        ready={Loading}
        catchAll={NotFound}
        customRoutes={customRouters(props.permissions.permissions)}
      >
        {customResources(props.permissions.permissions)}
      </AdminUI>
    )
  ) : (
    <AdminUI
      disableTelemetry
      loginPage={LoginFlow}
      layout={MyLayout}
      ready={Loading}
    ></AdminUI>
  );
}

const mapStateToProps = function (state) {
  return {
    permissions: state.permissions,
  };
};

AsyncResources.propTypes = {
  permissions: PropTypes.shape({
    loading: PropTypes.bool,
    permissions: PropTypes.array,
    error: PropTypes.object,
  }),
};

export default connect(mapStateToProps)(AsyncResources);
