import CryptoJS from "crypto-js";
import { sortKeys } from "./utils";

export function mapStateToProps(state) {
    return {
      botContainers: state.botContainers,
    };
}

const md5 = (data) => {
  return CryptoJS.MD5(data).toString();
};

const hmacSha256 = (dataStr, secret) => {
  return CryptoJS.HmacSHA256(dataStr, secret).toString(CryptoJS.enc.Hex);
};

export function signData(data, secret) {
  const strData = JSON.stringify(data);
  return hmacSha256(strData, secret);
}

export const createSignature = (dataInput, secret, fileBuffers) => {
  const data = { ...dataInput }; // clone data to new object
  if (typeof dataInput.form !== 'string') {
    data.form = JSON.stringify(dataInput.form);
  }
  if (dataInput.metadata && typeof dataInput.metadata !== 'string') {
    data.metadata = JSON.stringify(dataInput.metadata);
  }
  if (dataInput.file?.length && fileBuffers?.length) {
      data.file = md5(fileBuffers[0]);
  }
  const ordered = sortKeys(data);
  return signData(ordered, secret);
};
