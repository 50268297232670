import React, {useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import BlockIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/Error';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const TEMPLATE_TITLE_NAME = {
  RETRIEVING: "retrieving",
  RETRIEVED: "retrieved",
  RETRIEVE_ERROR: "retrieve_error",
  ASSIGNING: "assigning",
  ASSIGNING_DONE: "assigning_done",
  ASSIGNING_CANCELED: "assigning_canceled",
};

export const TEMPLATE_ACTION_NAME = {
  ASSIGNING: "assigning",
  ASSIGNING_CANCEL: "assigning_cancel",
  RETRIEVE_ERROR: "retrieve_error",
};

export const RoomOwnerModalTitleTemplate = ({templateName, translate, ...props}) => {
  switch (templateName) {
    case TEMPLATE_TITLE_NAME.RETRIEVING:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>{translate("assigning.retrieving_room_data")}</h4>
          <Box>{translate("assigning.wait_moment")}</Box>
        </Box>
      );
    case TEMPLATE_TITLE_NAME.RETRIEVED:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>{translate("assigning.retrieving_room_data")}</h4>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon
              color="secondary"
            />
            <Box
              sx={{
                paddingLeft: 1
              }}
            >
              {translate("assigning.completed")}
            </Box>
          </Box>
        </Box>
      );
    case TEMPLATE_TITLE_NAME.RETRIEVE_ERROR:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>{translate("assigning.retrieve_data_error")}</h4>
          <p>
            <ErrorIcon
              fontSize="large"
              htmlColor="red"
            />
          </p>
        </Box>
      );
      break;
    case TEMPLATE_TITLE_NAME.ASSIGNING:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>
            {translate("assigning.assign_progress")}
          </h4>
          <p>
            {translate("assigning.keep_browser")}
          </p>
        </Box>
      );
    case TEMPLATE_TITLE_NAME.ASSIGNING_DONE:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>
            {translate("assigning.assign_complete")}
          </h4>
          <p>
            <CheckCircleIcon
              color="secondary"
              fontSize="large"
            />
          </p>
        </Box>
      );
    case TEMPLATE_TITLE_NAME.ASSIGNING_CANCELED:
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <h4>
            {translate("assigning.assign_canceled")}
          </h4>
          <p>
            <BlockIcon
              fontSize="large"
              htmlColor="orange"
            />
          </p>
        </Box>
      );
    default:
      return <></>;
  }
};

export const RoomOwnerModalActionTemplate = ({templateName, translate, ...props}) => {
  const {options = {}} = props;
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copied');
  const onCopy = (value) => {
    setCopyText(value ? translate("resources.users.action.copied") : translate("resources.users.action.empty"));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000)
  };

  switch (templateName) {
    case TEMPLATE_ACTION_NAME.ASSIGNING:

      return (
        <Box>
          <Box
            sx={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "green",
                marginRight: 1
              }}
            >
              {translate("assigning.success")}:&nbsp;{options.success}/{options.total}
            </Box>
             {options.error?.length > 0 && <>&minus;</>}
            <Box
              sx={{
                color: "red",
                cursor: "pointer",
                marginLeft: 1
              }}
            >
              {
                options.error?.length > 0 && <CopyToClipboard text={JSON.stringify(options.error)}>
                   <Box
                     onClick={event => onCopy(event)}
                     onKeyDown={() => {}}
                   >
                      <Tooltip title={copyText} open={isCopied} placement="top">
                        <Box>
                          {translate("assigning.error")}:&nbsp;{options.error.length}&nbsp;<FileCopyIcon style={{fontSize: "14"}}/>
                        </Box>
                      </Tooltip>
                    </Box>
                </CopyToClipboard>
              }
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "right",
            }}
          >
            <Button
              color="primary"
              startIcon={<CheckCircleIcon />}
              onClick={options.onDoneAssigning}
            >
              {translate("assigning.done")}
            </Button>
          </Box>
        </Box>
      );
    case TEMPLATE_ACTION_NAME.RETRIEVE_ERROR:
      return (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              variant="outlined"
              startIcon={<ReplayIcon />}
              onClick={options.onRetry}
              sx={{
                marginRight: 1,
              }}
            >
              {translate("assigning.retry")}
            </Button>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={options.onClose}
            >
              {translate("assigning.close")}
            </Button>
          </Box>
        </Box>
      );
    case TEMPLATE_ACTION_NAME.ASSIGNING_CANCEL:
      return (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              disabled={options.loading}
              onClick={options.onCancel}
            >
              {translate("assigning.cancel")}
            </Button>
          </Box>
        </Box>
      );
      break;
    default:
      return <></>;
  }
}
