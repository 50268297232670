import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    FormTab,
    TextInput,
    useNotify
} from 'react-admin';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EUserThreePID } from '../../enum/EThreePID';
import { mxcUrlToHttp } from '../../synapse/dataProvider';
import userProvider from '../../synapse/userProvider';
import { logger } from '../../utils/logger';
import { AvatarField, useStyles } from '../users';

export const Profile = (props) => {
    const classes = useStyles();
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [loading3PID, setLoading3PID] = useState(false);
    const [loadingUsername, setLoadingUsername] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [userThreePID, setUserThreePID] = useState({});
    const [username, setUsername] = useState('');
    const notify = useNotify();

    useEffect(() => {
        const access_token = localStorage.getItem("access_token");
        if (access_token) {
            loadUserInfo()
            loadUsername();
            loadThreePID();
        }
    }, []);

    function loadUserInfo() {
        setLoadingProfile(true);
        userProvider
            .getSelfProfile()
            .then((data) => {
                const user = {...data};
                user.avatar_src = mxcUrlToHttp(user.avatar_url);
                setUserInfo(user);
            })
            .catch(() => {
                notify('resources.profile.notifications.failed_to_fetch');
            })
            .finally(() => {
                setLoadingProfile(false);
            });
    }

    function loadUsername() {
        setLoadingUsername(true);
        userProvider
            .getSelfUsername()
            .then((data) => {
                setUsername(data.username);
            })
            .catch(() => {
                notify('resources.profile.notifications.failed_to_fetch');
            })
            .finally(() => {
                setLoadingUsername(false);
            });
    }

    function loadThreePID() {
        setLoading3PID(true);
        userProvider
            .getSelfThreePId()
            .then((data) => {
                const threepids = data.threepids;
                const email = threepids.find((i) => i.medium === EUserThreePID.email);
                const phone = threepids.find((i) => i.medium === EUserThreePID.phone);
                setUserThreePID({
                    email: email?.address,
                    phone: phone?.address,
                });
            })
            .catch((e) => {
                logger.error(e);
                notify('resources.profile.notifications.failed_to_fetch');
            })
            .finally(() => {
                setLoading3PID(false);
            });

    }

    const isLoading = loadingProfile || loading3PID || loadingUsername;
    return (
        <FormTab
            {...props}
            label="resources.profile.tabs.profile"
            icon={<AccountCircle />}
        >{
            (isLoading) ?
                <div style={{ margin: '75px auto 100px', display: 'flex', justifyContent: 'center' }} >
                    <CircularProgress size={50} thickness={2} />
                </div>
                : // loaded
                <Grid container width="100%" spacing={2}>
                    <Grid item xs={8} md={6} lg={4}>
                        <TextInput
                            source="userId"
                            label="resources.users.fields.id"
                            variant="outlined"
                            initialValue={userInfo.userId}
                            disabled={true}
                            fullWidth
                        />
                        <TextInput
                            source="displayname"
                            label="resources.users.fields.displayname"
                            variant="outlined"
                            initialValue={userInfo.displayname}
                            disabled={true}
                            loading={loadingProfile}
                            fullWidth
                        />
                        <TextInput
                            source="field_username"
                            label="resources.users.fields.username"
                            autoComplete="none"
                            variant="outlined"
                            initialValue={username}
                            disabled={true}
                            fullWidth
                        />
                        <TextInput
                            source="email"
                            autoComplete="off"
                            variant="outlined"
                            initialValue={userThreePID.email}
                            disabled={true}
                            fullWidth
                        />
                        <TextInput
                            source="phone_number"
                            autoComplete="none"
                            label="resources.users.fields.phoneNumber"
                            helperText={false}
                            variant="outlined"
                            initialValue={userThreePID.phone}
                            disabled={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} md={6} lg={8}>
                        <AvatarField
                            source="avatar_src"
                            className={classes.large}
                            record={userInfo}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
        }</FormTab>
    );
};

Profile.propTypes = {};


