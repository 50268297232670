import jsonExport from 'jsonexport/dist';
import { downloadCSV } from "react-admin";
import { EUserThreePID } from '../enum/EThreePID';
import dataProvider from "../synapse/dataProvider";
export const exporterUser = async (data, _, __, resource) => {
    let exportData = [];
    for (const i of data) {
        delete i.phonenumber;
        await dataProvider.getOne("users", {id: i.id}).then((user) => {
            if (user.data?.threepids?.length) {
                user.data.threepids.forEach((user3pids) => {
                    if (user3pids.medium === EUserThreePID.email) {
                        i.email = user3pids.address;
                    }
                    if (user3pids.medium === EUserThreePID.phone) {
                        i.phone = user3pids.address;
                    }
                    if (user3pids.medium === EUserThreePID.username) {
                        i.username = user3pids.address?.replace('@','');
                    }
                });
            }
        });
        if(i.email && i.displayname){
            exportData.push(i);
        }
    }
    return jsonExport(exportData, (err, csv) => downloadCSV(csv, resource))
}

export function shortenId(id) {
  return id && id.substr(
      1,
      id.indexOf(':') > 0 && !id.includes(' ') ?
          (id.indexOf(':') -1) :
          id.length,
  );
}

export function isBotUser(userId) {
    return userId && userId.startsWith('@bot') && userId.indexOf(':') > 0;
}

