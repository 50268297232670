import { env } from "../env";
import { jsonClient } from "./dataProvider"

const adminProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async loginStep(dto) {
    const url = this.base_url + '/auth/login/admin';
    const options = {
      method: 'Post',
      body: JSON.stringify(dto)
    }
    return await jsonClient(url, options);
  },
};

export default adminProvider;
