import { env } from "../env";
import { jsonClient } from "./dataProvider"
import { stringify } from "query-string";

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

const roleProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async getList(res, params) {
    const { name } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      size: perPage,
      page: page,
      name: name,
      order_by: field,
      dir: getSearchOrder(order),
    };
    const url = this.base_url + `/roles?${stringify(query)}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json['items'].map((item, index) => res.map(item, index, from)),
      total: res.total(json, from, perPage),
    }));
  },

  async getListRoles() {
    const query = {
      size: 100,
      page: 1,
      order_by: "name",
      dir: "f",
    };
    const url = this.base_url + `/roles?${stringify(query)}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => (json['items']));
  },

  async getRole(roleId) {
    const url = this.base_url + `/roles/${roleId}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json,
    }));
  },

  async getPermissions() {
    const url = this.base_url + `/roles/permissions`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json['items'],
    }));
  },

  async getUsers(roleId) {
    const query = {
      roleId: roleId,
    };
    const url = this.base_url + `/roles/users?${roleId ? stringify(query) : null}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json['items'].map(item => (item)),
    }));
  },

  async createRole(data) {
    const url = this.base_url + `/roles`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    }
    return await jsonClient(url, options);
  },

  async updateRole(data) {
    const url = this.base_url + `/roles/${data.roleId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify(data)
    }
    return await jsonClient(url, options);
  },

  async deleteRole(roleId) {
    const url = this.base_url + `/roles/${roleId}`;
    const options = {
      method: 'DELETE',
    }
    return await jsonClient(url, options);
  },

  async getMyRole() {
    // Call this func from [permissionsSaga] but we can not use variable [this.base_url] (undefined)
    // So we use [env.REACT_APP_AUTH_SERVER] instead
    const url = env.REACT_APP_AUTH_SERVER + `/roles/my-role`;
    const options = {
      method: 'GET',
    };
    return await jsonClient(url, options).then(({ json }) => (json));
  },
};

export default roleProvider;