import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { StyledTextField } from './FormController';
import {
    MAX_LENGTH_64_REGEX,
    MAX_LENGTH_255_REGEX,
    SPACES_REGEX,
} from "../BotTestForm";
import { checkValidation } from "./FormController";
import { randomString } from "../../../../helper/utils";

const useStyles = makeStyles({
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        marginTop: '10px',
        alignItems: 'flex-start'
    },
});

const initValidations = {
    value_messageErr: '',
    label_messageErr: '',
}

const handleMessageErr = (value, field) => {
    if (!value) {
        return `resources.bots.validations.required`;
    }
    if ((SPACES_REGEX.test(value))) {
        return `resources.bots.validations.invalid_value`;
    }
    if (!(MAX_LENGTH_255_REGEX.test(value)) && field === 'label') {
        return `resources.bots.validations.invalid_length_description`;
    }
    if (!(MAX_LENGTH_64_REGEX.test(value)) && field === 'value') {
        return `resources.bots.validations.invalid_length_name`;
    }
    return '';
}

const RadioItem = ({item, index, itemId,...props}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [id, setId] = useState('');
    const [validations, setValidations] = useState(initValidations);

    useEffect(() => {
        setId(itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id !== itemId) {
            setId(itemId);
            setValidations({
                ...validations,
                value_messageErr: handleMessageErr(item.value, 'value'),
                label_messageErr: handleMessageErr(item.label, 'label'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId, id]);

    const handleOptionChange = (e) => {
        const { name, value } = e.target;

        const inputValidation = {
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        };
        const isInValid = checkValidation(inputValidation);

        props.onRadioChangeOption({
            ...item,
            [name]: value,
        }, index, isInValid);

        setValidations({
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        });
    };

    

    return (
        <div className={classes.item}>
            <StyledTextField
                id="value"
                label={translate(`resources.bots.fields.testform.radio_value`)}
                name="value"
                value={item.value}
                onChange={handleOptionChange}
                onClick={handleOptionChange}
                variant="outlined"
                size="small"
                fullWidth
                required
                error={validations.value_messageErr}
                helperText={validations?.value_messageErr ? translate(validations?.value_messageErr) : ""}
            />
            <StyledTextField
                id="label"
                label={translate(`resources.bots.fields.testform.radio_label`)}
                name="label"
                value={item.label}
                onChange={handleOptionChange}
                onClick={handleOptionChange}
                variant="outlined"
                size="small"
                fullWidth
                required
                error={validations.label_messageErr}
                helperText={validations?.label_messageErr ? translate(validations?.label_messageErr) : ""}
            />
            <IconButton onClick={() => {props.onRadioRemoveOption(index)}}>
                <DeleteIcon />
            </IconButton>
        </div>
    );
}

const RadioFormController = ({component, index, ...props}) => {
    const translate = useTranslate();
    const [optionIds, setOptionIds] = useState([]);

    const onRadioChangeOption = (item, key) => {
        const updatedValues = [...component.values];
        updatedValues[key] = item;

        const isAllItemInvalid = updatedValues?.some(
            e => !!handleMessageErr(e.label, 'label') || !!handleMessageErr(e.value, 'value')
        );
        props.onChildFormChange({
            ...component,
            'values': updatedValues,
        }, isAllItemInvalid);
    };

    const onRadioRemoveOption = (key) => {
        const updatedComponent = {...component};
        updatedComponent['values'] = updatedComponent.values.filter((e, i) => i !== key);
        setOptionIds([...optionIds.filter((e, i) => i !== key)]);
        const isAllItemInvalid = updatedComponent['values']?.some(
            e => !!handleMessageErr(e.label, 'label') || !!handleMessageErr(e.value, 'value')
        ) || !updatedComponent['values']?.length;
        props.onChildFormChange(updatedComponent, isAllItemInvalid);
    };

    const onRadioAddOption = () => {
        const newOption = { value: '', label: '' };
        props.onChildFormChange({
            ...component,
            'values': [...component['values'], newOption],
        }, true);
        setOptionIds([...optionIds, randomString(6)]);
    };

    return (
        <div>
            {translate(`resources.bots.fields.testform.radio_values`)}
            {
                !component?.values?.length && <div style={{
                    marginTop: '10px',
                    color:'red',
                    fontSize: '12px',
                    maxWidth: '50%',
                }}>
                    {translate("resources.bots.validations.please_add_option")}
                </div>
            }
            { 
                component?.values?.map((item, index) => {
                    return (
                        <RadioItem
                            itemId={optionIds[index]}
                            key={index}
                            index={index}
                            item={item}
                            onRadioChangeOption={onRadioChangeOption}
                            onRadioRemoveOption={onRadioRemoveOption}
                        />
                    )
                })
            }            
            <div style={{ marginTop: '5px' }}>
                <Button
                    variant="text"
                    sx={{fontSize: "13px"}}
                    onClick={onRadioAddOption}
                    startIcon={<AddIcon />}
                    disabled={component?.values.length >= 10}
                >
                    {translate(`resources.bots.fields.testform.radio_add_option`)}
                </Button>
            </div>
        </div>
    );
}

export default RadioFormController;