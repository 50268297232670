import * as PERMISSION_TYPES from "../types/permissionTypes";

const initialState = {
  permissions: [],
  loading: true,
  error: null,
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_TYPES.INIT_PERMISSIONS:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: [],
      };
    case PERMISSION_TYPES.FETCH_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        permissions: [],
      };
    case PERMISSION_TYPES.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        permissions:
          action.payload && action.payload.permissions
            ? action.payload.permissions
            : [],
      };
    case PERMISSION_TYPES.FETCH_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        permissions: [],
      };
    case PERMISSION_TYPES.RESET_PERMISSIONS:
      return initialState;
    default:
      return state;
  }
};

export default permissionsReducer;
