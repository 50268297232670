import React, { useState, useEffect } from "react";
import {
  Notification,
  useTranslate,
  PasswordInput,
  required,
  minLength,
  useNotify,
} from "react-admin";
import { Form} from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types"; import {
  Button,
  Card,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import profileProvider from "../../synapse/profileProvider";
import { logger } from '../../utils/logger';


const confirmPasswordValidation = (value, form) => {
  logger.log(value, form);
  if (value !== form.new_password) {
    return "resources.profile.fields.does_not_match_new_password";
  }
  return undefined;
};

const passwordValidation = (value, _) =>  {
  if ((/^\s*$/.test(value?.trim())) || (/\s/.test(value))) 
  {
      return 'resources.users.invalid_password';
  }
  return undefined;
}

const UpdatePassword = (props) => {

  const classes = useStyles({ theme: props.theme });
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const translate = useTranslate();

  useEffect(() => {
  }, []);

  const validate = values => {
    return {};
  };

  const handleUpdatePassword = async (values) => {
    setLoading(true);
    const dto = {
      oldPassword: values.old_password || props.oldPassword,
      newPassword: values.new_password
    }
    profileProvider
      .updatePassword(dto)
      .then((value) => {
        
        notify("resources.profile.notifications.save_success");
        setLoading(false);
        props.updatePassword(value);
      })
      .catch(error => {
        setLoading(false);
        logger.error(error.message);

        if (error && error.body && error.body.errcode && error.body.errcode === "M_OLD_PASSWORD_INVALID") {
          notify("resources.profile.notifications.save_failed_wrong_old_password", {
            type: "error",
          });
        } else {
          notify("resources.profile.notifications.save_failed", {
            type: "error",
          });
        }
      });
  };

  return (
    <Form
      initialValues={{ old_password: props.oldPassword || '' }}
      onSubmit={handleUpdatePassword}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.form}>
                <div className={classes.boxBodyList}>
                  <h4>
                    {translate("synapseadmin.auth.login_flow.update_password.title")}
                  </h4>
                  <PasswordInput
                    className={props.oldPassword ? classes.hidden : ''}
                    label={"resources.profile.fields.old_password"}
                    source="old_password"
                    variant="outlined"
                    autoComplete="off"
                    validate={[
                      required("resources.profile.fields.old_password_required"),
                      minLength(6, "resources.profile.fields.minlength_password")
                    ]}
                  />
                  <PasswordInput
                    label={"resources.profile.fields.new_password"}
                    source="new_password"
                    variant="outlined"
                    autoComplete="off"
                    validate={[
                      required("resources.profile.fields.new_password_required"),
                      minLength(6, "resources.profile.fields.minlength_password"),
                      passwordValidation
                    ]}
                  />
                  <PasswordInput
                    label={"resources.profile.fields.re_enter_new_password"}
                    source="re_enter_new_password"
                    variant="outlined"
                    autoComplete="off"
                    validate={[
                      required("resources.profile.fields.re_enter_new_required"),
                      confirmPasswordValidation,
                      passwordValidation
                    ]}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("synapseadmin.auth.login_flow.update_password.button_save")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

UpdatePassword.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  dataFlow: PropTypes.object,
  updatePassword: PropTypes.func,
  oldPassword: PropTypes.string,
};

export default UpdatePassword;


const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: '25%',
  },
  form: {
    padding: "2em 1em 1em 1em",
  },
  boxBodyList: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    fontSize: '1.3rem',
  },
  inputTotp: {
    height: '0px',
    overflow: 'hidden',
    border: 'none',
  },
  iconBox: {
    paddingTop: '16px',
    width: '100%',
    textAlign: 'center'
  },
  errorText: {
    paddingTop: '1em',
    color: 'red',
  },
  hidden: {
    display: 'none',
  }
}));
