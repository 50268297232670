import React, { useState, useEffect } from "react";
import { useNotify, useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SaveIcon from '@material-ui/icons/Save';

import roomProvider from "../../synapse/roomProvider";
import { LoadingProgress } from "../common/LoadingProgress";
import { POWER_LEVEL } from "../../enum/ERoomOwner";

const DROPDOWN_KEY = {
  DEFAULT_ROLE: "DEFAULT_ROLE",
  CHANGE_PERMISSION: "CHANGE_PERMISSION",
};

const OPTIONS = [
  {
    key: DROPDOWN_KEY.DEFAULT_ROLE,
    options: [
      {
        key: "admin",
        powerLevel: POWER_LEVEL.ADMIN,
      },
      {
        key: "member",
        powerLevel: POWER_LEVEL.MEMBER,
      },
    ]
  },
  {
    key: DROPDOWN_KEY.CHANGE_PERMISSION,
    options: [
      {
        key: "owner",
        powerLevel: POWER_LEVEL.OWNER,
      },
      {
        key: "admin",
        powerLevel: POWER_LEVEL.ADMIN,
      },
    ]
  }
];

const SORT_KEY_MAPPING = {
  events_default: ["events_default"],
  events_reaction: ["events", "m.reaction"],
  events_room_pinned_events: ["events", "m.room.pinned_events"],
  events_room_redaction: ["events", "m.room.redaction"],
  redact: ["redact"],
  invite: ["invite"],
  ban: ["ban"],
  kick: ["kick"],
  events_modular_widgets: ["events", "im.vector.modular.widgets"],
  notifications_room: ["notifications", "room"],
  view_members: ["view_members"],
  view_system_messages: ["view_system_messages"],
  events_room_name: ["events", "m.room.name"],
  events_room_avatar: ["events", "m.room.avatar"],
  events_room_topic: ["events", "m.room.topic"],
  events_room_canonical_alias: ["events", "m.room.canonical_alias"],
  state_default: ["state_default"],
  events_room_history_visibility: ["events", "m.room.history_visibility"],
  config_whitelisted_ips: ["config_whitelisted_ips"],
  events_room_tombstone: ["events", "m.room.tombstone"],
};

const getPowerLevelsFilter = (powerLevels) => {
  if (!powerLevels) {
    return [];
  }
  return Object.keys(SORT_KEY_MAPPING).map((sortKey) => {
    const path = SORT_KEY_MAPPING[sortKey];
    let value = powerLevels;

    for (const key of path) {
      value = value[key];

      if (value === undefined) {
        break;
      }
    }

    return { key: sortKey, powerLevel: value };
  });
};

const assignValuesToObject = (obj, keys, value) => {
  if (keys.length === 0) {
    return;
  }

  const currentKey = keys[0];

  if (keys.length === 1) {
    // Assign a value to the last key
    obj[currentKey] = value;
  } else {
    // Create object if key does not exist
    obj[currentKey] = obj[currentKey] || {};
    // Recursion to move to the next object
    assignValuesToObject(obj[currentKey], keys.slice(1), value);
  }
};

const PermissionSelector = (
  {
    title,
    description,
    options,
    onSelect,
    selectedValue,
    translate,
  }
) => {
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <Box
      paddingY={2}
    >
      <Box>
        {title}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        paddingTop={1}
      >
        <Box
          fontSize={14}
          width={"50%"}
        >
          {description}
        </Box>
        <Box
          width={"50%"}
        >
          <FormControl
            size="small"
            sx={{
              width: "30%",
            }}
          >
            <Select
              variant="outlined"
              value={selectedValue}
              onChange={handleChange}
            >
              {options.map((option) => (
                <MenuItem key={option.powerLevel} value={option.powerLevel}>
                  {translate(`resources.rooms.permission.${option.key}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

const RowTable = (
  {
    data,
    onChangePowerLevel,
    translate,
  }
) => {
  const [powerLevel, setPowerLevel] = useState(POWER_LEVEL.OWNER);
  useEffect(() => {
    if (data.powerLevel || data.powerLevel === POWER_LEVEL.MEMBER) {
      setPowerLevel(data.powerLevel);
    }
  }, [data.powerLevel]);

  const handleChangePowerLevel = (powerLevel) => {
    onChangePowerLevel(
      {
        key: data.key,
        powerLevel,
      }
    )
  };

  const onOwnerChecked = () => {
    handleChangePowerLevel(POWER_LEVEL.OWNER);
  };

  const onAdminChecked = () => {
    if (powerLevel <= POWER_LEVEL.ADMIN) {
      handleChangePowerLevel(POWER_LEVEL.OWNER);
      return;
    }
    handleChangePowerLevel(POWER_LEVEL.ADMIN);
  };

  const onMemberChecked = () => {
    if (powerLevel === 0) {
      handleChangePowerLevel(POWER_LEVEL.ADMIN);
      return;
    }
    handleChangePowerLevel(POWER_LEVEL.MEMBER);
  };

  return (
    <TableRow key={data.key}>
      <TableCell component="th" scope="row">
        {translate(`resources.rooms.permission.${data.key}`)}
      </TableCell>
      <TableCell align="left">
        <Checkbox
          disabled
          onClick={onOwnerChecked}
          checked={powerLevel <= POWER_LEVEL.OWNER}
          color="primary"
        />
      </TableCell>
      <TableCell align="left">
        <Checkbox
          onClick={onAdminChecked}
          checked={powerLevel <= POWER_LEVEL.ADMIN}
          color="primary"
        />
      </TableCell>
      <TableCell align="left">
        <Checkbox
          onClick={onMemberChecked}
          checked={powerLevel === POWER_LEVEL.MEMBER}
          color="primary"
        />
      </TableCell>
    </TableRow>
  );
};

export const RoomPermissions = ({powerLevels, ...props}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [changePermission, setChangePermission] = useState(powerLevels?.events?.["m.room.power_levels"] ?? 0);
  const changePermissionOptions = OPTIONS.find(x=> x.key === DROPDOWN_KEY.CHANGE_PERMISSION)?.options ?? [];
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDataTable(getPowerLevelsFilter(powerLevels));
  }, []);

  const onSelectedChangePermission = (selectedValue) => {
    setChangePermission(selectedValue);
  };

  const onChangePowerLevel = (rowData) => {
    const data = [...dataTable];
    const dataFilter = data.find(x => x.key === rowData.key);
    if (dataFilter) {
      dataFilter.powerLevel = rowData.powerLevel;
      setDataTable(data);
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const dto = {...powerLevels};
      dataTable.forEach(item => {
        const keys = SORT_KEY_MAPPING[item.key];
        if (keys) {
          assignValuesToObject(dto, keys, item.powerLevel);
        }
      });
      dto.events["m.room.power_levels"] = changePermission;
      await roomProvider.updatePermissions(props.id, dto);
      notify("resources.rooms.notifications.change_permissions_success",{ type: "success" });
    } catch (e) {
      notify("resources.rooms.notifications.change_permissions_failed",{ type: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (!powerLevels) {
    return <></>;
  }

  return (
    <Box sx={{
      width: "100%",
    }}>
      <Box>
        <PermissionSelector
          title={translate("resources.rooms.permission.change_permission")}
          description={translate("resources.rooms.permission.change_permission_description")}
          options={changePermissionOptions}
          onSelect={onSelectedChangePermission}
          selectedValue={changePermission}
          translate={translate}
        />
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {translate("resources.rooms.permission.permission")}
                </TableCell>
                <TableCell>
                  {translate("resources.rooms.permission.owner")}
                </TableCell>
                <TableCell>
                  {translate("resources.rooms.permission.admin")}
                </TableCell>
                <TableCell>
                  {translate("resources.rooms.permission.member")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataTable.length
                ? dataTable.map((data) => (
                  <RowTable
                    key={data.key}
                    data={data}
                    onChangePowerLevel={onChangePowerLevel}
                    translate={translate}
                  />
                ))
                : <TableRow>
                  <TableCell align="center">
                    {translate("resources.rooms.permission.no_data")}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        paddingY={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={onSave}
          disabled={loading}
          startIcon={loading ? <LoadingProgress size={20} thickness={3}/> : <SaveIcon />}
        >
          {translate("resources.roles.actions.save")}
        </Button>
      </Box>
    </Box>
  );
};
