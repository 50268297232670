export const ROOM_OWNER_TYPE = {
  ROOM: 1,
  USER: 2,
};

export const PROGRESS_STATUS = {
  NONE: 0,
  PROCESSING: 1,
  DONE: 2,
  ERROR: 3,
};

export const POWER_LEVEL = {
  OWNER: 100,
  ADMIN: 75,
  MEMBER: 0,
  RESTRICTED: -1,
};
