import React, { useEffect, useState } from "react";
import {
    useNotify, useTranslate
} from "react-admin";
import { Button } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { LoadingProgress } from "../../../common/LoadingProgress";
import { createSignature } from "../../../../helper/bot";
import botProvider from "../../../../synapse/botProvider";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    column: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    codeBlockTitle: {
        background: "#ececec",
        margin: "5px",
        padding: "10px",
        width: "20%",
    },
    codeBlock: {
        background: "#ececec",
        margin: "5px",
        padding: "10px",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        width: "80%",
    },
    downloadBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    textErr: {
        color: "red",
    },
    textSuccess: {
        color: "#319131",
    },
    preCard: {
        whiteSpace: 'pre-wrap'
    }
});

const renderHeaders = (headers) => {
    const contentHeaders = `Content-Type: ${headers?.contentType}\nX-Signature: ${headers?.signature}`;
    return contentHeaders;
};

const ResponseDetailsDialog = ({
    secret,
    botToken,
    isOpenDialogDetails,
    onCloseDialogDetails,
    detailRequest,
}) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [responseDetails, setResponseDetails] = useState({
        headers: '',
        body: '',
    });
    const [enableDownLoadFile, setEnableDownLoadFile] = useState(false);
    const [isValidSignature, setIsValidSignature] = useState(false);

    useEffect(() => {
        if (isOpenDialogDetails) {
            fetchResponseDetails(detailRequest);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenDialogDetails])

    const onClose = () => {
        setResponseDetails('');
        onCloseDialogDetails();
    };

    const fetchResponseDetails = async (dataRequest) => {
        // TODO: Compare create signature with BE
        const signature = createSignature(dataRequest, secret);
        setLoadingDetail(true);
        botProvider.getBotFormResponseDetails(botToken, signature, dataRequest)
            .then((res) => {
                const contentType = res?.headers.get('content-type');
                const xSignature = res?.headers.get('x-signature');
                setResponseDetails({
                    headers: {
                        contentType,
                        signature: xSignature,
                    },
                    body: res?.json,
                });
                setEnableDownLoadFile(checkDownLoadFile(res?.json));
                // Verify signature
                const signatureVerify = createSignature(res?.json, secret);
                if (signatureVerify === xSignature) {
                    setIsValidSignature(true);
                }
            })
            .catch(e => {
                onCloseDialogDetails();
                notify("resources.bots.fields.testform.response_detail_fetch_fail", "error");
            }).finally(() => {
                setLoadingDetail(false);
            });
    };

    const createFileName = () => {
        let data = responseDetails.body;
        return `${data.request_id}-${data.email}`;
    };

    const checkDownLoadFile = (data) => {
        if (!data) return false;
        return data.form?.some((e) => e.type === "file" && (e.value?.length > 0));
    };

    const onClickDownload = () => {
        const signature = createSignature(detailRequest, secret);
        const fileName = createFileName();
        botProvider.downloadFileFromResponse(botToken, signature, detailRequest, fileName).catch(() => {
            notify("resources.bots.fields.testform.response_detail_download_fail", "error");
        });
    };

    return <StyledDialog onClose={onClose} aria-labelledby="reset-dialog-title" open={isOpenDialogDetails}>
        <DialogTitle id="reset-dialog-title">
        </DialogTitle>
        {
            loadingDetail ? <LoadingProgress style={{ marginLeft: '7px', alignSelf: 'center' }} size={30} thickness={3}/> :
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.column}>
                        <div className={classes.codeBlockTitle}>
                            <code>
                                Headers
                            </code>
                        </div>
                        <div className={classes.codeBlock}>
                            <code>
                                {renderHeaders(responseDetails?.headers)}
                            </code>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.codeBlockTitle}>
                            <code>
                                Body
                            </code>
                        </div>
                        <div className={classes.codeBlock}>
                            <code>
                                <pre className={classes.preCard}>{JSON.stringify(responseDetails?.body, null, 2)}</pre>
                            </code>
                        </div>
                    </div>
                </div>
                <div className={classes.downloadBlock}>
                    <Button
                        endIcon={<GetAppIcon/>}
                        onClick={onClickDownload}
                        color="inherit"
                        variant="outlined"
                        sx={{ margin: '5px' }}
                        disabled={!enableDownLoadFile}
                    >
                        Download files
                    </Button>
                    {
                        !isValidSignature ?
                        <div className={classes.textErr}>
                            {translate("resources.bots.fields.testform.signature_invalid")}
                        </div> :
                        <div className={classes.textSuccess}>
                            {translate("resources.bots.fields.testform.signature_verified")}
                        </div>
                    }
                </div>
            </DialogContent>
        }
        <DialogActions>

        </DialogActions>
    </StyledDialog>;
};

const StyledDialog = withStyles({
    paperWidthSm: {
        width: '100%',
        maxWidth: '50vw'
    },
})(Dialog);

export default ResponseDetailsDialog;
