import {
    FormTab,
    useTranslate,
    useNotify,
} from "react-admin";
import AssignmentIcon from '@material-ui/icons/Assignment';
import React, { useEffect, useState } from "react";
import { Grid, ThemeProvider, Button } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import muiTheme from "../../../helper/muiTheme";
import FormController from "./BotTestForm/FormController";
import CurlPreview from "./BotTestForm/CurlPreview";
import botProvider from "../../../synapse/botProvider";
import ListResponses from "./BotTestForm/ListResponses";
import { createSignature } from "../../../helper/bot";
import { EBotSetting } from "../../settings/BotSettings";
import { AttachFile } from "./BotTestForm/AttachFile";
import { randomString } from "../../../helper/utils";
import PropTypes from "prop-types";

export const NAME_REGEX = /^[a-zA-Z0-9._]+$/;
export const MAX_LENGTH_64_REGEX = /^.{1,64}$/;
export const MAX_LENGTH_255_REGEX = /^.{1,255}$/;
export const SPACES_REGEX = /^\s*$/;

const useStyles = makeStyles({
    optional: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '.85em',
        marginLeft: '5px'
    },
    formSummary: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginLeft: '30px',
    },
    attachment: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    helperAttachmentText: {
        padding: '5px',
        fontSize: '11.5px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em'
    },
    formDetailResponse: {
        marginLeft: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    addComponent: {
        margin: '20px 30px',
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        fontWeight: 'bold',
    },
    curlLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '15px'
    },
    errorMsg: {
        color: '#d32f2f',
    },
    errorText: {
        color:'red',
        fontSize: '12px',
        maxWidth: '50%',
    },
    helperText: {
        margin: '0',
        fontStyle: 'italic',
        fontSize: '12px'
    },
    attachmentsCard: {
        padding: '10px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
        overflow: 'hidden',
        minWidth: '0%',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
});

const initValidations = {
    from: {
        isInValid: true,
        messageErr: '',
    },
    to: {
        isInValid: true,
        messageErr: '',
    },
    description: {
        isInValid: true,
        messageErr: '',
    },
    metadata: [],
    form : [],
}

const initFormData = {
    from: '',
    to: '',
    description: '',
    file: [],
    form: [],
};

const MAX_METADATA_LENGTH = 500;

const BotTestForm = (props) => {
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);

    const [validations, setValidations] = useState({ ...initValidations });
    const [isInValidForm, setIsInValidForm] = useState(true);

    const [metadata, setMetadata] = useState([]);
    const [metadataLength, setMetadataLength] = useState(0);
    const [formData, setFormData] = useState(initFormData);
    const [curl, setCurl] = useState({});
    const [signature, setSignature] = useState("");

    const [showListResponse, setShowListResponse] = useState(false);

    // File
    const [fileBuffers, setFileBuffers] = useState([]);
    const [attachFiles, setAttachFiles] = useState([]);

    useEffect(() => {
        const isInvalidFields = Object.values(validations).some(
            (value) => value.isInValid
        );
        const isInvalidForm = !validations.form?.length || validations.form?.some(
            (e) => e.isInValid
        );
        const isInvalidMetadata = !!validations.metadata?.length && validations.metadata.some(
          (i) => i.keyError || i.valueError
        )
        setIsInValidForm(isInvalidFields || isInvalidForm || isInvalidMetadata || metadataLength > MAX_METADATA_LENGTH);
    }, [validations, metadataLength]);

    useEffect(() => {
        if (props.settings?.[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_COUNT]) {
            initAttachFiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings]);

    useEffect(() => {
        const files = attachFiles?.filter((e) => e.file)?.map((e) => e.file);
        const fileBuffers = attachFiles?.filter((e) => e.fileBuffer)?.map((e) => e.fileBuffer);
        setFormData({
            ...formData,
            file: [...files],
        });
        setFileBuffers([...fileBuffers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachFiles]);

    useEffect(() => {
        const meta = {}
        metadata.forEach((metaItem) => {
            meta[metaItem.key] = metaItem.value;
        });
        setMetadataLength(JSON.stringify(meta).length);
    }, [metadata]);

    const checkIsValid = (value, field) => {
        if (!value) {
            return true;
        }
        if (/^\s*$/.test(value?.trim())) {
            return true;
        }
        if (value?.length > 255 && field === 'from') {
            return true;
        }
        if (value?.length > 5000 && field === 'to') {
            return true;
        }
        if (value?.length > 1000 && field === 'description') {
            return true;
        }
        // more conditions here
        return false;
    };

    const handleMessageErr = (value, field) => {
        if (!value) {
            return `resources.bots.validations.required`;
        }
        if (/^\s*$/.test(value?.trim())) {
            return `resources.bots.validations.invalid_characters`;
        }
        if (value?.length > 255 && field === 'from') {
            return `resources.bots.validations.invalid_length_255`;
        }
        if (value?.length > 5000 && field === 'to') {
            return `resources.bots.validations.invalid_length_5000`;
        }
        if (value?.length > 1000 && field === 'description') {
            return `resources.bots.validations.invalid_length_1000`;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!name) {
            return;
        }
        setFormData({
          ...formData,
          [name]: value,
        });
        setValidations({
            ...validations,
            [name]: {
                isInValid: checkIsValid(value, name),
                messageErr: handleMessageErr(value, name),
            },
        });
    };

    const onFileAttach = (fileChange, index) => {
        const isRemoved = !fileChange.file;
        let newAttachFiles = [...attachFiles];
        if (isRemoved) {
            setAttachFiles(attachFiles.filter((item, idx) => idx !== index));
        }
        newAttachFiles[index] = {
            ...newAttachFiles[index],
            file: fileChange.file,
            fileBuffer: fileChange.fileBuffer,
            filename: fileChange.filename,
            fileHelperText: fileChange.fileHelperText,
        };
        setTimeout(() => {
            setAttachFiles(newAttachFiles);
        }, 1);
    };

    const onAddComponent = (e) => {

        const componentValidations = [...validations.form];
        componentValidations.push({
            isInValid: true,
        });
        setValidations({
            ...validations,
            form: componentValidations,
        });

        switch (e?.target.name) {
            case 'radio':
                setFormData({
                    ...formData,
                    form: [...formData.form, {
                        required: false,
                        label: '',
                        name: '',
                        type: 'radio',
                        values: [],
                        id: randomString(6),
                    }],
                });
                break;
            case 'file':
                setFormData({
                    ...formData,
                    form: [...formData.form, {
                        required: false,
                        label: '',
                        name: '',
                        type: 'file',
                        limit: 1,
                        size: 5,
                        sources: ['media'],
                        id: randomString(6),
                    }],
                });
                break;
            case 'textarea':
                setFormData({
                    ...formData,
                    form: [...formData.form, {
                        required: false,
                        label: '',
                        name: '',
                        type: 'textarea',
                        limit: 255,
                        id: randomString(6),
                    }],
                });
                break;
            default:
                break;
        }
    }

    /** Metadata */
    const allowedKeyRegex = new RegExp(/^[a-zA-Z0-9_-]+$/gi);
    const checkMetaKey = (index, key) => {
        if (metadata.length && metadata.some((item, i) => index !== i && item.key === key)) {
            // duplicate key value with another metadata item
            return false;
        }
        return allowedKeyRegex.test(key);
    }

    const allowedValueType = ['string', 'boolean', 'number']
    const checkMetaValue = (index, value) => {
        return value && allowedValueType.includes(typeof value);
    }

    const onRemoveMetaItem = (index) => {
        const result = [...metadata];
        result.splice(index, 1);
        setMetadata(result);
        setValidations({
            ...validations,
            metadata: validations.metadata.filter((_, i) => i !== index)
        })
    };

    const onAddMetaItem = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const result = [...metadata];
        const length = result.push({ key: '', value: '' });
        setMetadata(result);
        const validateMetadata = validations.metadata;
        validateMetadata[length - 1] = {
            keyError: 'resources.bots.validations.invalid_key_input',
            valueError: 'resources.bots.validations.invalid_value_input',
        };
        setValidations({
            ...validations,
            metadata: validateMetadata,
        })
    };

    /**
     * When change metadata item
     *
     * @param index Number index of array
     * @param field may be "key" or "value"
     * @param data user input data
     */
    const onChangeMetaItem = (index, field, data) => {
        const result = [...metadata];
        const item = result[index];
        item[field] = data;
        setMetadata(result);
        const validateMetadata = validations.metadata;
        const checkFn = field === 'key' ? checkMetaKey : checkMetaValue;
        validateMetadata[index] = {
            ...validateMetadata[index],
            [field + 'Error']: !checkFn(index, data) ? `resources.bots.validations.invalid_${field}_input` : null,
        }
        setValidations({
            ...validations,
            metadata: validateMetadata,
        })
    }

    const onRemoveComponent = (index) => {
        setFormData({
            ...formData,
            form: formData.form.filter((e, i) => i !== index),
        });
        setValidations({
            ...validations,
            form: validations.form.filter((e, i) => i !== index),
        });
    };

    const onComponentChange = (component, index, isInValid) => {
        setFormData(prevFormData=> {
            const newForm = [...prevFormData.form];
            newForm[index] = component;
            prevFormData.form = newForm;
            return {...prevFormData};
        });

        const componentValidations = [...validations.form];
        componentValidations[index] = { isInValid }
        setValidations({
            ...validations,
            form: componentValidations,
        });
    };

    const onMoveUp = (component, index) => {
        if (index === 0) return;
        setFormData(prevFormData=> {
            let newForm = [...prevFormData.form];
            newForm.splice(index, 1);
            newForm = [
                ...newForm.slice(0, index - 1),
                component,
                ...newForm.slice(index - 1)
            ];
            prevFormData.form = newForm;
            return {...prevFormData};
        });
    }

    const onMoveDown = (component, index, total) => {
        if (index === total - 1) return;
        setFormData(prevFormData=> {
            let newForm = [...prevFormData.form];
            newForm.splice(index, 1);
            newForm = [
                ...newForm.slice(0, index + 1),
                component,
                ...newForm.slice(index + 1)
            ];
            prevFormData.form = newForm;
            return {...prevFormData};
        });
    }

    const mapDataToSubmit = (dataForm, metadata) => {
        const data = {
            ...dataForm,
        };
        if (metadata && metadata.length) {
            const meta = {}
            metadata.forEach((metaItem) => {
                meta[metaItem.key] = metaItem.value;
            });
            data.metadata = JSON.stringify(meta);
        }
        const forms = data.form.map((e) => ({...e}))
        // Remove id field
        data.form = [...forms.map((e) => {
            delete e.id;
            return ({...e});
        })];
        if (data.file && !data.file.length) {
            delete data.file;
        }
        return data;
    };

    const onGenerateCurl = () => {
        const dataSubmit = mapDataToSubmit(formData, metadata);
        const signature = createSignature(dataSubmit, props.bot.configs.form_secret, fileBuffers);
        setSignature(signature);
        setCurl({
            ...dataSubmit,
        });
    };

    const onSend = (e) => {
        e.preventDefault();
        setLoading(true);
        const dataSubmit = mapDataToSubmit(formData, metadata);
        const signatureRequest = createSignature(dataSubmit, props.bot.configs.form_secret, fileBuffers);
        botProvider
            .sendForm(props.botToken, signatureRequest, dataSubmit)
            .then((res) => {
                notify(translate('resources.bots.notifications.send_success', {
                    requestId: res?.json?.request_id,
                }), "success");
            })
            .catch(error => {
                console.log(error);
                notify(translate('resources.bots.notifications.send_fail', {
                    errorMessage: error.body?.error || ''
                }), "error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openListResponses = () => {
        setShowListResponse(true);
    };

    const onBackToForm = () => {
        setShowListResponse(false);
    };

    const initAttachFiles = () => {
        setAttachFiles([]);
        const limitFile = props.settings?.[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_COUNT];
        let files = [];
        for (let i = 1; i <= limitFile; i++) {
            files.push({
                label: limitFile > 1 ? `File ${i}` : 'File',
                filename: '',
                file: '',
                fileBuffer: '',
                fileHelperText: '',
            });
        }
        setTimeout(() => {
            // async set state to force update
            setAttachFiles(files);
        }, 1);
    };

    const onClearForm = () => {
        setMetadataLength(0);
        setMetadata([]);
        const defaultValidation = { ...initValidations };
        defaultValidation.metadata = [];
        setValidations(defaultValidation);
        setFormData(initFormData);
        setFileBuffers([]);
        initAttachFiles();
        setCurl({});
        setSignature('');
    };

    if (showListResponse) {
        return (<FormTab {...props} label="resources.bots.tabs.test_form" icon={<AssignmentIcon />}>
            <ListResponses
                botId={props.bot?.id}
                onBackToForm={onBackToForm}
                secret={props.bot.configs.form_secret}
                botToken={props.botToken}
            />
        </FormTab>);
    }

    return (
        <FormTab {...props} label="resources.bots.tabs.test_form" icon={<AssignmentIcon />}>
            {!props.bot?.id ? (
                    <div
                        style={{
                            margin: "75px auto 100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        >
                        <CircularProgress size={50} thickness={2} />
                    </div>
                ) : (
                    <ThemeProvider theme={muiTheme}>
                        <Grid
                            container
                            width="100%"
                            spacing={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Grid item display="flex" justifyContent="space-between">
                                <Grid item xs={7}>
                                    {/* Summary information */}
                                    <h5>{translate("resources.bots.fields.testform_label_summary_information")}</h5>
                                    <div className={classes.formSummary}>
                                        <TextField
                                            id="from"
                                            label={translate("resources.bots.fields.testform.from")}
                                            name="from"
                                            value={formData.from}
                                            onChange={handleChange}
                                            onClick={handleChange}
                                            variant="outlined"
                                            size="small"
                                            required
                                            error={validations?.from?.messageErr}
                                            helperText={validations?.from?.messageErr ? translate(validations?.from?.messageErr) : ""}
                                        />
                                        <TextField
                                            id="to"
                                            label={translate("resources.bots.fields.testform.to")}
                                            name="to"
                                            value={formData.to}
                                            onChange={handleChange}
                                            onClick={handleChange}
                                            variant="outlined"
                                            size="small"
                                            required
                                            error={validations?.to?.messageErr}
                                            helperText={validations?.to?.messageErr ? translate(validations?.to?.messageErr) : ""}
                                        />
                                        <p className={classes.helperText}>{
                                            translate('resources.bots.fields.testform.helper_text_to')
                                        }</p>
                                        <TextField
                                            id="description"
                                            label={translate("resources.bots.fields.testform.description")}
                                            name="description"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            size="small"
                                            value={formData.description}
                                            onChange={handleChange}
                                            onClick={handleChange}
                                            required
                                            error={validations?.description?.messageErr}
                                            helperText={validations?.description?.messageErr ? translate(validations?.description?.messageErr) : ""}
                                        />
                                        <FieldLabel>
                                            {translate("resources.bots.fields.testform.attachment")}
                                        </FieldLabel>
                                        <div className={classes.attachmentsCard}>
                                            {
                                                !!attachFiles.length && attachFiles?.map((e, index) => (
                                                    <AttachFile
                                                        key={`attached_file_${index}`}
                                                        onFileAttach={onFileAttach}
                                                        data={e}
                                                        index={index}
                                                        settings={props.settings}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {/* Meta data */}
                                    <h5>
                                        {translate("resources.bots.fields.testform_label_metadata")}
                                        <span className={classes.optional}>
                                            ({translate("resources.bots.fields.testform_optional")})
                                        </span>
                                    </h5>
                                    <Grid container width="100%" spacing={2} display="flex" justifyContent="space-between" flexDirection="column">
                                        <div className={classes.formSummary}>
                                            <div className={
                                              metadataLength > MAX_METADATA_LENGTH ? classes.errorMsg : undefined
                                            }>
                                                {translate("resources.bots.fields.testform.metadata_length", {
                                                    current: metadata.length > 0 ? metadataLength : 0,
                                                    max: MAX_METADATA_LENGTH,
                                                })}
                                            </div>
                                            { metadata.length > 0 && <div>
                                                <p>
                                                    <label>{translate("resources.bots.fields.key")}: </label>
                                                    <span>
                                                        {translate("resources.bots.fields.testform.helper_text_key", { source: allowedKeyRegex.source })}
                                                    </span>
                                                </p>
                                                <p>
                                                    <label>{translate("resources.bots.fields.value")}: </label>
                                                    <span>
                                                        {translate("resources.bots.fields.testform.helper_text_value", { types: allowedValueType.join(", ") })}
                                                    </span>
                                                </p>
                                                <p>
                                                    {translate("resources.bots.fields.testform.metadata_length_helper")}
                                                </p>
                                            </div>}
                                            {metadata.length > 0 && metadata.map((metaItem, index) => (
                                              <Grid
                                                item
                                                display="flex"
                                                key={`metadata_item_${index}`}
                                                justifyContent="space-between"
                                                >
                                                  <Grid item xs={11} display="flex" gap={2}>
                                                      <TextField
                                                        label={translate("resources.bots.fields.key")}
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name={`metadata_key_${index}`}
                                                        size="small"
                                                        value={metaItem.key}
                                                        onChange={(e) => onChangeMetaItem(index, "key", e.target.value)}
                                                        onClick={(e) => onChangeMetaItem(index, "key", e.target.value)}
                                                        error={validations.metadata[index]?.keyError}
                                                        helperText={validations.metadata[index]?.keyError ? translate(validations.metadata[index]?.keyError) : ""}
                                                      />
                                                      <TextField
                                                        label={translate("resources.bots.fields.value")}
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name={`metadata_value_${index}`}
                                                        size="small"
                                                        value={metaItem.value}
                                                        onChange={(e) => onChangeMetaItem(index, "value", e.target.value)}
                                                        onClick={(e) => onChangeMetaItem(index, "value", e.target.value)}
                                                        error={validations.metadata[index]?.valueError}
                                                        helperText={validations.metadata[index]?.valueError ? translate(validations.metadata[index]?.valueError) : ""}
                                                      />
                                                  </Grid>
                                                  <Grid item>
                                                      <Button
                                                        startIcon={<DeleteIcon />}
                                                        color="error"
                                                        onClick={() => onRemoveMetaItem(index)}
                                                      >{translate("resources.bots.actions.remove")}</Button>
                                                  </Grid>
                                                </Grid>
                                              ),
                                            )}
                                            <ButtonGroup sx={{ mt: 2 }}>
                                                <Button startIcon={<AddIcon />} onClick={onAddMetaItem}>
                                                    {translate('resources.bots.actions.add_item')}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Grid>
                                    {/* Detail form for responding */}
                                    <h5>{translate("resources.bots.fields.testform_label_detail_responding")}</h5>
                                    <div className={classes.formDetailResponse}>
                                        {
                                            !formData.form.length && <div className={classes.errorText}>
                                                {translate("resources.bots.validations.please_add_component")}
                                            </div>
                                        }
                                        {
                                            formData.form?.map((e, index) => {
                                                return (
                                                    <FormController
                                                        key={index}
                                                        component={e}
                                                        index={index}
                                                        onComponentChange={onComponentChange}
                                                        onRemoveComponent={onRemoveComponent}
                                                        total={formData.form?.length}
                                                        onMoveUp={onMoveUp}
                                                        onMoveDown={onMoveDown}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    <div className={classes.addComponent}>
                                        <div>
                                            {translate("resources.bots.fields.testform.add_component")}
                                        </div>
                                        <ButtonGroup disabled={formData.form?.length >= 10} variant="outlined" aria-label="Basic button group">
                                            <Button name='radio' onClick={onAddComponent} startIcon={<AddIcon />}>
                                                {translate("resources.bots.fields.testform.radio")}
                                            </Button>
                                            <Button name='file' onClick={onAddComponent} startIcon={<AddIcon />}>
                                                {translate("resources.bots.fields.testform.file")}
                                            </Button>
                                            <Button name='textarea' onClick={onAddComponent} startIcon={<AddIcon />}>
                                                {translate("resources.bots.fields.testform.textarea")}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    {/* CURL Preview */}
                                    <div className={classes.curlLabel}>
                                        <h5>{translate("resources.bots.fields.testform_label_curl_preview")}</h5>
                                        <Button
                                            variant="contained"
                                            onClick={onGenerateCurl}
                                            disabled={
                                                !props.bot.configs.form_secret?.length ||
                                                !props.bot.configs.form_callback?.length ||
                                                isInValidForm
                                            }
                                        >
                                        {
                                            Object.keys(curl).length ?
                                            translate("resources.bots.fields.testform_button_curl_re_generate") :
                                            translate("resources.bots.fields.testform_button_curl_generate")
                                        }
                                        </Button>
                                        {
                                            (!props.bot.configs.form_secret?.length || !props.bot.configs.form_callback?.length) &&
                                            <div className={classes.errorText}>
                                                {translate("resources.bots.validations.invalid_form_secret")}
                                            </div>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        startIcon={<ListIcon/>}
                                        sx={{ marginTop: "10px" }}
                                        onClick={openListResponses}
                                    >
                                        {translate("resources.bots.fields.testform.btn_view_response")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <div>
                                {
                                    !!Object.keys(curl).length &&
                                    <CurlPreview
                                        curl={curl}
                                        signature={signature}
                                        botToken={props.botToken}
                                    />
                                }
                                <StyledButton
                                    variant="contained"
                                    onClick={onSend}
                                    disabled={
                                        loading ||
                                        !props.bot.configs.form_secret?.length ||
                                        !props.bot.configs.form_callback?.length ||
                                        isInValidForm
                                    }
                                >
                                    Send
                                </StyledButton>
                                <StyledButton
                                    component="label"
                                    variant="outlined"
                                    startIcon={<ClearAllIcon/>}
                                    color="error"
                                    sx={{marginRight: '10px'}}
                                    onClick={onClearForm}
                                >
                                    Clear form
                                </StyledButton>
                            </div>
                        </Grid>
                    </ThemeProvider>
                )
            }
        </FormTab>
    );
};

BotTestForm.prototype = {
    settings: PropTypes.array,
    bot: {
        id: PropTypes.string,
        configs: PropTypes.object,
    },
    botToken: PropTypes.string,

}
const FieldLabel = ({ children }) => {
    return (
      <div
        style={{
          color: 'rgba(0, 0, 0, 0.54)',
          padding: '0',
          fontSize: '12.5px',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: '400',
          lineHeight: '1',
          letterSpacing: '0.00938em',
          marginTop: '0px',
        }}
      >
        { children }
      </div>
    );
}

const StyledButton = styled(Button)({
    '&.MuiButton-root': {
        padding: '8px',
        margin: '16px 0px 16px 16px',
        minWidth: '100px'
    },
});

export default BotTestForm;
