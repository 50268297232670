import MarkdownIt from 'markdown-it';
import MarkdownItColor from './markdown-color';
const MARKDOWN_SUPPORT_LIST = [
  'normalize',
  'text',
  'heading', // h1 - h6
  'emphasis', // bold + italic
  'backticks', // code
  'strikethrough', // strikethrough
  'block', // div
  'inline', // span
  'paragraph', // p
  'newline', // br
  'list', // ul, ol
  'link', // a
];

const markdown = MarkdownIt('zero').enable(MARKDOWN_SUPPORT_LIST);
const default_renderer = (tokens, idx, options, env, _self) =>
  _self.renderToken(tokens, idx, options);

/** Customize strikethrough from tag <s> to <strike> */
for (const name of ['s_open', 's_close']) {
  const renderer = markdown.renderer.rules[name] || default_renderer;

  markdown.renderer.rules[name] = (tokens, idx, options, env, _self) => {
    tokens[idx].tag = 'strike';
    return renderer(tokens, idx, options, env, _self);
  };
}

/**
 * Support for syntax color
 *
 * Input: {red}(sample)
 * Output: <span data-mx-color="red" style="color: rgb(235, 78, 59);">sample</span>
 *
 * Ref: https://spec.matrix.org/v1.3/client-server-api/#mroommessage-msgtypes
 */
markdown.use(MarkdownItColor, {
  attributeName: 'data-mx-color',
  inline: true, // inline style support for iOS client
});

export { markdown };
