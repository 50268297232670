import React, { useState } from "react";
import { TabbedForm, useNotify, Title } from "react-admin";
import { Card } from "@material-ui/core";
import profileProvider from "../synapse/profileProvider";
import { logger } from '../utils/logger';
import { Profile } from './my-profile/Profile';
import { ProfileChangePassword } from "./my-profile/ProfileChangePassword";
import ToolbarSaveButton from "./common/ToolbarSaveButton";
import { ProfileSecurity } from "./my-profile/ProfileSecurity";

export const MyProfile = props => {
  const pathnameDisabled = ['/profile', '/profile/security'];

  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const onSave = data => {
    setLoading(true);
    const dto = {
      oldPassword: data.old_password,
      newPassword: data.new_password,
    };
    profileProvider
      .updatePassword(dto)
      .then(() => {
        notify("resources.profile.notifications.save_success");
        setLoading(false);
      })
      .catch(error => {
        logger.error(error.message);

        if (error.body?.errcode && error.body.errcode === "M_OLD_PASSWORD_INVALID") {
          notify("resources.profile.notifications.save_failed_wrong_old_password", {
            type: "error",
          });
        } else {
          notify("resources.profile.notifications.save_failed", {
            type: "error",
          });
        }

        setLoading(false);
      });
  };

  return (
    <Card>
      <TabbedForm
        {...props}
        save={onSave}
        toolbar={<ToolbarSaveButton
            pathnameDisabled={pathnameDisabled}
            {...props}
            loading={loading}
        />}
      >
        <Profile {...props} />
        <ProfileChangePassword {...props} path="change-password" />
        <ProfileSecurity {...props} path="security"/>
        <Title title={"resources.profile.name"} />
      </TabbedForm>
    </Card>
  );
};
