import * as React from 'react';
import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <g transform="translate(-2.000000,24.000000) scale(0.055, -0.055)">
    <path d="M224 411 c-4 -21 -13 -31 -28 -33 -47 -7 -11 -23 61 -28 43 -3 88
    -11 100 -18 26 -15 53 -61 53 -92 0 -50 -57 -110 -105 -110 -8 0 -15 -7 -15
    -15 0 -37 85 -2 128 53 42 53 42 91 0 144 -34 44 -80 68 -126 68 -25 0 -30 5
    -35 30 -8 38 -26 39 -33 1z"/>
    <path d="M75 326 c-58 -63 -61 -100 -10 -162 41 -49 86 -67 154 -62 68 5 65
    28 -4 28 -67 0 -109 19 -130 60 -27 52 -16 96 33 137 21 18 24 25 14 35 -9 9
    -21 2 -57 -36z"/>
    <path d="M125 295 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 22 -23 32 -25
    115 -25 83 0 93 2 115 25 26 25 32 63 15 96 -14 25 -45 26 -35 2 31 -73 10
    -93 -95 -93 -83 0 -110 12 -110 50 0 37 27 50 106 50 59 0 74 3 74 15 0 12
    -16 15 -80 15 -73 0 -83 -3 -105 -25z"/>
    <path d="M175 250 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
    <path d="M275 250 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
  </g>,
  'BotIcon',
);