import React from "react";
import { AdminContext, resolveBrowserLocale } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./synapse/authProvider";
import dataProvider from "./synapse/dataProvider";
/* delete by ctalk
import germanMessages from "./i18n/de";
import chineseMessages from "./i18n/zh";
import vietnameseMessages from "./i18n/vi";
 */
import englishMessages from "./i18n/en";
import permissionsReducer from "./reducers/permissionsReducer";
import * as permissionsSaga from "./sagas/permissionsSaga";
import botContainersReducer from "./reducers/botContainersReducer";
import * as botContainersSaga from "./sagas/botContainersSaga";
import AsyncResources from "./AsyncResources";

// TODO: Can we use lazy loading together with browser locale?

/* delete by ctalk
const messages = {
  de: germanMessages,
  en: englishMessages,
  zh: chineseMessages,
  vi: vietnameseMessages,
};
*/

const messages = {
  en: englishMessages,
};

const getLocale = () => {
  const locale = localStorage.getItem("locale");
  return locale || resolveBrowserLocale();
};

/* delete by ctalk
const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.en),
  getLocale()
);
*/

const i18nProvider = polyglotI18nProvider(
    locale => messages.en,
    getLocale()
);

function App() {
  return (
    <AdminContext
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{ permissions: permissionsReducer, botContainers: botContainersReducer }}
      customSagas={[permissionsSaga.permissionsSaga, botContainersSaga.botContainersSaga]}
    >
      <AsyncResources />
    </AdminContext>
  );
}

export default App;
