import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Box from "@mui/material/Box";
import Fade from "@material-ui/core/Fade";
import React from "react";

export function CommonModal(
  {
    title,
    action,
    lockModal,
    open,
    handleClose,
    children,
  }
) {
  const classes = useStyles();
  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={lockModal ? undefined : handleClose} // Check close when click outside modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {title}
            <Box
              sx={{
                width: '100%',
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {children}
            </Box>
            {action}
          </div>
        </Fade>
      </Modal>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 1),
    width: 500,
  },
}));
