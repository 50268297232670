import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    CardActions,
    Filter,
    SearchInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const date_format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
};

const useStyles = makeStyles({
    rowNo: {
        width: '50px',
        textAlign: 'center',
    },
});

const CardActionsGrid = props => {
    const onClickEdit = () => {
        window.location = `/#/roles/${props.record.id}`
    }

    return (
        <CardActions>
            <IconButton aria-label="edit" onClick={onClickEdit}>
                <EditIcon />
            </IconButton>
            {/* <DeleteRole roleId={props.record.id} isList={true} roleName={props.record.name}/> */}
        </CardActions>
    );
}

const ActionsBar = props => (
    <TopToolbar >
        <CreateButton label="resources.roles.actions.add_role"/>
    </TopToolbar>
);

const RoleFilter = props => (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn variant="outlined" placeholder="Role Name"/>
    </Filter>
);

export const RoleList = props => {
    const classes = useStyles();
    return (
      <List
        {...props}
        filters={<RoleFilter />}
        sort={{ field: "createdAt", order: "DESC" }}
        bulkActionButtons={false}
        actions={<ActionsBar />}
      >
        <Datagrid
            rowClick="edit"
        >
            <TextField 
                source="no"
                label="#"
                cellClassName={classes.rowNo}
                headerClassName={classes.rowNo}
                sortable={false}
            />
            <TextField 
                source="name" 
                label="resources.roles.fields.namelist"
            />
            <DateField
                source="createdAt"
                label="resources.roles.fields.createdAt"
                showTime
                options={date_format}
            />
            <DateField
                source="updatedAt"
                label="resources.roles.fields.updatedAt"
                showTime
                options={date_format}
            />
            <CardActionsGrid />
        </Datagrid>
      </List>
    );
};
