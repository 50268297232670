import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  // "&:before": {
  //   display: "none",
  // },
}));

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  // flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-content": {
  //   marginLeft: "3px",
  //   marginTop: 0,
  //   marginBottom: 0,
  //   padding: 0,
  //   color: "#002884",
  //   "&:hover": {
  //     opacity: 0.8,
  //   },
  // },
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(0deg)",
  // },
  padding: theme.spacing(0),
  // paddingLeft: "15px",
  // minHeight: 20,
  // width: "50%",
  // marginTop: "-15px",
  // "&:hover:active::after": {
  //   backgroundColor: "transparent",
  // },
  // "&:hover .MuiAccordionSummary-expandIconWrapper": {
  //   opacity: 0.8,
  // },
  // ".MuiAccordionSummary-expandIconWrapper": {
  //   color: "#002884",
  // },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(1),
  // marginTop: theme.spacing(1),
  // marginLeft: theme.spacing(2),
  // border: "2px solid #c8c8c8",
}));
