import React, { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SaveIcon from "@material-ui/icons/Save";
import roomProvider from "../../synapse/roomProvider";
import { LoadingProgress } from "../common/LoadingProgress";
import { AutoDeleteMessages } from "../common/AutoDeleteMessages";
import { FieldLabel } from "../settings/SettingsStyle";

export const RoomSecurity = (props) => {
  const {data} = props;
  const translate = useTranslate();
  const notify = useNotify();
  const time = data?.max_lifetime || -1;
  const [maxLifetime, setMaxLifetime] = useState(time);
  const [maxLifetimeCloned, setMaxLifetimeCloned] = useState(time);
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setLoading(true);
    try {
      const dto = {
        max_lifetime: maxLifetime > 0 ? maxLifetime : null,
      }
      await roomProvider.setAutoDeleteMessages(props.id, dto);
      notify("resources.rooms.notifications.change_config_success",{ type: "success" });
    } catch (e) {
      notify("resources.rooms.notifications.change_config_failed",{ type: "error" });
    } finally {
      setMaxLifetimeCloned(maxLifetime);
      setLoading(false);
    }
  }

  const onMaxLifetimeChange = (value) => {
    setMaxLifetime(value);
  };

  return (
    <Box
      sx={{
        paddingY: 2,
      }}
    >
      <FieldLabel>
        {translate("resources.settings.fields.auto_delete")}
      </FieldLabel>
      <AutoDeleteMessages
        translate={translate}
        onMaxLifetimeChange={onMaxLifetimeChange}
        maxLifetime={time}
        switchLabel={translate("resources.settings.fields.enable_auto_delete")}
        firstSelected={true}
      />
      <Box
        paddingY={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={onSave}
          disabled={loading || maxLifetimeCloned === maxLifetime}
          startIcon={loading ? <LoadingProgress size={20} thickness={3}/> : <SaveIcon />}
        >
          {translate("resources.roles.actions.save")}
        </Button>
      </Box>
    </Box>
  );
};
