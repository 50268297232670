import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, ThemeProvider } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslate } from "ra-core";
import muiTheme from "../../helper/muiTheme";
import { Pagination } from "../common/Pagination";

function CustomToolbar(props) {
    const translate = useTranslate();

    return (
        <Box sx={{
            display: 'flex',
            fontWeight: 'bold',
            p: 2,
            justifyContent: 'space-between'
        }}>
            <FormControlLabel
                sx={{
                    display: 'block',
                }}
                control={
                    <Switch
                        onChange={props.handleShowOnlyAssignedUsersChange}
                        name="showAssignedUsers"
                        color="primary"
                    />
                }
                label={translate("resources.roles.fields.showOnlyAssignedUsersChange")}
            />
            <GridToolbarQuickFilter />
        </Box>
    );
}

export const RoleUsers = ({userList, role, ...props})  => {
    const columns = [
        { 
            field: 'avatar', 
            headerName: 'Avatar', 
            width: 100, 
            sortable: false, 
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                  <>
                    <Avatar src={params.record?.avatarUrl} />
                  </>
                );
            }
        },
        { 
            field: 'userId', 
            headerName: 'User-ID', 
            width: 400, 
            sortable: false, 
            disableColumnMenu: true,
        },
        { 
            field: 'displayname', 
            headerName: 'Displayname', 
            width: 400, 
            sortable: true, 
            disableColumnMenu: true, 
        },
    ];
    const [selectionModel, setSelectionModel] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (userList && userList.length > 0) {
            setUsers(userList);
            if (role) {
                setSelectionModel(role.users);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList]);

    const handleShowOnlyAssignedUsersChange = (value) => {
        if (value.target.checked) {
            const usersFilter = userList.filter((item) => selectionModel.some((i) => i === item.userId));
            setUsers(usersFilter);
        } else {
            setUsers(userList);
        }
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Box
                sx={{ height: '70vh'}}
            >
                <DataGrid
                    style={{borderWidth:0}}
                    rows={users}
                    columns={columns} 
                    getRowId={(row) => row?.userId}
                    checkboxSelection
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        const selectedIDs = new Set(ids);
                        const selectedRowData = users.filter((row) =>
                            selectedIDs.has(row.userId.toString())
                        )?.map((row) => (row.userId));
                        props.onUsersChange(selectedRowData);
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 50 } },
                        sorting: {
                            sortModel: [{ field: 'displayname', sort: 'asc' }],
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    slots={{
                        pagination: Pagination,
                        toolbar: CustomToolbar }}
                    slotProps={{
                        toolbar: {
                            handleShowOnlyAssignedUsersChange: handleShowOnlyAssignedUsersChange
                        },
                    }}
                />
            </Box>
        </ThemeProvider>
    )
};