import React from "react";
import {
  FormTab,
} from "react-admin";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListProxy from "./BotTestProxy/ListProxy";

const BotTestProxy = (props) => {
  return (
    <FormTab {...props} label="resources.bots.tabs.test_proxy" icon={<SwapHorizontalCircle />}>
      {
        !props.bot?.id ?
          <div style={{ margin: "75px auto 100px", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={50} thickness={2} />
          </div> :
          <ListProxy
            botId={props.bot?.id}
            botToken={props.botToken}
            location={props.location}
          />
      }
    </FormTab>
  );
};

export default BotTestProxy;
