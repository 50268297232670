import { put, takeEvery, call } from "redux-saga/effects";
import {
  fetchBotContainersRequest,
  fetchBotContainersSuccess,
  fetchBotContainersFailure,
} from "../actions/botContainersAction";
import botProvider from "../synapse/botProvider";

export const FETCH_BOT_CONTAINERS_SAGA = "FETCH_BOT_CONTAINERS_SAGA";

export function* botContainersSaga() {
  yield takeEvery(FETCH_BOT_CONTAINERS_SAGA, fetchBotContainers);
}

function* fetchBotContainers() {
  const token = localStorage.getItem("access_token");
  if (token !== null) {
    yield put(fetchBotContainersRequest());
    try {
      const botContainers = yield call(botProvider.getContainers);
      yield put(fetchBotContainersSuccess(botContainers));
    } catch (e) {
      yield put(fetchBotContainersFailure());
    }
  }
}
