import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const DEFAULT_OPTIONS = [100, 1000, 10000, 100000];

export const RotationConfig = (
    {
      translate,
      rotationValueInput,
      helperDescription,
      onRotationChange,
    }
) => {
    const [rotationValue, setRotationValue] = useState(rotationValueInput);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    useEffect(() => {
        if (!options.find(value => value === rotationValueInput)) {
            setOptions([...options, rotationValueInput]);
        }
    }, []);

    const handleChange = (event) => {
        let { value } = event.target;
        setRotationValue(Number(value));
        onRotationChange(Number(value));
    };

    const formater = new Intl.NumberFormat('en-US');

    return <Box>
            <Box
                sx={{
                whiteSpace: "nowrap",
                color: "gray",
                fontSize: "0.8em"
                }}
            >
                {translate(helperDescription)}
            </Box>
            <Box sx={{ paddingLeft: 2}}>
                <RadioGroup
                    aria-label="rotation_period_messages"
                    name="rotation_period_messages"
                    value={rotationValue}
                    onChange={handleChange}
                >
                    {
                        options.map(value => (
                            <FormControlLabel
                                key={value}
                                control={<Radio />}
                                value={value}
                                label={translate('resources.settings.fields.rotation_period_messages_option', {
                                    message: formater.format(value)
                                })}
                            />
                        ))
                    }
                </RadioGroup>
            </Box>
    </Box>;
};
