import { env } from "../env";
import { jsonClient } from "./dataProvider";

const profileProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async updatePassword(dto) {
    const url = this.base_url + `/user/change-password`;
    const options = {
      method: "POST",
      body: JSON.stringify(dto),
    };
    return await jsonClient(url, options);
  },
  async twoFactorEnable() {
    const url = this.base_url + `/user/two-factor/enable`;
    const options = {
      method: "POST",
    };
    return await jsonClient(url, options);
  },
  async verifyTwoFactor(otpCode) {
    const url = this.base_url + `/user/two-factor/verify`;
    const options = {
      method: "POST",
      body: JSON.stringify({otpCode}),
    };
    return await jsonClient(url, options);
  }
};

export default profileProvider;
